import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import FormCard from '../components/FormCard.js';
import TableComponent from '../components/TableComponent';
import {
  FormContainer,
  FormSectionHeader,
  FormInputWrapper,
  FormInputLabel,
  Button,
  FormSelectInput,
} from '../components/CommonComponents';
import { sheepYellow } from '../components/Colors.js';
import { getUsername } from '../utils/TokenUtils.js';
import { getVehiclesByOperation } from '../utils/VehicleUtils.js';

const axios = require('axios');

export default function AnimalAttachments() {
  const tableHeadings = [{ name: 'CSIP #', id: 'isonum' }];

  const [sheep, setSheep] = useState([]);
  const [redirect, setRedirect] = useState('INITIAL');
  const [data, setData] = useState({
    id: '',
    information: '',
    attachments: [],
  });

  useEffect(() => {
    axios
      .get(`/api/operations/${getUsername()}/all`)
      .then((res) => {
        setData({ ...data, operations: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (redirect === 'DONE') {
    return <Redirect to='/' push />;
  }

  const operationChange = async (operation) => {
    setSheep([]);
    setData({
      ...data,
      id: '',
      information: '',
    });
    // Get premises
    axios
      .get(`/api/premises/operation/${operation}`)
      .then((response) => {
        setData((data) => ({ ...data, premises: response.data }));
      })
      .catch((error) => {
        console.log(error);
      });
    // Get vehicles
    const result = await getVehiclesByOperation(operation);
    setData((data) => ({ ...data, vehicles: result }));
  };

  const premiseChange = (target) => {
    setData({
      ...data,
      id: '',
      information: '',
    });
    // Get sheep
    axios
      .get(`/api/sheep/premises/${target.dataset.id}`)
      .then((res) => {
        setSheep(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectSheep = ({ isonum }, checked) => {
    setSheep(
      sheep.map((sheep) => {
        if (sheep.isonum === isonum) return { ...sheep, checked };
        if (checked && data.isonum && sheep.isonum === data.isonum)
          return { ...sheep, checked: false };
        return sheep;
      })
    );
    if (checked) {
      setData({ ...data, isonum });
    } else {
      setData({ ...data, isonum: undefined });
    }
  };

  const uploadDocument = (e) => {
    e.preventDefault();

    setRedirect('PROCESSING');

    const formData = new FormData();
    for (const file of data.attachments) {
      formData.append('file', file);
    }
    formData.append('isonum', data.isonum);
    formData.append('information', data.information);

    axios
      .post('/api/animalattachment', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(
        async () => {
          toast.success('Animal attachment uploaded!');
          setRedirect('DONE');
        },
        (error) => {
          toast.error(`An error has occurred: ${error}.`, {
            autoClose: false,
          });
          setRedirect('INITIAL');
        }
      )
      .catch((error) => {
        // TODO: handle error
        console.log(error);
      });
  };

  return (
    <FormCard title='Animal Document Upload' back>
      <ImportContainer>
        <ImportSheep>
          <ImportTable>
            <TableComponent
              headings={tableHeadings}
              data={sheep}
              check={{ type: 'single', cb: selectSheep }}
              actions
              filters
            />
          </ImportTable>
        </ImportSheep>
        <ImportFormContainer>
          <FormSectionHeader>Attach Documents</FormSectionHeader>
          <FormInputWrapper>
            <FormInputLabel>Operation</FormInputLabel>
            <FormSelectInput
              value={data.operation}
              onChange={(e) => operationChange(e.target.value)}
            >
              <option hidden>Select an operation</option>
              {data.operations &&
                data.operations.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.businessName}
                  </option>
                ))}
            </FormSelectInput>
          </FormInputWrapper>
          <FormInputWrapper>
            <FormInputLabel>Premise</FormInputLabel>
            <FormSelectInput
              value={data.pid}
              onChange={(e) => premiseChange(e.target[e.target.selectedIndex])}
            >
              <option hidden>Select a premise</option>
              {data.premises &&
                data.premises.map((option) => (
                  <option key={option._id} value={option.pid} data-id={option._id}>
                    {option.name}
                  </option>
                ))}
            </FormSelectInput>
          </FormInputWrapper>
          <FormInputWrapper>
            <FormInputLabel>Animal Attachments</FormInputLabel>
            <input
              type='file'
              accept='.pdf, .doc'
              multiple
              onChange={(e) => setData({ ...data, attachments: e.target.files })}
            />
          </FormInputWrapper>
          {/* TODO: Find a better button name. But my english sucks */}
          <ButtonContainer>
            <ImportButton
              onClick={uploadDocument}
              disabled={!data.attachments.length || !data.isonum || redirect !== 'INITIAL'}
            >
              Upload documents
            </ImportButton>
          </ButtonContainer>
        </ImportFormContainer>
      </ImportContainer>
    </FormCard>
  );
}

const ImportFormContainer = styled(FormContainer)`
  flex-direction: row;
  flex-wrap: wrap;
  > button {
    margin: 0 auto 10px;
  }
`;


const ImportContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0 0;
`;

const ImportSheep = styled.div`
  width: fit-content;
  display: flex;
  padding: 0 20px 20px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

// TODO: Make the height dynamic
const ImportTable = styled.div`
  overflow-y: auto;
  max-height: 400px;
  min-width: 350px;
  margin: auto;
  &::-webkit-scrollbar {
    width: 15px;
    &-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
    &-thumb {
      background: ${sheepYellow};
      background: #e2e672;
      border-radius: 10px;
      &:hover {
        background: #e2e672;
        background: ${sheepYellow};
      }
    }
  }
`;

const ImportButton = styled(Button)`
  align-self: center;
`;
