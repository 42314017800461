import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Redirect, Link } from 'react-router-dom';
import {
	FormContainer,
	Button,
	FormInputWrapper,
	FormInputLabel,
	FormTextInput,
	FormSelectInput,
} from '../components/CommonComponents.js';
import FormCard from '../components/FormCard.js';
import { getUsername } from '../utils/TokenUtils.js';
import { 
	getOperationTypes,
	createOperation
} from '../utils/OperationUtils.js';
import DropDown from '../components/DropDown.js';
import Input from '../components/Input.js';
import Phone from 'phone';
import { toast } from 'react-toastify';

const axios = require('axios');

const baseOperationForm = {
	businessName: '',
	phoneNumber: '',
	operationType: '',
	cvsp: false,
	sfcp: false,
};

const basePremiseForm = {
	pid: '',
	name: '',
	address: '',
	municipality: '',
	province: '',
	postalCode: '',
	description: '',
};

const OperationCreate = () => {
	const [operationComplete, setOperationComplete] = useState(false);
	const [premiseComplete, setPremiseComplete] = useState(false);
	const [operationForm, setOperationForm] = useState(baseOperationForm);
	const [premiseForm, setPremiseForm] = useState(basePremiseForm);
	const [operationErrors, setOperationErrors] = useState([]);
	const [redirect, setRedirect] = useState(false);

	const isOperationFormComplete = (op) => {
		if(
			op.businessName != ''
			&& op.phoneNumber != ''
			&& op.operationType != ''
			&& Phone(op.phoneNumber).length > 0
		) return true;

		return false;
	}

	const isPremiseFormComplete = (prem) => {
		console.log({prem})
		if(
			prem.pid != ''
			&& prem.name != ''
			&& prem.address != ''
			&& prem.municipality != ''
			&& prem.province != ''
			&& prem.postalCode != ''
			&& prem.description != ''
		) return true;

		return false;
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		const body = {
			operation: operationForm,
			premise: premiseForm
		};
		body.operation.user = getUsername();
		try {
			console.log({body});
			await axios.post('/api/operations', body);
			setRedirect(true);
			toast.success('Operation successfully created!');
		} catch (err) {
			toast.error("An error occurred submitting the form. Contact an administrator");
		}
	}

	if(redirect){
		return <Redirect to='/operations'/>;
	}

	return <>
		<FormCard 
			title='Create a new Operation'
			errors={operationErrors}
			back
		>
			<FormContainer>
				<h3>Operation Information</h3>
				<p>
					In order to create a new operation, please enter the following information.
				</p>
				<FormInputWrapper key='businessName'>
					<FormInputLabel htmlFor='input'>
						Business Name
					</FormInputLabel>
					<FormTextInput
						placeholder='Your business name'
						type='text'
						id='input'
						name='businessName'
						value={operationForm['businessName']}
						onChange={(e) => {
							const newOperationForm = {
								...operationForm,
								businessName: e.target.value
							}
							setOperationForm(newOperationForm);
							setOperationComplete(
								isOperationFormComplete(newOperationForm)
							)
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='phoneNumber'>
					<FormInputLabel htmlFor='input'>
						Phone Number
					</FormInputLabel>
					<FormTextInput
						placeholder='3072776286'
						type='tel'
						id='input'
						name='phoneNumber'
						value={operationForm['phoneNumber']}
						onChange={(e) => {
							const phone = e.target.value;
							const fixedPhone = Phone(phone);
							console.log({fixedPhone});
							const phoneError = 'Invalid phone number';
							if(fixedPhone.length == 0){
								if(!operationErrors.includes(phoneError))
								setOperationErrors([
									...operationErrors,
									'Invalid phone number'
								]);
							} else {
								const index = operationErrors.indexOf(phoneError);
								console.log({index});
								if(index > -1){
									var operationErrorsCopy = operationErrors;
									operationErrorsCopy.splice(index, 1);
									setOperationErrors(operationErrorsCopy);
								}
							}
							const newOperationForm = {
								...operationForm,
								phoneNumber: e.target.value
							};
							setOperationForm(newOperationForm);
							setOperationComplete(
								isOperationFormComplete(newOperationForm)
							);
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper>
					<FormInputLabel htmlFor='input'>
						Operation Type
					</FormInputLabel>
					<FormSelectInput
						value={operationForm['operationType']}
						onChange={(e) => {
							const newOperationForm = {
								...operationForm,
								operationType: e.target.value
							};
							setOperationForm(newOperationForm);
							setOperationComplete(
								isOperationFormComplete(newOperationForm)
							);
						}}
					>
						<option hidden>Select one...</option>
						<option
							key='producer'
							value='producer'
						>
							Producer
						</option>
					</FormSelectInput>
				</FormInputWrapper>
				<FormInputWrapper>
					<FormInputLabel htmlFor='input'>
						CVSP
					</FormInputLabel>
					<input 
						type='checkbox'
						name='cvsp'
						value='cvsp'
						checked={operationForm.cvsp}
						onClick={(e) => {
							setOperationForm({
								...operationForm,
								cvsp: !operationForm.cvsp
							});
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper>
					<FormInputLabel htmlFor='input'>
						SFCP
					</FormInputLabel>
					<input 
						type='checkbox'
						name='sfcp'
						value='sfcp'
						checked={operationForm.sfcp}
						onClick={(e) => {
							setOperationForm({
								...operationForm,
								sfcp: !operationForm.sfcp
							});
						}}
					/>
				</FormInputWrapper>
			</FormContainer>
			{(isOperationFormComplete(operationForm)) ? <>
			<FormContainer>
				<h3>Premise Information</h3>
				<p>
					Every operation created needs at least one premise.
				</p>
				<FormInputWrapper key='pid'>
					<FormInputLabel htmlFor='input'>
						Premise ID (Leave blank if one has not yet been assigned)
					</FormInputLabel>
					<FormTextInput
						placeholder='Your premise id number'
						id='input'
						name='pid'
						value={premiseForm.pid}
						onChange={(e) => {
							setPremiseForm({
								...premiseForm,
								pid: e.target.value
							});
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='name'>
					<FormInputLabel htmlFor='input'>
						Name
					</FormInputLabel>
					<FormTextInput
						placeholder='Add a descriptive name for your premise'
						id='input'
						name='name'
						value={premiseForm.name}
						onChange={(e) => {
							setPremiseForm({
								...premiseForm,
								name: e.target.value
							});
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='address'>
					<FormInputLabel htmlFor='input'>
						Address
					</FormInputLabel>
					<FormTextInput
						placeholder='123 Internet Street'
						id='input'
						name='address'
						value={premiseForm.address}
						onChange={(e) => {
							setPremiseForm({
								...premiseForm,
								address: e.target.value
							});
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='municipality'>
					<FormInputLabel htmlFor='input'>
						Municipality or City
					</FormInputLabel>
					<FormTextInput
						placeholder='Toronto'
						id='input'
						name='municipality'
						value={premiseForm.municipality}
						onChange={(e) => {
							setPremiseForm({
								...premiseForm,
								municipality: e.target.value
							});
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='province'>
					<FormInputLabel htmlFor='input'>
						Province
					</FormInputLabel>
					<FormTextInput
						placeholder='E.g. ON'
						id='input'
						name='province'
						value={premiseForm.province}
						onChange={(e) => {
							setPremiseForm({
								...premiseForm,
								province: e.target.value
							})
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='postalCode'>
					<FormInputLabel htmlFor='input'>
						Postal Code
					</FormInputLabel>
					<FormTextInput
						id='input'
						name='postalCode'
						value={premiseForm.postalCode}
						onChange={(e) => {
							setPremiseForm({
								...premiseForm,
								postalCode: e.target.value
							});
						}}
					/>
				</FormInputWrapper>
				<FormInputWrapper key='description'>
					<FormInputLabel htmlFor='input'>
						Description
					</FormInputLabel>
					<FormTextInput
						id='input'
						placeholder='Enter a short description...'
						name='description'
						value={premiseForm.description}
						onChange={(e) => {
							setPremiseForm({
								...premiseForm,
								description: e.target.value
							})
						}}
					/>
				</FormInputWrapper>
				
			</FormContainer>
			</> : <></>}
			<Button type='submit' onClick={handleSubmit} disabled={!(isPremiseFormComplete(premiseForm) && isOperationFormComplete(operationForm))}>
					Submit
			</Button>
		</FormCard>

	</>
}

export default OperationCreate;
