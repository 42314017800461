//SearchTable.js
import React, { useState } from 'react';
import StripedTable from './StripedTable';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import propTypes from 'prop-types';

const MoreButton = styled.button`
  background-color: #17a737;
  border: none;
  border-radius: 10px;
  padding: .5em;
  font-size: 1em;
`;

const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 0.5em;
`;

const SearchBarContainer = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
`;

const filterRowsByCriteria = (rows, filterField, filter) => {
  if(!filter || filter === '') return rows;
  if(rows.length == 0) return rows;
  return rows.filter((r) => {
    console.log({r});
    return r[`${filterField}`].includes(filter);
  });
}

const SearchTable = ({
  rows, 
  columns, 
  to, 
  placeholder, 
  filterField, 
  count,
}) => {
  const [filter, setFilter] = useState('');
  let filteredRows = filterRowsByCriteria(rows, filterField, filter);
  console.log({filteredRows});
  filteredRows = filteredRows.slice(0, count);
  let currentDisplayed = (filteredRows.length > count) ? count : filteredRows.length;
  
  
  return <>
    {/* SearchBar */}
    <SearchBarContainer>
      <SearchBar 
        value={filter} 
        placeholder={placeholder}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
      />
    </SearchBarContainer>
    {/* Striped Table */}
    <StripedTable 
      rows={filteredRows}
      columns={columns}
    />
    {/* Show remaining count */}
    <p>Showing {currentDisplayed} of {rows.length} results</p>
    {/* more button */}
    <ButtonContainer>
      <Link to={to}>
        <MoreButton>View More</MoreButton>
      </Link>
    </ButtonContainer>
  </>;
};

SearchTable.propTypes = {
  rows: propTypes.any,
  columns: propTypes.any,
  to: propTypes.any,
  placeholder: propTypes.any,
  filterField: propTypes.any,
  count: propTypes.any
}

export default SearchTable;