// SheepReportDetailView.js
import React, { useEffect, useState } from 'react';
import PageCard from './PageCard';
import SimpleCard from './SimpleCard';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { PrettyPrintJson, PrintDate } from './SheepReportComponents';

const StyledName = styled.span`
  font-weight: bold;
`

const DataDiv = styled.div`
  font-size: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

const DataSpan = ({name, value}) => {
  return <DataDiv>
    <StyledName>{name}:</StyledName>&nbsp;<span>{value}</span>
  </DataDiv>
}

const SubHeading = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 1rem;
`

const SectionContainer = styled.div`
  margin-left: 1rem;
`

const Code = styled.code`
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 0.5em 0.5em;
  display: block;
  word-wrap: break-word;
`

const SheepReportDetailView = ({title, fetchReportDetailFunction}) => {
  const [report, setReport] = useState({
    createdAt: '2020-01-01'
  });
  const params = useParams();
  const id = params.id;
  const fetchReportAndTransform = async () => {
    const fetchedReport = await fetchReportDetailFunction(id);
    console.log({fetchedReport});
    setReport(fetchedReport);
  }

  useEffect(() => {
    fetchReportAndTransform();
  }, [])



  return <>
    <PageCard
      line
      back
      title={`View ${title} Report`}
    >
      {/* Date of  */}
      <DataSpan 
        name="Created At"
        value={<PrintDate date={report.createdAt} />}
      />
      <SubHeading>Animal Identification</SubHeading>
      <SectionContainer>
        {(report.isoNumber)?
          <DataSpan
            name="Isonumber"
            value={report.isoNumber}
          />
        : <></>}
        {(report.localMgmtNumber)?
          <DataSpan
            name="Local Management Number"
            value={report.localMgmtNumber}
          />
        : <></>}
        {(report.tattooNumber)?
          <DataSpan
            name="Tattoo Number"
            value={report.tattooNumber}
          />
        : <></>}
      </SectionContainer>
      <DataSpan
        name="Premise ID"
        value={report.pid}
      />
      <DataDiv>
        <StyledName>Raw Data:</StyledName><br />
        <Code><PrettyPrintJson
          data={report}
        /></Code>
      </DataDiv>
    </PageCard>
  </>
}

export default SheepReportDetailView;