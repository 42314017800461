import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../images/logo.png';
import { FormInputLabel, FormSelectInput } from '../components/CommonComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { getRoles } from '../utils/TokenUtils';
import propTypes from 'prop-types';

const Navbar = ({ token, handleLogout, messages, selectedRole }) => {
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    if (token) setRoles(getRoles());
  }, [token]);

  return (
    <Nav>
      <Img onClick={() => window.open('https://www.cansheep.ca/')} />
      <Title to='/'>
        <span style={{ color: '#17A737', fontSize: 'inherit' }}>Agro</span>Ledger
        {(token && getRoles() && getRoles().length > 0 ) && (
          <OperationContainer>
            <FormInputLabel htmlFor='input'>Selected Role:</FormInputLabel>
            <FormSelectInput style={{ width: '25%' }} name='role' value={selectedRole}>
              {roles.map((r) => (
                <option value={r.role} key={r.role}>{r.role}</option>
              ))}
            </FormSelectInput>
          </OperationContainer>
        )}
      </Title>
      <NavActions>
        {token ? (
          <>
            <MessagesContainer to='/notifications'>
              <FontAwesomeIcon icon={faBell} color='gray' size='lg' />
              {messages > 0 && <MessageCount>{messages}</MessageCount>}
            </MessagesContainer>
            <NavButton onClick={handleLogout}>Logout</NavButton>
          </>
        ) : (
          <>
            <NavLink to='/register'>Register</NavLink>
            <NavLink to='/login'>Login</NavLink>
          </>
        )}
      </NavActions>
    </Nav>
  );
};

Navbar.propTypes = {
  token: propTypes.any,
  handleLogout: propTypes.any,
  messages: propTypes.any,
  selectedRole: propTypes.any
};

const Nav = styled.div`
  padding: 0 10px;
  background: #fdffb1;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
`;

const NavButton = styled.a`
  font-size: 30px;
  text-decoration: none;
  margin: 20px;
`;

const NavLink = styled(Link)`
  margin: 20px;
  font-size: 30px;
  text-decoration: none;
  color: black;
`;

const Title = styled(Link)`
  width: 100%;
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  font-size: 80px;
  text-align: center;
`;

const Img = styled.img.attrs({
  src: logo,
})`
  position: absolute;
`;

const NavActions = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  align-items: center;
`;

const MessagesContainer = styled(Link)`
  position: relative;

  &:hover {
    font-weight: bold;
    font-size: 20px;
  }
`;

const MessageCount = styled.span`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  text-align: center;
  color: white;
  background-color: red;
  border-radius: 50%;
`;

const OperationContainer = styled.div`
  margin-bottom: 10px;
  line-height: 0;
`;

export default Navbar;
