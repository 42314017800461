import React, { useEffect, useState, useReducer } from 'react';
import { useParams, Redirect} from 'react-router-dom';
import axios from 'axios';
import {
	FormTextListItem,
	FormContainer,
	FormInputLabel,
	FormTextInput,
	Button,
	FormRow,
	FormSelectInput,
	FormTextList,
} from '../components/CommonComponents';
import styled from 'styled-components';
import { EditButton } from '../components/CommonComponents';
import {countries} from './ImportCreate.js';
import FormCard from '../components/FormCard.js';

function reducer(state, action) {
	switch (action.type) {
		case 'append': {
			var flag = false;
			console.log(state); //logs {provinces: []}
			console.log(action.value); //logs whatever value you select
			console.log(action.array); // logs 'provinces'

			if (state[action.array].includes(action.value)) {
				flag = true;
			}
			return {
				...state,
				[action.array]:
					flag === false ? [...state[action.array], action.value] : [...state[action.array]],
				dynamicAdd: {
					...state.dynamicAdd,
					[action.array]: [...state[action.array]],
				},
			};
		}
		case 'remove': {
			const filtered = state[action.array].filter((item) => item !== action.value);
			console.log(filtered);
			return {
				...state,
				[action.array]: filtered,
				dynamicAdd: {
					...state.dynamicAdd,
					[action.array]: [...state.dynamicAdd[action.array], { name: action.value }],
				},
			};
		}
		case 'init':
			return action.payload;
		default:
			throw new Error();
	}
}

export default function ImportUpdate() {
	let params = useParams();
	const [sheepImport, setSheepImport] = useState([]);
	const [redirected, setRedirected] = useState(false);
	const [fieldErrors, setFieldErrors] = useState({});
	const [data, dispatch] = useReducer(reducer, {});
	const [foo, setFoo] = useState('');
	const [reqd, setReqd] = useState(false);
	const [reqd1, setReqd1] = useState(false);
	const [windowSize, setWindowSize] = useState(0);

	var valid = true;

	const [form, setForm] = useState({
		exportingCountry: '',
		departureSite: '',
		firstDestinationPID: '',
        dateOfArrival: '',
        vehicleIdentifier: '',
	});

	const account = [
		{ name: 'Departure Site:', hint: sheepImport.departureSite, id: 'departureSite' },
		{ name:  <div>
					First Destination PID - Is the location an Abattoir?
					<EditButton type='button' onClick = {(e) => {
						if (data.isonum){
							data.isonum.map((option) => dispatch({ type: 'remove', array: 'isonum', value: option }));
						}
						//Functionality unique to update
						sheepImport.isonum = [];
						setReqd(false);
						setReqd1(true);
					}}>Yes</EditButton>
					<EditButton type='button' onClick = {(e) => {
						setReqd(true);
					}}>No</EditButton></div>, hint: sheepImport.firstDestinationPID, id: 'firstDestinationPID' },
        { name: 'Date of Arrival:', hint: sheepImport.dateOfArrival, id: 'dateOfArrival', type: 'date' },
        { name: 'Vehicle Identifier:', hint: sheepImport.vehicleIdentifier, id: 'vehicleIdentifier' },
	];

	const dynamicAdd = [{ name: 
		<>
			<span>Animal Identifiers:</span>
			<EditButton type='button' onClick={(e) => {
				foo === '' ? 
					alert('Cannot be blank')
					: 
					dispatch({ type: 'append', array: 'isonum', value: foo })
					setFoo('')
			}}>Add</EditButton>
		</>, id: 'isonum', hint: String(sheepImport.isonum) }];
		const dropDown = [[{ name: 'Exporting Country:', id: 'exportingCountry' }]];

	//For animal identifiers
	useEffect(() => {
		const payload = {
			isonum: [],
		};

		dispatch({
			type: 'init',
			payload,
		});
	}, []);

	useEffect(() => {
		axios
			.get(`/api/import/${params.id}`)
			.then(function (response) {
				setSheepImport(response.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

    //This function is run when the Update button is hit
	const updateImport = (e) => {
        const { dynamicAdd, ...imp } = data;
		form.isonum = imp.isonum;

		console.log(sheepImport.isonum);
		if (form.isonum.length === 0 && reqd === true){
			valid = false;
		}

		if (valid){
			if (reqd1 === false && form.isonum.length === 0 && reqd === false) {
				form.isonum = '';
			}
			else if (reqd1 === true && form.isonum.length === 0 && reqd === false){
				form.isonum = [];
			}

			axios
				.put(`/api/import/${params.id}`, form).then(
					(res) => {
						setRedirected(true);
					},
					(err) => {
						setFieldErrors(err.response.data);
					}
				);
		}
		else{
			alert('If your First Destination PID is not an abattoir, you need to fill in the Animal Identifiers!');
		}
		e.preventDefault();
	};

	if(window.innerWidth !== windowSize){
		setWindowSize(window.innerWidth);
		console.log(windowSize);
	}

	return (
		<FormCard title='Update an Import' errors={fieldErrors} back>
		{windowSize < 1300 ?
			<FormContainer onSubmit={updateImport}>
				{dropDown.map((row) => {
					return (
						<FormRow key={row}>
							{row.map((element) => {
								return (
									<FormInputWrapper1 key={element.id}>
										<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
										<FormTextList>
											<FormSelectInput
												name={element.id}
												value={form[element.id]}
												onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
											>	
												<option hidden>{sheepImport.exportingCountry}</option>
												{countries.map((value) => {
													return <option key={value.name}>{value}</option>;
												})}
											</FormSelectInput>
										</FormTextList>
									</FormInputWrapper1>
								);
							})}
						</FormRow>
					);
				})}
				{account.map((element) => {
					return (
						<FormInputWrapper1 key={element.id}>
							<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
							<FormTextInput
								placeholder={element.hint}
                                id='input'
                                type={element.type ? element.type : 'text'}
								valid={true}
								name={element.id}
								value={form[element.id]}
								onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} // react replaces the whole object so go to add ...form,
							></FormTextInput>
						</FormInputWrapper1>
					);
				})}
				{dynamicAdd.map((element) => (
					<FormInputWrapper1 key={element.id}>
						<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
							
							{data[element.id] &&
								data[element.id].map((option) => (
									<FormTextListItem
										key={element.id}
										remove
										onClick={(e) => dispatch({ type: 'remove', array: element.id, value: option })}
									>
										{option}
									</FormTextListItem>
								))}
								
    						<FormTextInput value={foo} disabled={reqd === false} placeholder={element.hint} onChange={(e)=>setFoo(e.target.value)}>
							</FormTextInput>
					</FormInputWrapper1>
				))}
				<div style={{ textAlign: 'center' }}>
					<Button>Update</Button>
				</div>
			</FormContainer>
			:
			<FormContainer onSubmit={updateImport}>
				{dropDown.map((row) => {
					return (
						<FormRow key={row}>
							{row.map((element) => {
								return (
									<FormInputWrapper key={element.id}>
										<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
										<FormTextList>
											<FormSelectInput
												style={{"width": "450px"}}
												name={element.id}
												value={form[element.id]}
												onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
											>	
												<option hidden>{sheepImport.exportingCountry}</option>
												{countries.map((value) => {
													return <option key={value.name}>{value}</option>;
												})}
											</FormSelectInput>
										</FormTextList>
									</FormInputWrapper>
								);
							})}
						</FormRow>
					);
				})}
				{account.map((element) => {
					return (
						<FormInputWrapper key={element.id}>
							<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
							<FormTextInput
								placeholder={element.hint}
                                id='input'
                                type={element.type ? element.type : 'text'}
								valid={true}
								name={element.id}
								value={form[element.id]}
								onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} // react replaces the whole object so go to add ...form,
							></FormTextInput>
						</FormInputWrapper>
					);
				})}
				{dynamicAdd.map((element) => (
					<FormInputWrapper key={element.id}>
						<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
							
							{data[element.id] &&
								data[element.id].map((option) => (
									<FormTextListItem
										key={element.id}
										remove
										onClick={(e) => dispatch({ type: 'remove', array: element.id, value: option })}
									>
										{option}
									</FormTextListItem>
								))}
								
    						<FormTextInput value={foo} disabled={reqd === false} placeholder={element.hint} onChange={(e)=>setFoo(e.target.value)}>
							</FormTextInput>
					</FormInputWrapper>
				))}
				<div style={{ textAlign: 'center' }}>
					<Button>Update</Button>
				</div>
			</FormContainer>}
			{redirected ? <Redirect push to={`/events/animalImport/${sheepImport._id}`} /> : null}
		</FormCard>
	);
}

const FormInputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 25px;
	margin-top: 0;
	width: 450px;
`;

const FormInputWrapper1 = styled.div`
	display: flex;
	flex-direction: column;
	margin: 25px;
	margin-top: 0;
	width: 200px;
`;