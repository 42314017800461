import React, { useState, useEffect, useReducer } from 'react';
import { useParams, useHistory,Link } from 'react-router-dom';
import styled from 'styled-components';
import {
	FormContainer,
	FormSectionHeader,
	FormInputWrapper,
	FormInputLabel,
	FormTextList,
	FormTextListItem,
	FormSelectInput,
	FormTextInput,
	Button,
} from '../components/CommonComponents';
import { getRoles } from '../utils/TokenUtils.js';
import FormCard from '../components/FormCard.js';
import { toast } from 'react-toastify';
import { getUser } from '../utils/UserUtils';
import PageCard from '../components/PageCard';
import DetailTable from '../components/DetailTable';
const axios = require('axios');

// TODO: Style the arrow in the select
// TODO: Fix the Select error wherein you cannot add the currently selected item
// TODO: Fix the dispatch method firing twice (kinda know why (?), but need remedy)

const UserView = () => {
	const params = useParams();
	const username = params.username;
	const [user, setUser] = useState();
	const [redirect, setRedirect] = useState('');

	const fetchUser = async() => {
		let u = await getUser(username);
		let transformedUser = {
			roleString: u.roles.map(r => r.role).join(', '),
			firstName: u.firstName,
			lastName: u.lastName,
			username: u.username,
			email: u.email,
			phone: u.phone
		};
		console.log({u});
		console.log(transformedUser);
		setUser(transformedUser);
	}

	useEffect(() => {
		fetchUser();
	}, [])

	const userFields = [
		{ name: 'First Name', id: 'firstName'},
		{ name: 'Last Name', id: 'lastName'},
		{ name: 'Roles', id: 'roleString'},
		{ name: 'Email', id: 'email'},
		{ name: 'Phone', id: 'phone'},
	]
	
	return <>
		<PageCard title={username} back>
			{user ? <>
				<Link to={`/roles/${username}`}><Button>Update Roles</Button></Link>
				<DetailTable
					data={user}
					fields={userFields}
				/>
			</> : <>Loading...</>}
		</PageCard>
	</>
}

export default UserView;
