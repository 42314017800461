import React, { useEffect, useState } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import { StyledTable } from '../components/TableComponents.js';
import PageCard from '../components/PageCard.js';
import styled from 'styled-components';
import axios from 'axios';
import { EditButton as Button } from '../components/CommonComponents';
import { agGreen } from '../components/Colors.js';
const moment = require('moment');

const ImportsTable = styled(StyledTable)``;

export default function ImportView() {
	let params = useParams();
	const [importSheep, setImportSheep] = useState([]);
	const [arr, setArr] = useState([]);
	const [redirected, setRedirected] = useState(false);

	useEffect(() => {
		axios
			.get(`/api/import/${params.id}`)
			.then(function (response) {
				setImportSheep(response.data);
				setArr(response.data.isonum);
				console.log(response.data);
				console.log(response.status);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	function deleteImport() {
		axios
			.delete(`/api/import/${params.id}`)
			.then(function (response) {
				console.log(response.data);
				setRedirected(true);
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	const parsed = moment(importSheep.dateOfArrival, 'YYYY/MM/DD');

	return (
		<>
			<PageCard title='View Details' back>
				<ImportsTable>
					<thead>
						<tr>
							<th>Parameter</th>
							<th>Value</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Exporting Country</td>
							<td>{importSheep.exportingCountry}</td>
						</tr>
						<tr>
							<td>Departure Site</td>
							<td>{importSheep.departureSite}</td>
						</tr>
						<tr>
							<td>First Destination PID</td>
							<td>{importSheep.firstDestinationPID}</td>
						</tr>
                        <tr>
							<td>Date of Arrival</td>
							<td>{parsed.format('LL')}</td>
						</tr>
                        <tr>
							<td>Animal Identifiers</td>
							<td>{arr.map((element) => <li>{element}</li>)}</td>
						</tr>
                        <tr>
							<td>Vehicle Identifer</td>
							<td>{importSheep.vehicleIdentifier}</td>
						</tr>
					</tbody>
				</ImportsTable>
			</PageCard>
			<ButtonWrapper>
				<Link to={`/events/animalImport/${params.id}/update`}>
					<EditButton>Edit</EditButton>
				</Link>
				<Link to={'/events/animalImport'}>
					<EditButton>Dashboard</EditButton>
				</Link>
				<DeleteButton onClick={(e) => deleteImport()}>Delete</DeleteButton>
				{redirected ? <Redirect push to='/events/animalImport' /> : null}
			</ButtonWrapper>
		</>
	);
}

const EditButton = styled(Button)`
	background-color: ${agGreen};
	margin-bottom: 10px;
`;

const DeleteButton = styled(Button)`
	background-color: #c11400;
`;

const ButtonWrapper = styled.div`
	margin: 0;
	position: absolute;
	top: 70%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
