import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
	FormInputWrapper,
	FormInputLabel,
	FormTextInput,
	FormContainer,
	FormSelectInput,
  Button,
} from '../components/CommonComponents';
import { faSpinner} from '@fortawesome/free-solid-svg-icons';
library.add(faSpinner);
import SimpleCard from './SimpleCard';
import { library } from '@fortawesome/fontawesome-svg-core';
import propTypes from 'prop-types';
const axios = require('axios');

const RequiredSpan = styled.span`
  color: red;
`

const RequiredStar = () => <RequiredSpan>*</RequiredSpan>;

const SubmittingDiv = styled.div`
  text-align: center
`;
const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin>

    </FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const validateForm = (form) => {
  let hasId = false;
  if(!form.tag) return false;
  if(
    (form.tag.isoNumber && form.tag.isoNumber != '') ||
    (form.tag.localMgmtNumber && form.tag.localMgmtNumber != '') ||
    (form.tag.tattooNumber && form.tag.tattooNumber != '')
  ) hasId = true;

  if(!form.premise || form.premise == '') return false;
  
  return hasId;
}

const submitSheep = async (sheep) => {
  try {
    toast.info("Submitting sheep to the backend. It may take up to a minute. You will be redirected when the sheep has been created. Please stay on this page.")
    const request = await axios.post('/api/sheep', sheep);
    console.log({request});
    const returnedSheep = request.data
    return returnedSheep;
  } catch (err){
    throw new Error(`An error occurred submitting the sheep: ${err.message}`);
  }
}

export default function SheepForm({ errors, breeds, premises }) {
  const [form, setForm] = useState({
    premise:'',
    tag:{
    },
  });
  const [redirect, setRedirect] = useState('');
  const [submitting, setSubmitting] = useState(false);

	const sheepFields = [
		{ name: 'Premise', type: 'dropdown', required: true, id: 'premise', altvalue: true, unique: true },
		{ name: 'Gender', type: 'dropdown', id: 'gender' },
		{ name: 'Sub Gender', type: 'dropdown', id: 'subgender' },
		{ name: 'Breed', type: 'dropdown', id: 'breed' },
		{ name: 'Date of Birth', type: 'date', id: 'birthdate', hint: 'May 11, 2019' },
	];

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setSubmitting(true);
      const returnedSheep = await submitSheep(form);
      console.log({returnedSheep});
      setRedirect(`/sheep/${returnedSheep._id}`);
    } catch (err){
      console.log(err);
      toast.error(err.message);
    }
  }

  const tagFields = [
    {
      name: 'Iso Number',
      id: 'isoNumber',
    },
    {
      name: 'Local Management Number',
      id: 'localMgmtNumber'
    },
    {
      name: 'Tattoo Number',
      id: 'tattooNumber',
    },
    {
      name: 'US Scrapie Id',
      id: 'usScrapieId'
    }
  ]
  
	let dropdowns = {
    breeds: breeds,
    premises: premises,
    genders: [{ name: 'Male', value: 'male' }, { name: 'Female', value: 'female' }],
  };

	if (form.gender === 'male') {
		dropdowns.subgenders = [{ name: 'Ram', value: 'ram' }, { name: 'Wether', value: 'wether' }];
	} else if (form.gender === 'female') {
		dropdowns.subgenders = [{ name: 'Ewe', value: 'ewe'}, { name: 'Ewe Lamb', value: 'ewelamb' }];
	} else {
    dropdowns.subgenders = [];
  }

	
  if(redirect != ''){
    console.log({redirect});
    return <Redirect to={redirect} />;
  }
	return (
		<SheepContainer>
      <SimpleCard 
        title="Sheep Information"
        description={<>
          <>Enter in the following information about the animal.</>
          <br /><br />
          <><RequiredStar /> indicates a required field</>
        </>}
      >
			{sheepFields.map((element) => (
				<FormInputWrapper key={element._id}>
					<FormInputLabel htmlFor='input'>
            {element.name}
            {(element.required) ? <>&nbsp;<RequiredStar/></> : <></>}
          </FormInputLabel>
					{element.type === 'dropdown' ? (
						<FormSelectInput
							invalid={errors[element.id]}
							value={form[element.id]}
							onChange={(e) =>
								setForm({
									...form,
									[element.id]: e.target.value,
								})
							}
						>
							<option>Add New</option>
							{dropdowns[`${element.id}s`] &&
								dropdowns[`${element.id}s`].map((option) => (
									<option key={option._id} value={element.altvalue ? option._id : (option.value) ? option.value : option.name}>
										{option.name}
									</option>
								))}
						</FormSelectInput>
					) : (
						<FormTextInput
							disabled={element.unique && form.disabled}
							placeholder={element.hint}
							type={element.type ? element.type : 'text'}
							id='input'
							invalid={errors[element.id]}
							name={element.id}
							value={form[element.id] || ''}
							onChange={(e) => {
								if (e.target.value === '') {
									const { [element.id]: _, ...updatedState } = form;
									setForm(updatedState);
								} else setForm({ ...form, [e.target.name]: e.target.value });
							}}
						/>
					)}
				</FormInputWrapper>
			))}
      </SimpleCard>
      {/*Tag fields*/}
      <SimpleCard 
        title="Tag Information"
        description="Enter the following uniquely idenfiying information for the sheep. Note that at least one of the following elements must be provided: Iso Number, Local Management Number or Tattoo Number"
      >
      {tagFields.map((f) => <>
        <FormInputWrapper>
          <FormInputLabel htmlFor='input'>
            {f.name}
          </FormInputLabel>
          <FormTextInput
            placeholder={f.placeholder}
            type='text'
            id='input'
            value={form.tag[f.id]}
            onChange={(e) => {
              console.log(form);
              setForm({...form, tag: {
                  ...form.tag,
                  [f.id]: e.target.value
                }
              })
            }}
          />
        </FormInputWrapper>
      </>)}
      </SimpleCard>
      <Button type='submit' onClick={handleSubmit} disabled={!validateForm(form) || submitting}>
        Create
      </Button>
      {(submitting) ? <SubmittingSpinner />: <></>}
		</SheepContainer>
	);
}

SheepForm.propTypes = {
  errors: propTypes.any,
  breeds: propTypes.any,
  premises: propTypes.any
};
// This should be the new default
const SheepContainer = styled(FormContainer)`
	
`;
