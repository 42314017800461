// SheepDisposeView.js
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PageCard from '../components/PageCard';
import SimpleCard from '../components/SimpleCard';
import StripedTable from '../components/StripedTable';
import { getUsername } from '../utils/TokenUtils';
import styled from 'styled-components';
import { Button } from '../components/CommonComponents';
import { Redirect, useLocation} from 'react-router-dom';
import moment from 'moment';

const getSheepForUser = async (username) => {
  const endpoint = `/api/sheep?username=${username}&premise=true`;
  try {
    const request = await axios.get(endpoint);
    const fetchedSheep = request.data;
    return fetchedSheep;
  } catch (err) {
    throw new Error(`An error occurred fetching sheep for user: ${err.message}`);
  }
}

const SubmittingDiv = styled.div`
  text-align: center
`;
const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin>

    </FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const SelectAllDiv = styled.div`
  padding-top: 2rem;
  text-align: center;
`;

const SelectAllSection = ({onClick, clearOnClick, showClearButton}) => {
  return <SelectAllDiv>
    <Button onClick={onClick}>Select All</Button>
    {(showClearButton) ? <Button onClick={clearOnClick}>Clear All</Button> : <></>}
  </SelectAllDiv>
}

const DisposeDiv = styled.div`
  margin-top: 2rem;
  text-align: center;
`
const RemoveButton = styled.button`
`;



const CheckBox = ({onChange, checked}) => {
  return <input type='checkbox' checked={checked} onChange={onChange} />;
}

const disposeOfSheep = async(sheepIDs, type, dateOf) => {
  try {
    toast.info("Submitting sheep to the backend. It may take up to a minute. You will be redirected when the sheep have been disposed. Please stay on this page.");
    const request = await axios.delete('/api/sheep/bulk', {
      data: {
        sheep: sheepIDs,
        type: type,
        date: dateOf
      }
    });
    toast.success('Sheep deleted successfully!');
    return;
  } catch (err) {
    throw new Error(`An error occurred submitting the sheep: ${err.message}`);
  }
}

const SheepDisposeView = () => {
  const [sheep, setSheep] = useState([]);
  const [selectedSheep, setSelectedSheep] = useState([]);
  const [operations, setOperations] = useState([]);
  const [premises, setPremises] = useState([]);
  const [filter, setFilter] = useState([]);
  const [tempFilter, setTempFilter] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [disposalInformation, setDisposalInformation] = useState({
    disposalType: 'carcass',
    disposalDate: moment().format('YYYY-MM-DD') //new Date().toDateString()
  });
  console.log({date: disposalInformation.disposalDate})
  const [selectedID, setSelectedID] = useState('');
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const DisposeSection = ({onClick, count}) => {
    return <DisposeDiv>
      <Button onClick={onClick} type='submit' disabled={submitting || selectedSheep.length == 0}>Dispose of {count} sheep</Button>
    </DisposeDiv>
  }
  const handleSubmit = async(e) => {
    try {
      e.preventDefault();
      setSubmitting(true);
      await disposeOfSheep(
        selectedSheep.map(s => s.id),     
        disposalInformation.disposalType, 
        disposalInformation.disposalDate
      );
      setRedirect('/sheep');
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  }

  console.log({selectedSheep})

  const isSheepSelected = (sheepID) => {
    const selected = selectedSheep.filter(s => s.id == sheepID);
    if(selected.length > 0) return true;
    if(sheepID == selectedID){
      setSelectedSheep([
        ...selectedSheep,
        sheep.filter(s => s.id == selectedID)[0]
      ]);
      setSelectedID('');
      return true;
    }
    
    return false;
  }

  const fetchSheepAndTransform = async () => {
    try {
      const fetchedSheep = await getSheepForUser(getUsername());
      const transformedSheep = fetchedSheep.map((s) => {
        const tSheep = {
          id: s._id,
           
          pid: s.premise.pid,
          gender: s.gender,
          isoNumber: s.tag.isoNumber,
          localMgmtNumber: s.tag.localMgmtNumber
        };

        return tSheep;
      })
      setSheep(transformedSheep);
    } catch (err) {
      toast.error(err);
    }
  }

  useEffect(() => {
    fetchSheepAndTransform();
    setSelectedID(query.get('id'))
  }, [])

  const baseColumns = [
    {
      id: 'isoNumber',
      name: 'Isonumber'
    },
    {
      id: 'localMgmtNumber',
      name: 'Local Management Number'
    },
    {
      id: 'gender',
      name: 'Gender'
    },
    {
      id: 'pid',
      name: 'Premise'
    }
  ];

  const columns = [
    {
      id: 'check',
      name: '' 
    },
    ...baseColumns
  ]

  const selectedSheepColumns = [
    ...baseColumns,
    {
      id: 'remove',
      name: ''
    }
  ]

  const selectAll = () => {
    setSelectedSheep(sheep);
  }

  const clearAll = () => {
    setSelectedSheep([]);
  }

  const sheepWithCheck = sheep.map(s => ({
      ...s,
      check: <CheckBox 
            checked={isSheepSelected(s.id)}
            onChange={() => {
              // Check if selected
              const alreadySelected = isSheepSelected(s.id);
              if(alreadySelected){
                // remove from selected sheep

                const newSelectedSheep = selectedSheep.filter(sheep => sheep.id != s.id);
                setSelectedSheep(newSelectedSheep);
              } else {
                const newSelectedSheep = [
                  sheep.filter(sheep => sheep.id == s.id)[0],
                  ...selectedSheep
                ];
                setSelectedSheep(newSelectedSheep);
              }
            }}
          />,
    }))

  const selectedSheepWithRemove = selectedSheep.map(s => ({
    ...s,
    remove: <RemoveButton
      onClick={(e) => {
        e.preventDefault;
        const newSelectedSheep = selectedSheep.filter(sheep => sheep.id != s.id);
        setSelectedSheep(newSelectedSheep)
      }}
    >Remove from Disposal</RemoveButton>
  }))
  
  if(redirect != ''){
    return <Redirect to={redirect} />;
  }

  return <>
    <PageCard
      line
      back
      title='Dispose of Sheep'
    >
      <SimpleCard
        title='Disposal Information'
      >
        <div>
          <label>Date of Disposal&nbsp;</label>
          <input
            type='date'
            value={disposalInformation.disposalDate}
            onChange={
              (e) => {
                const newDisposalInformation = {
                  ...disposalInformation,
                  disposalDate: e.target.value
                }

                setDisposalInformation(newDisposalInformation)
              }
            }
          />
          &nbsp;
          <label>Disposal Type&nbsp;</label>
          <select
            name='test'
            value={disposalInformation.disposalType}
            onChange={(e) => {
              const newDisposalInformation = {
                ...disposalInformation,
                disposalType: e.target.value
              };
              setDisposalInformation(newDisposalInformation)
            }}
          >
            <option 
              value='carcass'
              key='carcass'
            >Carcass Disposal Report</option>
            <option
              value='slaughter'
              key='slaughter'
            >Slaughter Report</option>
          </select>
        </div>
      </SimpleCard>
      <SimpleCard
        title='Filter and Select Sheep'
      >
        {/* Filter Section */}
        {/* Table */}
        <StripedTable
          columns={columns}
          rows={sheepWithCheck}
          max={10}
          paginate
        />
        <SelectAllSection 
          onClick={(e) => {
            e.preventDefault();
            selectAll();
          }}
          clearOnClick={(e) => {
            e.preventDefault();
            clearAll();
          }}
          showClearButton={selectedSheep.length > 0}
        />
      </SimpleCard>
      {(selectedSheep.length > 0) ? 
        <SimpleCard
          title='Sheep Selected to Dispose'
        >
          <StripedTable
            columns={selectedSheepColumns}
            rows={selectedSheepWithRemove}
            max={10}
            paginate
          />
          <DisposeSection
            count={selectedSheep.length}
            onClick={handleSubmit}
          />
          {(submitting) ? <SubmittingSpinner />:<></>}
        </SimpleCard>
      :<></>}
    </PageCard>
  </>
}

export default SheepDisposeView;