import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import propTypes from 'prop-types';

const Restricted = ({ component: Component, restricted, redirect, ...rest }) => {
	return (
		// TODO: Redirect to 403 screen upon failure
		<Route
			{...rest}
			render={(props) => (restricted ? <Redirect to={redirect} /> : <Component {...props} />)}
		/>
	);
};

Restricted.propTypes = {
  component: propTypes.any,
  restricted: propTypes.any,
  redirect: propTypes.any
}

export default Restricted;

// https://medium.com/@thanhbinh.tran93/private-route-public-route-and-restricted-route-with-react-router-d50b27c15f5e
