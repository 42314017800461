import React from 'react';
import styled from 'styled-components';
import { agGreen, viewColor, deleteColor } from './Colors.js';
import { EditButton } from '../components/CommonComponents';
import { StyledTable } from './TableComponents.js';
import propTypes from 'prop-types';


export default function TableComponentMini({ list, fields, viewCB, editCB, deleteCB }) {
  return (
    <div>
      {list.map((data) => {
        return (
          <StyledTable key={data} style={{ "margin-top": "10px", "table-layout": "fixed" }}>
            <thead>
              <tr>
                <th>Parameters</th>
                <th>Values</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((row) => {
                return (
                  <tr key={row.id}>
                    <td>{row.name}</td>
                    <td>{data[row.id]}</td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan="2">
                  <Buttons>
                    <EditButton bColor={viewColor} onClick={() => viewCB(data)}>View</EditButton>
                    <EditButton bColor={agGreen} onClick={() => editCB(data)}>Edit</EditButton>
                    <EditButton bColor={deleteColor} onClick={() => deleteCB(data)}>Delete</EditButton>
                  </Buttons>
                </td>
              </tr>
            </tbody>
          </StyledTable>
        );
      })}
    </div>
  );


}

TableComponentMini.propTypes = {
  list: propTypes.any,
  fields: propTypes.any,
  viewCB: propTypes.any,
  editCB: propTypes.any,
  deleteCB: propTypes.any
}

const Buttons = styled.div`
	display: flex;
	justify-content: space-between;
`;