import styled from 'styled-components';
import { agGreen, rowColorA, rowColorB } from './Colors.js';

export const StyledTable = styled.table`
	border-bottom: 2px solid ${agGreen};
	width: 100%;
	border-collapse: collapse;
	background-color: ${rowColorA};
	tr button {
		margin: 5px;
		margin-bottom: 7px;
	}
	th,
	td {
		padding-left: 10px;
	}
	th {
		padding-top: 10px;
		text-transform: uppercase;
		text-align: left;
		background-color: ${agGreen};
	}
	tr:nth-child(even) {
		background-color: ${rowColorB};
	}
`;

export const StyledTableCurved = styled(StyledTable)`
	border-radius: 10px;
	overflow: hidden;
	border: none;

	th,
	td {
		> input {
			border: none;
			border-radius: 10px;
			padding: 3px 6px;
			margin: 3px 0;
		}
	}
`;
