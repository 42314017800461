// Multiselect.js
import React from 'react';
import './Multiselect.css';
import propTypes from 'prop-types';

const Multiselect = ({title, name, options = []}) => {
	const numSelected = options.filter(o => o.checked).length;
	return <div >
		<span>{title} - {numSelected} selected</span> 
		<ul style={{padding: 0, listStyle: 'none', maxHeight: '100px',overflowY: 'auto'}}>
			{options.map(({label, id, checked, onClick}) => <>
				<li>
					<label>
						<input 
							type='checkbox' 
							name={name} 
							id={id} 
							value={id} 
							checked={checked} 
							onClick={onClick}
						/>
						{label}
					</label>
				</li>
			</>)}
		</ul>
	</div>
}

Multiselect.propTypes = {
  title: propTypes.node,
  name: propTypes.node,
  options: propTypes.node
}

export default Multiselect;
