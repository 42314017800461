import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ErrorArea from './ErrorArea';
import MessageArea from './MessageArea.js';
import { BackLink } from './CommonComponents.js';
import propTypes from 'prop-types';

const FormCard = ({ title, back, children, errors, messages }) => {
	const history = useHistory();

	return (
		<FormDiv>
			<FormTitle>
				{back && <BackLink onClick={history.goBack}>&larr; Back</BackLink>}
				{title}
			</FormTitle>
			{children}
			{errors && <ErrorArea formErrors={errors} />}
			{messages && messages.length > 0 && <MessageArea messages={messages} />}
		</FormDiv>
	);
};

FormCard.propTypes = {
  title: propTypes.node,
  back: propTypes.node,
  children: propTypes.node,
  errors: propTypes.node,
  messages: propTypes.node
}

export default FormCard;

const FormDiv = styled.div`
	max-width: fit-content;
	background-color: rgba(0, 0, 0, 0.6);
	border-radius: 10px;
	margin: auto;
	display: flex;
	flex-direction: column;
`;

const FormTitle = styled.div`
	padding: 10px;
	font-size: 20px;
	background-color: black;
	border-radius: 10px 10px 0px 0px;
	color: white;
`;
