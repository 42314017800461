import React from 'react';
import styled from 'styled-components';

const Body = styled.div`
	position: relative;
	width: 100%;
	height: 30px;
	background: #333333;
	display: flex;
	align-items: center;
	color: white;
`;

const Name = styled.div`
	position: relative;
	left: 10px;
`;

const Legal = styled.div`
	text-align: center;
	width: 100%;
`;

export default function Footer() {
	console.log({env: process.env})
	return (
		<Body>
			<Name>AgroLedger</Name>
			<Legal>Copyright © 2020 Canadian Sheep Federation. All Rights Reserved. Build {process.env.REACT_APP_BUILD} </Legal>
		</Body>
	);
}
