import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

// Route pages
import RestrictedRoute from './components/RestrictedRoute';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Landing from './components/Landing';
import Login from './components/Login';
import PasswordResetView from './views/PasswordResetView';
import Register from './components/Register';
import ForgotPasswordView from './views/ForgotPasswordView';
import HomePage from './views/HomePage.js';
import AdminRouter from './routes/AdminRouter';
import OperationRouter from './routes/OperationRouter';
import VehicleRouter from './routes/VehicleRouter';
import PremiseRouter from './routes/PremiseRouter';
import SheepDashboardView from './views/SheepDashboardView';
import SheepRouter from './routes/SheepRouter';
import SlaughterReportRouter from './routes/SlaughterReportRouter';
import UserView from './views/UserView';
import ErrorPage from './views/ErrorPage';
import Carousel from './views/CarouselView';
import TestNotifications from './views/TestNotifications';
import NotificationsView from './views/NotificationsView';
import EventRouter from './routes/EventRouter';
import PIDRouter from './routes/PIDRouter';
import { PageContainer } from './components/PageComponents.js';
import styled, { createGlobalStyle } from 'styled-components';
import {
  getToken,
  getRefreshToken,
  logoutAndClearTokens,
  loginAndSetToken,
  refreshTokens,
  getRoles,
  getUsername,
  decodeToken,
} from './utils/TokenUtils';

import axios from 'axios';
import RoleRouter from './routes/RoleRouter';
import UserRouter from './routes/UserRouter';
import SheepCsvImportView from './views/SheepCsvImportView';
import ReportRouter from './routes/ReportRouter';
import img from './images/b0.jpg';

// Create a timer to when the refresh token (! the access token) will expire
// TODO: Perhaps create a session expired page for a better user experience?
const tokenTimer = (() => {
  let timer = null;
  return () => {
    const refreshToken = decodeToken(getRefreshToken());
    if (!refreshToken) {
      window.location.reload(false);
    } else {
      if (timer) clearTimeout(timer);
      const time = refreshToken.exp * 1000 - new Date().getTime();
      timer = setTimeout(() => {
        logoutAndClearTokens();
        window.location.reload(false);
      }, time);
    }
  };
})();

const Background = () => {
  return <div style={{ backgroundImage: `url(${img})`}}>

  </div>
}

const axiosSetup = (setState) => {
  // Intercept all RESPONSES, and try to refresh token if needed
  axios.interceptors.response.use(
    (res) => res,
    (error) => {
      // Did it fail due to an authorization problem (aka tokens, aka 401)?
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }

      // Refresh the token from the session storage
      return refreshTokens()
        .then((res) => {
          // Refresh token was still valid
          // Reset the timer:
          tokenTimer();
          // Retry the request
          setState(res);
          error.response.config.headers.authorization = `Bearer ${res}`;
          error.response.config.baseURL = process.env.REACT_APP_API_URL;
          return axios(error.response.config);
        })
        .catch((err) => {
          // Refresh token is expired
          logoutAndClearTokens();
          window.location.reload(false);
          return Promise.reject(err);
        });
    }
  );

  // Intercept all REQUESTS, and inject the token into header
  axios.interceptors.request.use(
    (config) => {
      config.headers.authorization = `Bearer ${getToken()}`;
      config.baseURL = process.env.REACT_APP_API_URL;
      return config;
    },
    (error) => Promise.reject(error)
  );
};

// Import all the slideshow images (prefixed by a 'b')
function importAll(r) {
  return r.keys().map(r);
}

console.log({img});

const images = importAll(require.context('./images', false, /[b\d]\.(png|jpe?g|svg)$/));
const client = new WebSocket(process.env.REACT_APP_API_WebSocket);

export default function App() {
  const [token, setToken] = useState(getToken());
  const [selectedRole, setSelectedRole] = useState();
  const [image, setImage] = useState(images[0]);
  const [messages, setMessages] = useState([]);

  axiosSetup(setToken);

  useEffect(() => {
    let slide = 1;

    const slideShowInterval = setInterval(() => {
      setImage(images[slide]);
      slide = slide + 1 == images.length ? 0 : slide + 1; // For efficiency in not using modulo
    }, 5000);

    // The server sent us a message:
    client.onmessage = (e) => {
      const parsedData = JSON.parse(e.data);
      if (parsedData.type === 'MESSAGE') {
        toast.info(`Got: ${parsedData.data} from ${parsedData.sender}`);
        setMessages((messages) => [...messages, parsedData]);
      } else if (parsedData.type === 'LOGIN' && parsedData.data === 'Failure') {
        console.log('Failed somehow');
        // TODO: Maybe retry connection?
      }
    };

    client.onerror = (error) => {
      toast.error(`WebSocket error:`, error);
    };

    return () => clearInterval(slideShowInterval);
  }, []);

  useEffect(() => {
    // Try and get a notification ticket to authenticate with backend
    if (token) {
      // Start the timer:
      tokenTimer();
      const roles = getRoles();

      setSelectedRole((roles && roles.length > 0) ? roles[0].role : null);

      axios.get('/api/notifications/ticket').then(
        (res) => {
          // Send the authorization ticket:
          client.send(
            JSON.stringify({
              request: 'LOGIN',
              data: { wsTicket: res.data.ticket, username: getUsername() },
            })
          );
        },
        (err) => {
          console.log('Error during ticket retrieval', err);
        }
      );

      // Get user notifications
      axios.get(`/api/notifications/${getUsername()}`).then(
        (res) => {
          setMessages(res.data);
        },
        (err) => {
          console.log('Error during ticket retrieval', err);
        }
      );
    }
  }, [token]);

  

  const handleLogout = (e) => {
    e.preventDefault();
    logoutAndClearTokens();
    setToken(null);
  };

  const handleLogin = (e, user) => {
    e.preventDefault();
    return loginAndSetToken(user).then(
      (res) => {
        setToken(res.token);
        return res;
      },
      (err) => err.response
    );
  };

  function setOperation(selectedOpType) {
    setOpType(selectedOpType);
  }

  return (
    <AppContainer>
      <SlideShow slide={image} />
      <Background />
      <GlobalStyle />
      <Router>
        <Navbar
          token={token}
          handleLogout={handleLogout}
          setOperation={setOperation}
          messages={messages.length}
          selectedRole={selectedRole}
        />
        <PageContainer>
          <Switch>
            {/* Unrestricted routes */}
            <Route path='/' exact>
              {token ? <HomePage /> : <Landing />}
            </Route>
            <Route path='/register' component={Register} />
            <Route path='/login'>
              <Login handleLogin={handleLogin} token={token} />
            </Route>
            <Route path='/forget' component={ForgotPasswordView} />
            <Route path='/reset/:resetToken?' component={PasswordResetView} />
            {/* Only logged in users */}
            {!token && <Redirect to='/login' />}
            <Route exact path='/testNotifications'>
              <TestNotifications client={client} />
            </Route>
            <Route exact path='/notifications'>
              <NotificationsView messages={messages} />
            </Route>
            <Route path='/admin' render={() => <AdminRouter />} />
            <Route exact path='/users/:username' component={UserView} />
            <Route path='/roles' render={() => <RoleRouter />} />
            <Route path='/sheep' render={() => <SheepRouter />} />
            <Route path='/events' render={() => <EventRouter />} />
            <Route path='/pid' render={() => <PIDRouter />} />
            <Route path='/operations' render={() => <OperationRouter />} />
            <Route path='/vehicles' render={() => <VehicleRouter />} />
            <Route path='/premises' render={() => <PremiseRouter />} />
            <Route path='/slaughterReport' render={() => <SlaughterReportRouter />} />
            <Route path='/design' render={() => <>
              <SheepDashboardView>

              </SheepDashboardView>
            </>} />
            <RestrictedRoute
              path='/import/:route'
              redirect='/403'
              // restricted={restrictedCheck(createAnimal)}
              component={SheepCsvImportView}
            />
            <Route path='/onboarding/:page' component={Carousel} />
            {/* Only admins */}
            <Route path='/users/' render={() => <UserRouter />} />
            <Route path='/reports' render={() => <ReportRouter />} />
            
            <Route component={ErrorPage} />
          </Switch>
        </PageContainer>
        <StyledToastContainer>
          <ToastContainer
            transition={Zoom}
            position='top-center'
            autoClose='5000'
            hideProgressBar={false}
            closeOnClick={true}
            draggable={true}
            pauseOnHover={true}
          />
        </StyledToastContainer>
        <Footer />
      </Router>
    </AppContainer>
  );
}

const AppContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background: url(${img}) no-repeat center center fixed;
  background-size: cover;
`;

const SlideShow = styled.div`
  background: url(${img}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 0.5;
`;

const StyledToastContainer = styled.div`
  .Toastify__toast-container--top-center {
    top: 100px;
  }
`;

// To export things like default fonts, etc
const GlobalStyle = createGlobalStyle`
	* {
		font-family: 'Roboto' !important;
	}
`;
