import React from 'react';
import { Button } from '../components/CommonComponents.js';
import PageCard from '../components/PageCard.js';
import { Link } from 'react-router-dom';
import { getUsername, getRoles } from '../utils/TokenUtils.js';
import styled from 'styled-components';

const ContactAdmin = () => <PageCard title='Welcome to Agroledger'>
	<p>Looks like you&apos;ve just created an account! To finish getting set up, please contact: <a href="mailto:kip@cansheep.ca">kip@cansheep.ca</a></p>
</PageCard>

const HomePage = () => {
	const roles = getRoles();
	console.log({roles});
	return (
		(roles && roles.length == 0) ? 
			<ContactAdmin /> : 
			<PageCard title='Home'>
			<WelcomeDiv>Welcome {getUsername()}</WelcomeDiv>
			<hr />
			{(getRoles() && getRoles().includes('admin')) ? <>
			<Link to='/events'>
				<Button>Events</Button>
			</Link>
			<Link to='/sheep'>
				<Button>Sheep</Button>
			</Link>
			<Link to='/users'>
						<Button>Users</Button>
					</Link>
			</> : <></>}
			{/* {getRoles() && getRoles().includes('admin') && ( */}
				<>
					<Link to='/operations'>
						<Button>Operations</Button>
					</Link>
					<Link to='/premises'>
						<Button>Premises</Button>
					</Link>
					<Link to='/vehicles'>
						<Button>Vehicles</Button>
					</Link>
          <Link to='/sheep'>
            <Button>Sheep</Button>
          </Link>
				</>
			{/* )} */}
			<Link to={`/users/${getUsername()}`}>
				<Button>My Profile</Button>
			</Link>
		</PageCard>
	);
};

export default HomePage;

const WelcomeDiv = styled.div`
	font-size: 20px;
`;
