import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import error from '../images/error.png';
import { Text, TextContainer } from '../components/CommonComponents';
import { Redirect } from 'react-router-dom';

export default function ErrorPage() {
	const [redirect, setRedirect] = useState(false);
	const { pathname } = useLocation();
	const error = {};

	useEffect(() => {
		setTimeout(() => {
			setRedirect(true);
		}, 3000);
	}, []);

	if (redirect) {
		return <Redirect to={'/'} />;
	}

	switch (pathname) {
		case '/403':
			error.type = '403';
			error.message = 'Forbidden :(';
			break;
		default:
			error.type = '404';
			error.message = "Let's get you back home";
	}

	return (
		<ErrorContainer>
			<ErrorImage />
			<TextContainer>
				<Text style={{ color: 'black' }}>{error.type}</Text>
				<Text>{error.message}</Text>
			</TextContainer>
		</ErrorContainer>
	);
}

const ErrorContainer = styled.div`
	margin: auto;
	display: flex;
`;

const ErrorImage = styled.img.attrs({
	src: `${error}`,
})`
	width: 600px;
	height: 400px;
	margin: 10px;
`;
