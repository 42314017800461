import React, { useState, useEffect } from 'react';
import { FormInputWrapper, FormInputLabel, FormTextInput, FormSelectInput, FormTextList } from '../components/CommonComponents';
import { agGreen } from '../components/Colors.js';
import FormCard from '../components/FormCard.js';
import { Redirect } from 'react-router-dom';
import PageCard from '../components/PageCard.js';
import { Button, FormContainer } from '../components/CommonComponents.js';
import Loader from 'react-loader-spinner';
import LoadingCard from '../components/LoadingCard.js';
import { getUsername } from '../utils/TokenUtils';

const axios = require('axios');

export default function TagReplacementCreate() {
	const [form, setForm] = useState({
		reporter: '',
		owner: '',
		replacementPid: '',
		originPid: '',
		oldId: '',
		replacementId: '',
		vehicle: '',
	});
	const [redirected, setRedirected] = useState(false);
	const [fieldErrors, setFieldErrors] = useState({});
	const [id, setId] = useState();
	const [load, setLoad] = useState();
	const username = getUsername();

	const provinces = ['NL', 'PE', 'NS', 'NB', 'QC', 'ON', 'MB', 'SK', 'AB', 'BC', 'YT', 'NT', 'NU'];

	const account = [
		{ name: 'Event Reporter: ', hint: 'Mike Brown', id: 'reporter' },
		{ name: 'Sheep Owner:', hint: "Christine James", id: 'owner' },
		{ name: 'Tag Replacement Site: ', hint: 'ON3456789', id: 'replacementPid' },
		{ name: 'Sheep\'s Origin Site: ', hint: 'ON9876542', id: 'originPid' },
		{ name: 'Old Tag: ', hint: '124 000 310 000 101', id: 'oldId' },
		{ name: 'New Tag: ', hint: '124 000 310 020 965', id: 'replacementId' },
		{ name: 'Vehicle: ', hint: 'CABN 202', id: 'vehicle' },
	];
	// function getOperations(){
	// 	axios.get(`/api/operations/${username}/all`).then(
	// 		(res) =>{
	// 			console.log(res.data);
	// 			setOperations(res.data);
	// 		})
	// }
	// useEffect(() => {
	// 	getOperations();
	// }, []);

	async function post() {
		setLoad(true);
		console.log('the form is ', form);
		await axios.post('/api/tagreplacement', form).then(
			(res) => {
				// setId(res.data._id);
				setRedirected(true);
				console.log('res is', res);
			},
			(err) => {
				console.log(err.response.data);
				console.log('error posting occured');
				setFieldErrors(err.response.data);
				// console.log(`Error during update: ${JSON.stringify(err.response.data.Error)}`);
				// setErrorText(JSON.stringify(err.response.data.Error));
			}
		);
		console.log('load point reached');
		setLoad(false);
	}
	// run when submit button is clicked
	const submitEvent = (e) => {
		console.log('the form is ', form);
		// if (form.pid === '') {
		// 	form.pid = null;
		// }
		post();
		e.preventDefault();
	};

	return (
		<>
		{!load ?
		<FormCard title='Create Tag Replacement Event' back='/events' errors={fieldErrors}>
			<FormContainer onSubmit={submitEvent}>
				{account.map((element) => {
						
						return (
							<FormInputWrapper key={element.id}>
							<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
								<FormTextInput
									placeholder={element.hint}
									id='input'
									valid={true}
									name={element.id}
									value={form[element.id]}
									onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}>
								</FormTextInput>
							</FormInputWrapper>
						);
					}
				)}
				<div style={{ textAlign: 'center' }}>
					<Button>Create</Button>
				</div>
			</FormContainer>
			{redirected ? <Redirect to={`/events/`} /> : null}
		</FormCard> :
		<PageCard title="Processing your request ..." loader={<Loader type="Oval" color={agGreen} height={50} width={150}/>}>
			<LoadingCard/>
		</PageCard>}
	</>
	);
}
