import styled from 'styled-components';

export const FormContainer = styled.form`
	width: fit-content;
	max-width: 500px;
	color: white;
	position: relative;
	margin: 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
`;

export const FormSectionHeader = styled.div`
	font-size: 30px;
	width: 100%;
	text-align: center;
	margin-bottom: 20px;
	flex-basis: 100% !important;
`;

export const FormInputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 25px;
	margin-top: 0;
	width: 400px;
`;

export const FormInputLabel = styled.label`
	font-family: Inter-Regular;
	font-size: 15px;
	margin: 0 0 10px 0;
`;

export const FormTextInput = styled.input`
	height: 30px;
	border-radius: 10px;
	background: ${(props) => (props.invalid ? '#DE3434' : '#C2C6CA')};
	font-family: Inter-Regular;
	font-size: 14px;
	outline: none;
	border: none;
	padding: 2px 10px;

	&:disabled {
		background-color: silver;
		color: dimgray;
		cursor: not-allowed;
	}
`;

export const FormPasswordContainer = styled.div`
	display: flex;
	position: relative;

	> input {
		width: 100%;
		padding-right: 30px;
	}
`;

export const FormPassPeek = styled.span`
	position: absolute;
	z-index: 1;
	cursor: pointer;
	top: 50%;
	transform: translateY(-50%);
	right: 5px;
`;

export const FormSelectInput = styled.select`
	width: 100%;
	padding: 8px;
	border-radius: 10px;
	background: ${(props) => (props.invalid ? '#DE3434' : '#fff')};
	outline: none;
	border: none;

	&:disabled {
		cursor: not-allowed;
	}
`;

export const FormTextList = styled.div`
	padding: 0;

	> select {
		border-radius: 0;
	}

	*:first-child {
		background: #eee;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	*:last-child {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	*:nth-child(odd) {
		background: #eee;
	}
`;

export const FormTextListItem = styled.li`
	cursor: pointer;
	position: relative;
	padding: 10px;
	list-style-type: none;
	background: #c2c6ca;
	color: black;

	&:hover {
		font-weight: bold;
	}

	${({ remove }) =>
		remove &&
		`
    &::after {
		position: absolute;
		top: 0;
		right: 0;
		padding: 10px;
		content: '\u00D7';
	}
  `}
`;

export const FormRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	color: white;
	margin-right: 10px;

	* {
		width: 200px;
	}
`;

export const BackLink = styled.button`
	background: none;
	border: none;
	color: white;
	text-decoration: underline;
	cursor: pointer;
	margin-bottom: 10px;
	display: block;
	font-size: 16px;
`;

export const Button = styled.button`
	background: #17a737;
	border: none;
	border-radius: 10px;
	padding: 15px 30px;
	margin: 0 5px 10px;
	outline: none;
	cursor: pointer;
	font-size: 20px;
	color: white;
	font-weight: bold;
	width: fit-content;
	align-self: center;

	&:disabled {
		background-color: silver;
		color: dimgray;
		cursor: not-allowed;
	}
`;

export const TextContainer = styled.div`
	text-align: center;
	margin-bottom: 20px;
	align-self: center;
`;

export const Text = styled.div`
	font-size: 50px;
	width: 100%;
	text-align: center;
	font-weight: bold;
	color: #545741;
`;

export const EditBox = styled.div`
	&:nth-child(even) {
		background-color: #f2f2f2;
	}
	&:nth-child(odd) {
		background-color: white;
	}
	font-size: 20px;
	max-width: 700px;
	height: 25px;
	color: white;
	border-radius: 5px;
`;

export const EditKey = styled.h1`
	font-size: 20px;
	margin-right: 10px;
	color: white;
`;

export const EditButton = styled.button`
	max-width: 113px;
	height: 25px;
	font-size: 15px;
	color: white;
	margin-left: 10px;
	margin-right: 10px;
	border-radius: 10px;
	background-color: ${(props) => (props.bColor ? props.bColor : '#4caf50')};
	border: 2px solid #008000;
	box-shadow: 0 2px #999;
	cursor: pointer;

	&:hover {
		border-color: white;
		border: 1px solid;
	}
`;
