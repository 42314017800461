import React, { useEffect, useState } from 'react';
import { useParams, Link} from 'react-router-dom';
import axios from 'axios';
import {
	FormContainer,
	Button,
} from '../components/CommonComponents';
import styled from 'styled-components';

import FormCard from '../components/FormCard.js';

import { getUsername } from '../utils/TokenUtils.js';
import {
	updateOperation,
	getOperationById,
} from '../utils/OperationUtils.js';
import {
	getOperationTypes,
} from '../utils/OperationTypeUtils';
import DropDown from '../components/DropDown.js';
import Input from '../components/Input.js';
import MessageArea from '../components/MessageArea';

export default function OperationUpdateView() {
	const [form, setForm] = useState({
	});
	const [fieldErrors, setFieldErrors] = useState({});
	const [operationTypes, setOperationTypes] = useState([]);
	const [messages, setMessages] = useState([]);

	const { id } = useParams();
	
	const formInputs = [
		{
			name: 'Business Name',
			hint: 'Name',
			id: 'businessName',
		},
		{
			name: 'Phone Number',
			hint: '647 222 2018',
			id: 'phoneNumber'
		},
	];

	const cvspDropDown = {
		name: <span>CVSP (
			<StyledLink to='/operations/learnMore'>Learn more</StyledLink>)
		</span>,
		id: 'cvsp'
	};

	const operationTypeDropDown = {
		name: 'Operation Type',
		id: 'operationType'
	};

	const sfcpDropDown = {
		name: <span>SFCP (
			<StyledLink to='/operations/learnMore'>Learn More</StyledLink>)
		</span>,
		id: 'sfcp'
	};

	const submitOperation = async (e) => {
		e.preventDefault();

		try {
			const res = await updateOperation(form, id);
			console.log({res});
			setMessages([
				...messages,
				'Operation updated!'
			]);
		} catch (err) {
			console.log({err});
			setFieldErrors(err.response.data);
		}
	};

	useEffect(() => {
		const fetchOperation = async () => {
			let fetchedOperation = await getOperationById(id);
			let keys = Object.keys(fetchedOperation);
			keys = keys.filter(k => k.startsWith('_'));
			keys.forEach(k => delete(fetchedOperation[k]));
			delete(fetchedOperation['user']);
			console.log({fetchedOperation});
			setForm({
				...form,
				...fetchedOperation,	
			});
		};
		fetchOperation();
	}, []);

	useEffect(() => {
		const fetchAndSetOpTypes = async () => {
			const fetchedOpTypes = await getOperationTypes();
			console.log({fetchedOpTypes});

			setOperationTypes(fetchedOpTypes);
		};
		fetchAndSetOpTypes();
	}, []);

	return (
		<>
			<FormCard 
				title='Update an Operation' 
				errors={fieldErrors} 
				messages={messages}
				back
			>
				<FormContainer onSubmit={submitOperation}>
					{formInputs.map(i => <>
						<Input
							id={i.id}
							placeholder={i.hint}
							labelName={i.name}
							onChange={(e) => setForm({
								...form,
								[e.target.name]: e.target.value
							})}
							value={form[i.id]}
							required
						/>
					</>)}

					{/* dropdowns */}
					<DropDown
						id={operationTypeDropDown.id}
						name={operationTypeDropDown.name}
						onChange={(e) => setForm({
							...form,
							[e.target.id]: e.target.value
						})}
						value={form[operationTypeDropDown.id]}
						options={
							operationTypes.map(t => ({
								name: t.niceName,
								value: t.name
							}))
						}
						required
					/>
					<DropDown
						id={cvspDropDown.id}
						name={cvspDropDown.name}
						onChange={(e) => setForm({
							...form,
							[e.target.id]: e.target.value
						})}
						value={form[cvspDropDown.id]}
						options={[
							{
								name: 'Yes',
								value: true,
							},
							{
								name: 'No',
								value: false,
							}
						]}
						required
					/>
					<DropDown
						id={sfcpDropDown.id}
						name={sfcpDropDown.name}
						onChange={(e) => setForm({
							...form,
							[e.target.id]: e.target.value
						})}
						value={form[sfcpDropDown.id]}
						options={[
							{
								name: 'Yes',
								value: true,
							},
							{
								name: 'No',
								value: false
							}
						]}
						required
					/>

					<div style={{ textAlign: 'center' }}>
						<Button>Update</Button>
					</div>
				</FormContainer>
				{/*redirected ? <Redirect push to={`/operations/${id}`} /> : null */}
			</FormCard>
		</>
	);
}

const StyledLink = styled(Link)`
	color: white;
`;
