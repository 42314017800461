import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import PageCard from '../components/PageCard.js';
import axios from 'axios';
import DescriptionCard from '../components/DescriptionCard.js';
import TableComponent from '../components/TableComponent';
import TableComponentMini from '../components/TableComponentMini';
import { getVehicles, deleteVehicleById } from '../utils/VehicleUtils.js';
import { getUsername} from '../utils/TokenUtils';

const VehiclesView = () => {
	const [vehicles, setVehicles] = useState([]);
	const [redirect, setRedirect] = useState('');
	const [windowSize, setWindowSize] = useState(0);

	const fetchVehicles = async () => {
		const result = await axios.get(`/api/vehicles/user/${getUsername()}`);
		console.log({result});
		const transformedVehicles = result.data.map((v) => ({
			id: v._id,
			name: v.name,
			states: v.states.join(', '),
			provinces: v.provinces.join(', '),
			licensePlateNum: v.licensePlateNum,
		}));
		setVehicles(transformedVehicles);
	};

	useEffect(() => {
		const asyncFetch = async () => {
			await fetchVehicles();
		};
		asyncFetch();
	}, []);

	if (window.innerWidth !== windowSize) {
		setWindowSize(window.innerWidth);
		console.log(windowSize);
	}

	if (redirect !== '') {
		return <Redirect push to={redirect} />;
	}

	const deleteVehicle = async ({ id }) => {
		await deleteVehicleById(id);
		await fetchVehicles();
	};

	const viewVehicle = ({ id }) => {
		setRedirect(`/vehicles/${id}`);
	};

	const editVehicle = ({ id }) => {
		setRedirect(`/vehicles/${id}/update`);
	};

	const tableHeadings = [
		{ name: 'Vehicle Name', id: 'name' },
		{ name: 'License Plate Number', id: 'licensePlateNum' },
		{ name: 'Canadian Provinces/Territories', id: 'provinces' },
		{ name: 'U.S. States', id: 'states' },
	];

	const vehiclesFields = [
		{ name: 'Vehicle Name', id: 'name' },
		{ name: 'License Plate Number', id: 'licensePlateNum' },
		{ name: 'Canadian Provinces/Territories', id: 'provinces' },
		{ name: 'U.S. States', id: 'states' },
	];

	return (
		<PageCard title='Vehicles' to={`/vehicles/create`} back>
			<DescriptionCard page='Vehicle'></DescriptionCard>
			{vehicles.length === 0 ? (
				<>Loading...</>
			) : (
				<>
					{windowSize < 1300 ? (
						<TableComponentMini
							fields={vehiclesFields}
							list={vehicles}
							deleteCB={deleteVehicle}
							viewCB={viewVehicle}
							editCB={editVehicle}
						/>
					) : (
						<TableComponent
							headings={tableHeadings}
							data={vehicles}
							view={{ cb: viewVehicle, title: 'View' }}
							edit={{ cb: editVehicle, title: 'Edit' }}
							remove={{ cb: deleteVehicle, title: 'Delete' }}
							actions
						/>
					)}
				</>
			)}
		</PageCard>
	);
};

export default VehiclesView;
