import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from '../components/CommonComponents';
import FormCard from '../components/FormCard.js';
import SheepForm from '../components/SheepForm.js';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import LoadingCard from '../components/LoadingCard.js';
import PageCard from '../components/PageCard.js';
import { agGreen } from '../components/Colors.js';
import { getUsername } from '../utils/TokenUtils';

const axios = require('axios');

const fetchBreeds = async() => {
  try {
    const request = await axios.get(`/api/breed`);
    const fetchedBreeds = request.data;
    return fetchedBreeds;
  } catch (err) {
    throw new Error(`An error occurred fetching breeds`, err);
  }
}

const fetchPremises = async() => {
  try {
    const username = getUsername();
    const request = await axios.get(`/api/premises/user/${username}`);
    const fetchedPremises = request.data;
    return fetchedPremises;
  } catch (err) {
    throw new Error(`An error occurred fetching premises`);
  }
}

export default function SheepCreateManualView() {
	const [data, setData] = useState({ genders: [{ name: 'Male' }, { name: 'Female' }] });
  const [breeds, setBreeds] = useState([]);
  const [premises, setPremises] = useState([]);
	const [fieldErrors, setErrors] = useState({});
	const [redirect, setRedirect] = useState(false);
	const [load, setLoad] = useState();

  const fetchBreedsAndPremises = async() => {
    try {
      const fetchedBreeds = await fetchBreeds();
      const fetchedPremises = await fetchPremises();
      setBreeds(fetchedBreeds);
      setPremises(fetchedPremises);
    } catch (err){
      console.log({err});
      toast.error(err.message);
    }
  }

	useEffect(() => {
		// Getting all breeds
    fetchBreedsAndPremises();
    
		axios
			.get('/api/breed/')
			.then((res) => {
				setData({ ...data, breeds: res.data });
			})
			.catch((err) => {
				console.log(`Error during breed retrieval: ${JSON.stringify(err)}`);
			});
	}, []);

	if (redirect) {
		return <Redirect to='/sheep' push />;
	}

	async function create() {
		setLoad(true);
		const { breeds, genders, ...filtered } = data;
		await axios.post('/api/sheep/', filtered).then(
			(res) => {
				toast.success('Sheep created');
				setRedirect(true);
			},
			(err) => {
				let set_errors = {};
				const { errors } = err.response.data;
				for (const [field, val] of Object.entries(errors)) {
					set_errors[field] = val.properties.message;
				}
				setErrors(set_errors);
			}
		);
		setLoad(false);
	}

	// run when submit button is clicked
	const submitSheep = (e) => {
		create();
		e.preventDefault();
	};

	return (
		<>
			{!load ? (
				<FormCard title='Create a new sheep' errors={fieldErrors} back>
					<SheepForm errors={fieldErrors} breeds={breeds} premises={premises} />
				</FormCard>
			) : (
				<PageCard
					title='Processing your request ...'
					loader={<Loader type='Oval' color={agGreen} height={50} width={150} />}
				>
					<LoadingCard />
				</PageCard>
			)}
		</>
	);
}
