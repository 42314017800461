// DropDown.js
import React from 'react';
import {
	FormInputWrapper,
	FormInputLabel,
	FormTextList,
	FormSelectInput,
} from './CommonComponents.js';
import propTypes from 'prop-types';

const DropDown = ({id, name, value, onChange, options, required}) => <>
	<FormInputWrapper key={id} >
		<FormInputLabel htmlFor='input'>
			{name}
			{(required) ? <span style={{color: 'red'}}>*</span>:<></>}
		</FormInputLabel>
		<FormTextList>
			<FormSelectInput
				name={name}
				id={id}
				value={value}
				onChange={onChange}
			>
				{options.map(o => <>
						<option value={o.value} >
							{o.name}
						</option>	
					</>
				)}
			</FormSelectInput>
		</FormTextList>
	</FormInputWrapper>
</>

DropDown.propTypes = {
  id: propTypes.node,
  name: propTypes.node,
  value: propTypes.node,
  onChange: propTypes.node,
  options: propTypes.node,
  required: propTypes.node
}

export default DropDown;
