import React from 'react';
import PageCard from '../components/PageCard.js';
import { rowColorA, rowColorB, agGreen } from '../components/Colors.js';
import styled from 'styled-components';
import { EditButton } from '../components/CommonComponents';
import { Link } from 'react-router-dom';
import EventsDescriptionCard from '../components/EventsDescriptionCard';
import checkmark from '../images/checkmark.png';

export default function EventsView() {
  const tableHeadings = [
    { name: '', id: 'mandatory' },
    { name: 'Event Name', id: 'name' },
    { name: 'Description', id: 'description' },
    { name: 'Create Event', id: 'NA' },
  ];
  const decisons = [
    { name: 'I want to report tagging an animal', to: '/events/decision/animaltag' },
    { name: 'I want to report the death of an animal', to: '/events/decision/animaldeath' },
    {
      name: 'I want to report animal(s) leaving my premises',
      to: '/events/decision/animaldeparture',
    },
    {
      name: 'I want to report receiving animals at my premises',
      to: '/events/decision/animalarrival',
    },
    { name: 'I am moving animals between premises', to: '/events/decision/premisemovement' },
    { name: 'I want to report imported animals', to: '/events/decision/animalimport' },
    { name: 'I want to report exported animals', to: '/events/decision/animalexport' },
    {
      name: 'I want to report the presence of an animal(s)',
      to: '/events/decision/animalsighting',
    },
    {
      name: 'I want to report the results of a medical test',
      to: '/events/decision/animalmedical',
    },
    {
      name: 'I want to create a slaughter report',
      to: '/slaughterReport',
    },
    {
      name: 'I want to attach a document to an animal',
      to: '/events/documentupload/',
    },
  ];

  const events = [
    {
      mandatory: true,
      name: 'Tag Replacement',
      description: 'You can create a tag replacement here',
      to: '/events/tagreplacement',
    },
    {
      mandatory: false,
      name: 'Tag Activation',
      description: 'You can activate a tag here',
      to: '/events/tagactivation',
    },
    {
      mandatory: true,
      name: 'Animal Movement',
      description: 'You can report an animal movement here',
      to: '/import/move',
    },
    {
      mandatory: true,
      name: 'Animal Import',
      description: 'You can report any imported animal here',
      to: '/events/animalImport',
    },
  ];
  return (
    <div>
      <PageCard title='Events' back>
        <EventsDescriptionCard />
        <EventsTable>
          <thead>
            <tr>
              <th>What would you like to do ?</th>
            </tr>
          </thead>
          <tbody>
            {decisons.map((decision) => (
              <tr style={{ height: '35px' }} key={decision}>
                <td>
                  <StyledLink to={decision.to}>{decision.name}</StyledLink>
                </td>
              </tr>
            ))}
          </tbody>
        </EventsTable>
        <br></br>
        <DescriptionBox>
          <DescriptionTitle>Legend</DescriptionTitle>
          <DescriptionContent>
            <TextContainer>
              <Img src={checkmark} />{' '}
              <div> : Events labelled with this icon are mandatory events.</div>
            </TextContainer>
          </DescriptionContent>
        </DescriptionBox>
        <EventsTable>
          <thead>
            <tr>
              {tableHeadings.map((heading) => (
                <th key={heading.id}>{heading.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event}>
                {/* <td>{event.mandatory}</td> */}
                <td>{event.mandatory && <Img src={checkmark} />}</td>
                <td>{event.name}</td>
                <td>{event.description}</td>
                <td>
                  <Link to={event.to}>
                    <EditButton>Create</EditButton>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </EventsTable>
      </PageCard>
    </div>
  );
}

const EventsTable = styled.table`
  table-layout: fixed;
  border-bottom: 2px solid ${agGreen};
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  border: none;
  background-color: ${rowColorA};

  tr button {
    margin: 5px;
    margin-bottom: 7px;
  }

  th,
  td {
    padding-left: 10px;
    > input {
      border: none;
      border-radius: 10px;
      padding: 3px 6px;
      margin: 3px 0;
    }
  }

  th {
    padding-top: 10px;
    text-transform: uppercase;
    text-align: left;
    background-color: ${agGreen};
  }

  tr:nth-child(even) {
    background-color: ${rowColorB};
  }
`;

const Img = styled.img`
    justify-content: center
    height: 25px;
    width: 25px;
    margin-right: 10px;
`;

const StyledLink = styled(Link)`
  color: white;
  font-style: italic;
`;

const DescriptionBox = styled.div`
  background-color: grey;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px 10px 10px;
`;

const DescriptionContent = styled.div`
  color: white;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DescriptionTitle = styled.div`
  color: white;
  font-weight: bold;

  text-decoration: bold;
  font-size: 25px;
  margin-bottom: 10px;
`;
