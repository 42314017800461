
// SheepReportListView.js
import React, { useEffect, useState } from 'react';
import PageCard from './PageCard';
import SimpleCard from './SimpleCard';
import { toast } from 'react-toastify';
import StripedTable from './StripedTable';
import ViewEditDispose from './ViewEditDispose';
import { 
  DescriptionArea, 
  FetchPremises, 
  FilterSection,
  NAField
} from './SheepReportComponents';

const SheepReportListView = ({
  title, 
  description, 
  fetchReportsFunction,
  detailEndpoint
}) => {
  /* States */
  const [reports, setReports] = useState([]);
  const [premises, setPremises] = useState([]);
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    pid: '',
    gender: ''
  });
  const [tempFilter, setTempFilter] = useState({
    startDate: '',
    endDate: '',
    pid: '',
    gender: ''
  })

  /* Transforms */

  const fetchReportsAndTransform = async () => {
    try {
      let fetchedReports = await fetchReportsFunction();
      fetchedReports.forEach(r => {
        r.actions = <ViewEditDispose 
          id={r._id} 
          endpoint={detailEndpoint} 
          hideDispose
          hideEdit
        />;
        if(!r.localMgmtNumber) r.localMgmtNumber = <NAField />;
        if(!r.isoNumber) r.isoNumber = <NAField />;
        if(!r.gender) r.gender = <NAField />;
        if(!r.subgender) r.subgender = <NAField />;
      });
      
      setReports(fetchedReports);
    } catch (err) {
      toast.error(err);
    }
  }

  const fetchPremisesAndTransform = async () => {
    try {
      const fetchedPremises = await FetchPremises();
      const transformedPremises = fetchedPremises.map(p => {
        const transformedPremise = {
          value: p.pid,
          name: `${p.name} - ${p.pid}`
        }
        return transformedPremise;
      });

      setPremises(transformedPremises);
    } catch (err) {
      toast.error(err);
    }
  }
  
  useEffect(() => {
    fetchReportsAndTransform();
    fetchPremisesAndTransform();
  }, [])

  /* Handlers */

  const updateTempFilter = (updatedValue, filterID) => {
    const newFilter = {...tempFilter};
    newFilter[filterID] = updatedValue;
    if(filterID == 'startDate'){
      if(tempFilter.endDate == ''){
        newFilter.endDate = updatedValue;
      } else {
        const startDate = new Date(updatedValue);
        const endDate = new Date(tempFilter.endDate)
        if(startDate > endDate) newFilter.endDate = updatedValue;
      }

      if(updatedValue == '') newFilter.endDate = '';
    }

    if(filterID == 'endDate'){
      if(tempFilter.startDate == ''){ 
        newFilter.startDate = updatedValue;
      } else {
        const startDate = new Date(tempFilter.startDate);
        const endDate = new Date(updatedValue);
        if(endDate < startDate) newFilter.startDate = updatedValue;
      }

      if(updatedValue == '') newFilter.startDate = '';
    }
    setTempFilter(newFilter);
  }

  const handleApply = (e) => {
    e.preventDefault();
    const newFilter = {...tempFilter};
    setFilter(newFilter);
  }

  const handleClear = (e) => {
    e.preventDefault();
    const newFilter = {
      startDate: '',
      endDate: '',
      pid: '',
      gender: ''
    };

    setTempFilter(newFilter);
    setFilter(newFilter);
  }

  /* Data */
  let filterFields = [
    {
      id: 'startDate',
      name: 'Start Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'startDate');
      },
      onClick: (e) => {
        e.preventDefault();
        updateTempFilter('', 'startDate');
      },
    },
    {
      id: 'endDate',
      name: 'End Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'endDate');
      },
      onClick: (e) => {
        updateTempFilter('', 'endDate');
      }
    },
    {
      id: 'pid',
      name: 'Premise',
      dropdown: true,
      options: premises,
      onChange: (e) => {
        updateTempFilter(e.target.value, 'pid');
      }
    },
    {
      id: 'gender',
      name: 'Gender',
      dropdown: true,
      options: [{
          value: 'male',
          name: 'Male'
        },
        {
          value: 'female',
          name: 'Female'
        }],
      onChange: (e) => {
        updateTempFilter(e.target.value, 'gender');
      }
    }
  ]

  const columns = [
    {
      id: 'action',
      name: 'Action taken'
    },
    {
      id: 'pid',
      name: 'Premise ID'
    },
    {
      id: 'isoNumber',
      name: 'Isonumber'
    },
    {
      id: 'localMgmtNumber',
      name: 'Local Management Number'
    },
    {
      id: 'gender',
      name: 'Gender'
    },
    {
      id: 'subgender',
      name: 'Sub-Gender'
    },
    {
      id: 'createdAt',
      name: 'Created At'
    },
    {
      id: 'actions',
      name: 'Actions'
    }
  ]

  const filteredReports = reports.filter(r => {
    let filterResult = true;
    const filterKeys = Object.keys(filter);
    filterKeys.forEach(key => {
      if(filter[key] == '') return;
      if(key.includes('Date')) return;
      if(r[key] != filter[key]) filterResult = false;
    })
    const reportDate = new Date(r['createdAt']);
    const startDate = new Date(filter.startDate);
    const endDate = new Date(filter.endDate);
    if(reportDate < startDate) return false;
    if(reportDate > endDate) return false;
    return filterResult;
  });

  return <>
    <PageCard title={title} line back>
      <DescriptionArea>
        {description}
      </DescriptionArea>
      <SimpleCard
        title="Filters"
        description="Use these filters to narrow down reports to a specific date, premise or gender"
      >
        <FilterSection 
          data={filterFields} 
          onApply={handleApply}
          onClear={handleClear}
          values={tempFilter}
        />
      </SimpleCard>
      <SimpleCard
        title="Reports"
      >
        <StripedTable 
          columns={columns}
          rows={filteredReports}
          ShowFilters
          ShowCount
          max={10}
        />
      </SimpleCard>
    </PageCard>
  </>;
};

export default SheepReportListView;