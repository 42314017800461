// SheepOperationView.js
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import PageCard from '../components/PageCard';
import { FilterSection, NAField } from '../components/SheepReportComponents';
import SimpleCard from '../components/SimpleCard';
import StripedTable from '../components/StripedTable';
import ViewEditDispose from '../components/ViewEditDispose';

const GetSheepByOperation = async (operationID) => {
  const endpoint = `/api/sheep?premise=true&operation=${operationID}`;
  try {
    const request = await axios.get(endpoint);
    const fetchedInfo = request.data;
    return fetchedInfo;
  } catch (err) {
    throw new Error(`An error occurred fetching sheep by operation: ${err.message}`);
  }
}

const GetOperationInfo = async (operationID) => {
  const endpoint = `/api/operations/${operationID}`;
  try {
    const request = await axios.get(endpoint);
    const fetchedInfo = request.data;
    return fetchedInfo;
  } catch (err) {
    throw new Error(`An error occurred fetching operation info: ${err.message}`);
  }
}
const transformPremises = (premises) => {
  const transformedPremises = premises.map(p => {
    const transformedPremise = {
      value: p.pid,
      name: `${p.name} - ${p.pid}`
    };

    return transformedPremise;
  });

  return transformedPremises;
}
  
const SheepOperationView = () => {
  const [sheep, setSheep] = useState([]);
  const [operation, setOperation] = useState({});
  const [premises, setPremises] = useState([]);
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    pid: '',
    gender: ''
  });

  const [tempFilter, setTempFilter] = useState({
    startDate: '',
    endDate: '',
    pid: '',
    gender: ''
  });

  const params = useParams();
  const id = params.id;

  const fetchSheepAndTransformForOperation = async () => {
    try {
      let fetchedSheep = await GetSheepByOperation(id);
      const sheepEndpoint = '/sheep/'
      // Need to flatten sheep
      fetchedSheep.forEach(s => {
        s.actions = <ViewEditDispose
          hideEdit
          id={s._id}
          endpoint={sheepEndpoint}
         />;
        s.isoNumber = (s.tag.isoNumber) ? s.tag.isoNumber : <NAField />;
        s.pid = (s.premise.pid) ? s.premise.pid : <NAField />;
        s.localMgmtNumber = (s.tag.localMgmtNumber) ? s.tag.localMgmtNumber : <NAField />;
        // Should capitalize
        if(!s.gender) s.gender = <NAField />;
        if(!s.subgender) s.subgender = <NAField />;
      });
      console.log({fetchedSheep});
      setSheep(fetchedSheep);
    } catch (err) {
      toast.error(err);
    }
  }

  

  const fetchOperationInfo = async () => {
    try {
      const fetchedInfo = await GetOperationInfo(id);
      console.log({fetchedInfo});
      const transformedPremises = transformPremises(fetchedInfo.premises);
      setPremises(transformedPremises);
      setOperation(fetchedInfo);
    } catch(err){
      toast.error(err);
    }
  }

  useEffect(() => {
    fetchOperationInfo();
    fetchSheepAndTransformForOperation();
  }, [])

  const handleApply = (e) => {
    e.preventDefault();
    const newFilter = {...tempFilter};
    setFilter(newFilter);
  }

  const handleClear = (e) => {
    e.preventDefault();
    const newFilter = {
      startDate: '',
      endDate: '',
      pid: '',
      gender: ''
    };

    setTempFilter(newFilter);
    setFilter(newFilter);
  }

  let filterFields = [
    {
      id: 'startDate',
      name: 'Start Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'startDate');
      },
      onClick: (e) => {
        e.preventDefault();
        updateTempFilter('', 'startDate');
      },
    },
    {
      id: 'endDate',
      name: 'End Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'endDate');
      },
      onClick: (e) => {
        updateTempFilter('', 'endDate');
      }
    },
    {
      id: 'pid',
      name: 'Premise',
      dropdown: true,
      options: premises,
      onChange: (e) => {
        updateTempFilter(e.target.value, 'pid');
      }
    },
    {
      id: 'gender',
      name: 'Gender',
      dropdown: true,
      options: [{
          value: 'male',
          name: 'Male'
        },
        {
          value: 'female',
          name: 'Female'
        }],
      onChange: (e) => {
        updateTempFilter(e.target.value, 'gender');
      }
    }
  ]

  const columns = [
    {
      id: 'pid',
      name: 'Premise ID'
    },
    {
      id: 'isoNumber',
      name: 'Isonumber'
    },
    {
      id: 'localMgmtNumber',
      name: 'Local Management Number'
    },
    {
      id: 'gender',
      name: 'Gender'
    },
    {
      id: 'createdAt',
      name: 'Created At'
    },
    {
      id: 'actions',
      name: 'Actions'
    }
  ]

  const updateTempFilter = (updatedValue, filterID) => {
    const newFilter = {...tempFilter};
    newFilter[filterID] = updatedValue;
    if(filterID == 'startDate'){
      if(tempFilter.endDate == ''){
        newFilter.endDate = updatedValue;
      } else {
        const startDate = new Date(updatedValue);
        const endDate = new Date(tempFilter.endDate)
        if(startDate > endDate) newFilter.endDate = updatedValue;
      }

      if(updatedValue == '') newFilter.endDate = '';
    }

    if(filterID == 'endDate'){
      if(tempFilter.startDate == ''){ 
        newFilter.startDate = updatedValue;
      } else {
        const startDate = new Date(tempFilter.startDate);
        const endDate = new Date(updatedValue);
        if(endDate < startDate) newFilter.startDate = updatedValue;
      }

      if(updatedValue == '') newFilter.startDate = '';
    }
    setTempFilter(newFilter);
  }

  const filteredSheep = sheep.filter(s => {
    let filterResult = true;
    const filterKeys = Object.keys(filter);
    filterKeys.forEach(key => {
      if(filter[key] == '') return;
      if(key.includes('Date')) return;
      if(s[key] != filter[key]) filterResult = false;
    });
    const creationDate = new Date(s['createdAt']);
    const startDate = new Date(filter.startDate);
    const endDate = new Date(filter.endDate);
    if(creationDate < startDate) return false;
    if(creationDate > endDate) return false;

    return filterResult;
  });

  return <>
    <PageCard title={(operation) ? `View Sheep for Operation ${operation.businessName}` : 'Loading info...'} back>
      {/* Filter Section */}
      <SimpleCard
        title="Filters"
        description='Use filters to find a subset of sheep'
      >
        <FilterSection
          data={filterFields}
          onApply={handleApply}
          onClear={handleClear}
          values={tempFilter}
        />
      </SimpleCard>

      {/* Table Section */}
      <SimpleCard
        title="Sheep"
        
      >
        <StripedTable
          columns={columns}
          rows={filteredSheep}
          ShowFilters
          ShowCount
          max={100}
        />
      </SimpleCard>

    </PageCard>
  </>
}

export default SheepOperationView;