// SearchBar.js
import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const StyledInput = styled.input`
  width: 100%;
  border-radius: 5px;
  font-size: 1rem;
  border-style: solid;
  padding: .5rem;
`

const SearchBar = ({value, onChange, placeholder}) => {
  return <>
    <StyledInput 
      value={value}
      type="search"
      placeholder={placeholder}
      onChange={onChange}
    />
  </>
};

SearchBar.propTypes = {
  value: propTypes.any,
  onChange: propTypes.any,
  placeholder: propTypes.any
}

export default SearchBar;