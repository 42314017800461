// RoleAddView
import React, {useState, useEffect} from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { 
    FormInputWrapper,
    FormContainer, 
    FormInputLabel, 
    FormSelectInput,
    FormTextList,
    Button
} from '../components/CommonComponents';
import FormCard from '../components/FormCard';

const axios = require('axios');

const getRoleTypes = async () => {
    return await axios.get('/api/roleTypes');
}

const RoleAddView = () => {
    
    const params = useParams();
    const username = params.username;
    const [form, setForm] = useState({
        username,
        roleType: ''
    });
    const [roleTypes, setRoleTypes] = useState([]);
    const [redirect, setRedirect] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/roles', form);
            toast.success('Role added successfully!');
            setRedirect(`/roles/${username}`);
        } catch (err) {
            toast.error(`An error occurred: ${err}`);
        }

    }

    useEffect(() => {
        (async () => {
            const r = await getRoleTypes();
            console.log(r.data);
            setRoleTypes(r.data);
        })();
    }, [])

    if(redirect != '') return <Redirect to={redirect} push />;
    return <>
        {(username) ? <><FormCard title={`Add role for ${username}`} back>
            <FormContainer onSubmit={handleSubmit}>
                <FormInputWrapper>
                    <FormInputLabel htmlFor='input'>Role</FormInputLabel>
                    <FormTextList>
                        <FormSelectInput
                            name='roleType'
                            value={form['roleType']}
                            onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value})}>
                                <option hidden>Select one...</option>
                                {roleTypes.map(v => {
                                    return <option key={v.name} value={v.name}>{v.niceName}</option>
                                })}
                        </FormSelectInput>
                    </FormTextList>
                </FormInputWrapper>
                <Button>Submit</Button>
            </FormContainer>
        </FormCard></> : <>Loading...</>}
    </>
}

export default RoleAddView;