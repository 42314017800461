import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import PageCard from '../components/PageCard.js';

import styled from 'styled-components';
import { StyledTableCurved } from '../components/TableComponents.js';

const axios = require('axios');

export default function LogViewer() {
	const location = useLocation();
	const [data, setData] = useState({});
	const [load, setLoad] = useState(false);
	const [txn, setTxID] = useState('');

	useEffect(() => {
		setLoad(true);
		const { _id, __v, username, txID, ...rest } = location.state;
		setTxID(txID);
		console.log(rest);
		setData(rest);
		// TODO: Format according to the event type
		// TODO: If the request has an attachment, allow the user to download/view it
	}, []);

	return (
		<PageCard title='Log Viewer' back>
			{!load ? (
				<>Loading...</>
			) : (
				<div style={{ margin: '10px' }}>
					<StyledTableCurved>
						<thead>
							<tr>
								<th>Data</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							{Object.keys(data).map((key) => (
								<tr>
									<td>{key}</td>
									<td>{data[key]}</td>
								</tr>
							))}
							<tr>
								<td>Blockchain confirmation</td>
								<td>
									<a href={`https://ropsten.etherscan.io/tx/${txn}`} target='_blank'>
										View on Ropsten
									</a>
								</td>
							</tr>
						</tbody>
					</StyledTableCurved>
				</div>
			)}
		</PageCard>
	);
}
