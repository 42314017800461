import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import ContentEditable from 'react-contenteditable';
import { EditBox, EditKey, EditButton } from '../components/CommonComponents';
import { FormInputWrapper, FormInputLabel, FormTextInput, FormSelectInput, FormTextList } from '../components/CommonComponents';
import PageCard from '../components/PageCard.js';
import { agGreen, rowColorA, rowColorB } from '../components/Colors.js';
import { StyledTable } from '../components/TableComponents.js';
import LoadingCard from '../components/LoadingCard.js';
import Loader from 'react-loader-spinner';

const axios = require('axios');

export default function PremiseView() {
	const [premise, setPremise] = useState({
		pid: '',
		name: '',
		address: '',
		municipality: '',
		province: '',
		postalCode: '',
		description: '',
	});
	const [newPremise, setNewPremise] = useState({
		pid: '',
		name: '',
		address: '',
		municipality: '',
		province: '',
		postalCode: '',
		description: '',
	});
	const [pidLog, setPidLog] = useState({
		pid: '',
		alternateId : '',
		address: '',
		municipality: '',
		province: '',
		postalCode: '',
		txId: '',
		date: '',
		operationName: '',
		operationType: '',
	});
	const [operation, setOperation] = useState('');

	const [edit, setEdit] = useState(true);
	const [fieldErrors, setFieldErrors] = useState({});

	const [buttonDisable, setButtonDisable] = useState(false);
	const provinces = ['NL', 'PE', 'NS', 'NB', 'QC', 'ON', 'MB', 'SK', 'AB', 'BC', 'YT', 'NT', 'NU'];


	let params = useParams();

	useEffect(() => {
		console.log(window.innerWidth);
		getPremise();
		// getPidLog();
	}, []);

	// Getting premise data for specific premise
	async function getPremise() {
		await axios
			.get(`/api/premises/${params.id}`)
			.then(function (response) {
				if (response.data.pid === null) {
					response.data.pid = ' ';
				}
				console.log('the response is ', response);
				setPremise(response.data);
				setNewPremise(response.data);
				setOperation(response.data.operation.businessName);
				console.log(response.data);
				console.log(response.status);

				// console.log('the pid is ', premise.pid);
				// console.log('the premise is ', premise.name);
				var idParam = '';
				if(response.data.pid !== ' '){
					idParam = response.data.pid;
				}else{
					idParam = response.data.name;
				}
				console.log('the idParam is', idParam);
				axios
					.get(`/api/pidLog/${idParam}`)
					.then(function (response) {
						console.log('the pid log response is ', response);
						if(response.data === null){
							setPidLog({ // this will only occur if the user changes the PID which should not be allowed
								pid: 'No log value found',
								alternateId : 'No log value found',
								address: 'No log value found',
								municipality: 'No log value found',
								province: 'No log value found',
								postalCode: 'No log value found',
								txId: 'No log value found',
								date: 'No log value found',
								operationName: 'No log value found',
								operationType: 'No log value found',
							});
						}else{
							setPidLog(response.data);
						}
					})
					.catch(function (error) {
						console.log(error);
					});
				
			})
			.catch(function (error) {
				console.log(error);
				// setFieldErrors(error.response.data);
			});
	}

	const setEditFalse = () => {
		if (edit) {
			// turn on editing by setting disabled to false
			setEdit(false);
		}
		setNewPremise(premise); // new premise is set to current premise when edit is clicked
	};

	async function update(updatedPremise) {
		setButtonDisable(true);
		await axios.put(`/api/premises/${premise._id}`, updatedPremise).then(
			(res) => {
				console.log('Updated');
				console.log(updatedPremise);
				setFieldErrors({}); // turn off error messages
				setPremise(newPremise); // set the default premise to the updated premise
				if (!edit) {
					// turn off editing by setting disabled to true
					setEdit(true);
				}
			},
			(err) => {
				setButtonDisable(false);
				console.log(`Error during update: ${JSON.stringify(err.response.data.Error)}`);
				setFieldErrors(err.response.data);
			}
		);
		setButtonDisable(false);
	}

	// runs when save button is clicked
	const saveChanges = () => {
		setButtonDisable(true);

		const updatedPremise = {
			// put premise in separate object so fields such as id are removed
			pid: newPremise.pid,
			name: newPremise.name,
			address: newPremise.address,
			municipality: newPremise.municipality,
			province: newPremise.province,
			postalCode: newPremise.postalCode,
			description: newPremise.description,
		};

		if (updatedPremise.pid == ' ') {
			updatedPremise.pid = null;
		}

		console.log('the updated premise is', updatedPremise);
		update(updatedPremise);
	};

	// action when undo button is clicked
	const changeReverted = () => {
		setEdit(true); // disable editing
		setNewPremise(premise); // set newpremise back to old premise
		setFieldErrors({}); // do not show error message in non-editing state
	};

	const obj = [
		{
			title: 'PID:',
			newPremiseVal: newPremise.pid.toString(),
			premiseVal: premise.pid.toString(),
			id: 'pid',
		},
		{
			title: 'NICKNAME: ',
			newPremiseVal: newPremise.name,
			premiseVal: premise.name,
			id: 'name',
		},
		{
			title: 'ADDRESS: ',
			newPremiseVal: newPremise.address,
			premiseVal: premise.address,
			id: 'address',
		},
		{
			title: 'MUNICIPALITY: ',
			newPremiseVal: newPremise.municipality,
			premiseVal: premise.municipality,
			id: 'municipality',
		},
		{
			title: 'PROVINCE: ',
			newPremiseVal: newPremise.province,
			premiseVal: premise.province,
			id: 'province',
		},
		{
			title: 'POSTAL CODE: ',
			newPremiseVal: newPremise.postalCode,
			premiseVal: premise.postalCode,
			id: 'postalCode',
		},
		{
			title: 'DESCRIPTION: ',
			newPremiseVal: newPremise.description,
			premiseVal: premise.description,
			id: 'description',
		},
		{
			title: 'OPERATION: ',
			id: 'operation'
		}
	];

	const log = [
		{
			title: 'PID: ',
			id: 'pid',
			value: pidLog.pid,
		},
		{
			title: 'Alternate Id: ',
			id: 'alternateId',
			value: pidLog.alternateId,
		},
		{
			title: 'Address: ',
			id: 'address',
			value: pidLog.address,
		},
		{
			title: 'Municipality: ',
			id: 'municipality',
			value: pidLog.municipality,
		},
		{
			title: 'Province: ',
			id: 'province',
			value: pidLog.province,
		},
		{
			title: 'Postal Code: ',
			id: 'postalCode',
			value: pidLog.postalCode,
		},
		{
			title: 'Transaction Id: ',
			id: 'txId',
			value: pidLog.txId,
		},
		{
			title: 'Date: ',
			id: 'date',
			value: pidLog.date,
		},
		{
			title: 'Operation Name: ',
			id: 'operationName',
			value: pidLog.operationName,
		},
		{
			title: 'Operation Type: ',
			id: 'operationType',
			value: pidLog.operationType,
		},
	];

	function buttonContent() {
		// passes jsx of buttons to pagecard for better alignment
		return (
			<PageTitle>
				{edit ? <EditButton onClick={setEditFalse}>Edit</EditButton> : null}
				{!edit ? (
					<UndoButton disabled={buttonDisable} onClick={changeReverted}>
						Undo
					</UndoButton>
				) : null}
				{!edit ? (
					<SaveButton disabled={buttonDisable} onClick={saveChanges}>
						Save
					</SaveButton>
				) : null}
			</PageTitle>
		);
	}

	return (
		<>
			{!buttonDisable ? (
				<PageCard
					componentTitle={premise.name}
					buttonContent={buttonContent()}
					errors={fieldErrors}
					back
				>
					<EditInnerBox>
						<PremiseTable>
							<thead>
								<tr>
									<th>Parameters</th>
									<th>Values</th>
								</tr>
							</thead>
							<tbody>
								{obj.map((premise, index) => {
									if(premise.id === 'province'){
										return (
											<SpecialTr>
											<SpecialTd>
												<EditKey>{premise.title}</EditKey>
											</SpecialTd>
											<SpecialTd>
												<EditBox
													style={
														index % 2 === 0
															? { 'background-color': rowColorA }
															: { 'background-color': rowColorB }
													}
												>
												<FormTextList>
													<FormSelectInput  style={{"width": "200px"}}disabled={edit}
														name='province'
														value={premise.newPremiseVal}
														onChange={(e) => setNewPremise({ ...newPremise, [premise.id]: e.target.value })}>

														{provinces.map(value => {
															return <option key={value}>{value}</option>
														})}
													</FormSelectInput>
												</FormTextList>

												</EditBox>
											</SpecialTd>
										</SpecialTr>
									)}else if(premise.id === 'operation'){
										return (<SpecialTr>
											<SpecialTd>
												<EditKey>{premise.title}</EditKey>
											</SpecialTd>
											<SpecialTd>
												<EditBox
													style={
														index % 2 === 0
															? { 'background-color': rowColorA }
															: { 'background-color': rowColorB }
													}>
													<ContentEditable
														html={operation}
														data-column='item'
														disabled={true}
													/>
												</EditBox>
											</SpecialTd>
										</SpecialTr>
									)}else{
									return (<SpecialTr>
										<SpecialTd>
											<EditKey>{premise.title}</EditKey>
										</SpecialTd>
										<SpecialTd>
											<EditBox
												style={
													index % 2 === 0
														? { 'background-color': rowColorA }
														: { 'background-color': rowColorB }
												}
											>
												<ContentEditable
													html={premise.newPremiseVal}
													data-column='item'
													disabled={edit}
													onChange={(e) => {
														var str = e.target.value;
														if (premise.id === 'pid' && e.target.value === '<br>') {
															str = ' ';
														} else if(e.target.value === '<br>'){
															str = '';
														}else{
															str = str.replace(/&nbsp;/g, ' ');
														}

														setNewPremise({ ...newPremise, [premise.id]: str });
													}}
												/>
											</EditBox>
										</SpecialTd>
									</SpecialTr>
								)}})}
							</tbody>
						</PremiseTable>
					</EditInnerBox>
					<LogCard>
						<LogTitle>PID Log: </LogTitle>
						<LogTable>
						{log.map( (row, index) => {
							return (
								<tr>
									<td>
										<LogField>{row.title}</LogField>
									</td>
									<td>
										{row.id === 'txId' ? <a href={`https://ropsten.etherscan.io/tx/${row.value}`}>
										<LogData>{row.value.substring(0, 10) + '...'}</LogData></a> : <LogData>{row.value}</LogData>}
									</td>	
								</tr>
						)})}
						</LogTable>
					</LogCard>
				</PageCard>
			) : (
				<PageCard
					title='Processing your request ...'
					loader={<Loader type='Oval' color={agGreen} height={50} width={150} />}
				>
					<LoadingCard />
				</PageCard>
			)}
		</>
	);
}

const UndoButton = styled(EditButton)`
	background-color: #c11400;
`;
const SaveButton = styled(EditButton)`
	background-color: ${agGreen};
`;

const PageTitle = styled.div`
	margin-left: 10px;
	position: inherit;
	color: white;
	display: flex;
	align-items: center;
`;

const EditInnerBox = styled.div`
	top: 120px;
	// margin-left: 100px;
	margin-left: 0px;
`;

const PremiseTable = styled(StyledTable)`
	table-layout:fixed;
`;

const SpecialTd = styled.td`
	// word-wrap: break-word;
	// overflow-wrap: break-word;
	white-space: nowrap

	// background-color: red;
`;

const SpecialTr = styled.tr`
	word-wrap: break-word;
`;
const LogCard = styled.div`
	width: 100%;
	// background-color: grey;

`;

const LogTable = styled.table`
	background-color: grey;
	table-layout: fixed;
	color: white;
	border-collapse: collapse;
	th, td {
		padding-left: 10px;
		padding-right: 10px;
	  }
	th {
		padding-top: 10px;
`;

const LogTitle = styled.div`
	font-size: 30px;
	margin: 20px 0px 5px 0px;
	font-weight: bold;
`;
const LogField = styled(EditKey)`
	font-size: 15px;
`;
const LogData = styled.div`
	font-size: 15px;
`;

const PageCardEdited = styled(PageCard)`
	max-width: ${window.innerWidth};
`;