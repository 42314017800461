// PremiseIDList.js
import React, { useState, useEffect } from 'react';
import PageCard from '../components/PageCard.js';
import TableComponent from '../components/TableComponent.js';
import { getPIDs, deletePID } from '../utils/PremiseIDUtils.js';
import { Redirect } from 'react-router-dom';

const PremiseIDList = () => {
	const [pids, setPIDS] = useState([]);
	const [redirect, setRedirect] = useState('');
	const fetchPIDs = async () => {
		const result = await getPIDs();
		const transformedPIDs = result.map((pid) => ({
			premiseID: pid.premiseID,
			name: pid.operation.businessName,
		}));
		console.log(transformedPIDs);
		setPIDS(transformedPIDs);
	};
	useEffect(() => {
		if (pids.length === 0) {
			fetchPIDs();
		}
	});

	if (redirect !== '') {
		return <Redirect to={redirect} push />;
	}

	const tableHeadings = [
		{ name: 'Premise ID', id: 'premiseID' },
		{ name: 'Operation Name', id: 'name' },
	];

	// Why does it use `setRedirect` instead of Link?
	const viewPID = ({ premiseID }) => {
		setRedirect(`/pid/id/${premiseID}`);
	};
	const deletePIDFromView = async ({ premiseID }) => {
		await deletePID(premiseID);
		await fetchPIDs();
	};

	return (
		<>
			<PageCard title='Premise IDs' to='/pid/create' back>
				<TableComponent
					headings={tableHeadings}
					data={pids}
					view={{ cb: viewPID, title: 'View' }}
					edit={{ cb: viewPID, title: 'Edit' }}
					remove={{ cb: deletePIDFromView, title: 'Delete' }}
					actions
				/>
			</PageCard>
		</>
	);
};

export default PremiseIDList;
