import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { Button } from './CommonComponents';

// TODO: The simple throws the sentence off center.
// So two options:
// 1) Pick a different 4 letter word
// 2) Recenter the sentence upon word change
// Change is still TBD
const options = ['easy', 'simple', 'fast'];

export default function Landing() {
	return (
		<LandingContainer>
			<Text>
				Tracking sheep, made&nbsp;
				{options.map((option, i) => (
					<TextOptions key={i} delay={i}>
						{option}.
					</TextOptions>
				))}
			</Text>
			<ButtonGroup>
				<Link to='/register'>
					<Button>Join Now</Button>
				</Link>
				<Link to='/login'>
					<BorderedButton>Login</BorderedButton>
				</Link>
			</ButtonGroup>
		</LandingContainer>
	);
}

const BorderedButton = styled(Button)`
	background-color: transparent;
	border: 2px solid #4caf50;
	color: #17a737;
	font-weight: bold;
`;

const LandingContainer = styled.div`
	margin: auto;
`;

const ButtonGroup = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;

	Button {
		margin: 5px;
	}
`;

const Text = styled.div`
	font-size: 30px;
	text-align: center;
	margin-bottom: 20px;
`;

const DisappearKeyframe = keyframes`
	0%,${100 / options.length}% {
		opacity: 1;
	}
	${100 / options.length + 0.01}%,100% {
		opacity: 0;
	}
`;

const TextOptions = styled.span`
	font-size: inherit;
	position: absolute;
	color: #17a737;
	opacity: 0;

	animation: ${DisappearKeyframe} ${options.length}s infinite;
	animation-delay: ${(props) => props.delay}s;
`;
