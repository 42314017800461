// PremiseView.js
import React, { useState, useEffect } from 'react';
import PageCard from '../components/PageCard.js';
import { agGreen } from '../components/Colors';
import { getPremises } from '../utils/PremiseUtils.js';
import DescriptionCard from '../components/DescriptionCard.js';
import LoadingCard from '../components/LoadingCard.js';
import Loader from 'react-loader-spinner';
import TableComponent from '../components/TableComponent';
import TableComponentMini from '../components/TableComponentMini';
import { Redirect } from 'react-router-dom';
import { getUsername } from '../utils/TokenUtils.js';

const axios = require('axios');

const PremiseView = ({ token }) => {
	const [premises, setPremises] = useState([]);
	const [buttonDisable, setButtonDisable] = useState(false);
	const [load, setLoad] = useState(false);
	const [redirect, setRedirect] = useState('');
	const [windowSize, setWindowSize] = useState(0);

	useEffect(() => {
		let operations = [];
		axios.get(`/api/premises/user/${getUsername()}`).then(async (res) => {
			console.log({res});
			setPremises(res.data);
			setLoad(true);
		});
	}, []);

	if (window.innerWidth !== windowSize) {
		setWindowSize(window.innerWidth);
		console.log(windowSize);
	}

	if (redirect !== '') {
		return <Redirect push to={redirect} />;
	}

	async function deletePremises({ _id }) {
		setButtonDisable(true);
		await axios
			.delete(`/api/premises/${_id}`)
			.then(function (response) {
				console.log(response.data);
				console.log(response.status);
				setPremises(premises.filter((premise) => premise._id !== _id));
			})
			.catch(function (error) {
				console.log(error);
			});
		setButtonDisable(false);
	}

	const viewPremise = ({ _id }) => {
		// to={`${link + element._id}`}
		setRedirect(`premises/${_id}`);
	};

	const tableHeadings = [
		{ name: 'PID', id: 'pid' },
		{ name: 'Nickname', id: 'name' },
		{ name: 'Address', id: 'address' },
		{ name: 'Municipality', id: 'municipality' },
		{ name: 'Province', id: 'province' },
		{ name: 'Postal Code', id: 'postalCode' },
		{ name: 'Description', id: 'description' },
	];
	const premiseFields = [
		{ name: 'PID', id: 'pid' },
		{ name: 'Nickname', id: 'name' },
		{ name: 'Address', id: 'address' },
		{ name: 'Municipality', id: 'municipality' },
		{ name: 'Province', id: 'province' },
		{ name: 'Postal Code', id: 'postalCode' },
		{ name: 'Description', id: 'description' },
	];

	return (
		<>
			{!buttonDisable ? (
				<PageCard title='Premises' to='/premises/create' back>
					<DescriptionCard page='premise'></DescriptionCard>
					{!load ? (
						<>Loading...</>
					) : (
						<>
							{windowSize < 1300 ? (
								<TableComponentMini
									fields={premiseFields}
									list={premises}
									deleteCB={deletePremises}
									viewCB={viewPremise}
									editCB={viewPremise}
								/>
							) : (
								<TableComponent
									headings={tableHeadings}
									data={premises}
									view={{ cb: viewPremise, title: 'View' }}
									edit={{ cb: viewPremise, title: 'Edit' }}
									remove={{ cb: deletePremises, title: 'Delete' }}
									actions
								/>
							)}
						</>
					)}
				</PageCard>
			) : (
				<PageCard
					title='Processing your request ...'
					loader={<Loader type='Oval' color={agGreen} height={50} width={150} />}
				>
					<LoadingCard />
				</PageCard>
			)}
		</>
	);
};

export default PremiseView;
