import axios from 'axios';

const removeRole = async (username, roleType) => {
    const body = {
        username,
        roleType
    };
    console.log({body})
    try {
        await axios.delete('/api/roles', { data: body});
        return;
    } catch (err) {
       return `Error removing role: ${JSON.stringify(err)}`;
    }
}

export {
    removeRole,
}