import React from 'react';
import {
  FetchSlaughterReports
} from '../components/SheepReportComponents';
import SheepReportListView from '../components/SheepReportListView';

const SlaughterListView = () => {
  return <>
    <SheepReportListView
      title="Slaughter Reports"
      description="Slaughter reports must be created when animals are slaughtered"
      fetchReportsFunction={FetchSlaughterReports}
      detailEndpoint={'/reports/raw/'}
     />
  </>
}

export default SlaughterListView;