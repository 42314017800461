// OperationRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OperationsView from '../views/OperationsView';
import OperationCreate from '../views/OperationCreate';
import LearnMore from '../views/LearnMore';
import OperationUpdate from '../views/OperationUpdate';
import OperationView from '../views/OperationView';

const OperationRouter = () => (
  <Switch>
    <Route exact path='/operations' component={OperationsView} />
    <Route exact path='/operations/create' component={OperationCreate} />
    <Route exact path='/operations/learnMore' component={LearnMore} />
    <Route path='/operations/:id/update' component={OperationUpdate} />
    <Route exact path='/operations/:id' component={OperationView} />
  </Switch>
);

export default OperationRouter;
