// TagActivationListView.js
import React from 'react';
import { 
  FetchTagActivationReports, 
} from '../components/SheepReportComponents';
import SheepReportListView from '../components/SheepReportListView';

const TagActivationListView = () => {
  return <>
    <SheepReportListView
      title="Tag Activation Reports"
      description="Tag activation reports are automatically created when sheep are entered into the sytem either manually or via CSV upload."
      fetchReportsFunction={FetchTagActivationReports}
      detailEndpoint={'/reports/tagactivation/'}
    />
  </>
}


export default TagActivationListView;