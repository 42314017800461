// SheepsView.js
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PageCard from '../components/PageCard.js';
import DescriptionCard from '../components/DescriptionCard.js';
import { getUsername } from '../utils/TokenUtils.js';
import TableComponent from '../components/TableComponent';
import { toast } from 'react-toastify';

const axios = require('axios');

export default function SheepView() {
	const [sheep, setSheep] = useState([]);
	const [load, setLoad] = useState(false);
	const [redirect, setRedirect] = useState('');

	useEffect(() => {
		// First get all operations with that user
		const getSheep = async () =>{
			console.log('Here!')
			try {
				const request = await axios.get(`/api/sheep?username=${getUsername()}`);
				let fetchedSheep = request.data;
				// flatten
				fetchedSheep.forEach((f) => {
					if(f.tag.isoNumber) f.isoNumber = f.tag.isoNumber;
					if(f.tag.localMgmtNumber) f.localMgmtNumber = f.tag.localMgmtNumber;
					if(f.tag.tattooNumber) f.tattooNumber = f.tag.tattooNumber;
					if(f.tag.usScrapieId) f.usScrapieId = f.tag.usScrapieId;
				});

				setSheep(fetchedSheep);
				console.log("NO ERR!");
				console.log({fetchedSheep});
				setLoad(true);
			} catch (err){
				console.log({err});
			}
		};

		getSheep();	
	}, []);

	if (redirect !== '') {
		return <Redirect to={redirect} push />;
	}

	const tableHeadings = [
		{ name: 'CSIP #', id: 'isoNumber' },
		{ name: 'Local Mgmt #', id: 'localMgmtNumber' },
		{ name: 'Tattoo #', id: 'tattooNumber' },
		{ name: 'US Scrapie ID', id: 'usScrapieId' },
		{ name: 'Subgender', id: 'subgender' },
	];

	const viewSheep = ({ _id }) => {
		setRedirect(`/sheep/${_id}`);
	};

	const deleteSheep = ({ _id }) => {
		// TODO: Show confirmation dialog to the user
		axios.delete(`/api/sheep/${_id}`).then(
			(res) => {
				setSheep(sheep.filter((element) => element._id !== _id));
				toast.info('Sheep deleted');
			},
			(err) => {
				toast.error(`An error has occurred during deletion: ${err}`);
			}
		);
	};

	return (
		<PageCard title='Sheep' to='/sheep/create' back>
			<DescriptionCard page='sheep' plural='sheep' />
			{!load ? (
				<>Loading...</>
			) : (
				<TableComponent
					headings={tableHeadings}
					data={sheep}
					view={{ cb: viewSheep, title: 'View' }}
					edit={{ cb: viewSheep, title: 'Edit' }}
					remove={{ cb: deleteSheep, title: 'Delete' }}
					actions
				/>
			)}
		</PageCard>
	);
}
