// DateField.js
import React from 'react';
import propTypes from 'prop-types';
import {
	FormInputWrapper,
	FormInputLabel,
} from './CommonComponents.js';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateField = ({
  id,
  labelName,
  onChange,
  value
}) => <>
 <FormInputWrapper key={id}>
  <FormInputLabel htmlFor='datepicker'>
    {labelName}
  </FormInputLabel>
  <DatePicker
    selected={value}
    onChange={onChange}
  />
 </FormInputWrapper>
</>;

DateField.propTypes = {
  id: propTypes.string,
  labelName: propTypes.string,
  onChange: propTypes.string,
  value: propTypes.node
}

export default DateField;
