import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import FormCard from '../components/FormCard.js';
import sheeps from '../images/sheeps.png';
import { agGreen } from '../components/Colors.js';

export default function ImportCreateDashboard() {
  const [selected, setSelected] = useState(null);

  if (selected) {
    return <Redirect to={selected} push />;
  }

  return (
    <FormCard title='Add Imported Sheep' back>
      <OptionsContainer>
        <Option onClick={() => setSelected('/events/animalImport/import')}>
          <Img src={sheeps} />
          Add Imported Sheep Via a CSV
        </Option>
        <Option onClick={() => setSelected('/events/animalImport/create')}>
          <Img src={sheeps} />
          Add Imported Sheep Manually
        </Option>
      </OptionsContainer>
    </FormCard>
  );
}

const Img = styled.img.attrs({})`
  width: 100px;
  height: 100px;
  align-self: center;
`;

const OptionsContainer = styled.div`
  max-width: 500px;
  display: flex;
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 30px;
  color: ${agGreen};
  font-weight: bold;

  > img {
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
    > img {
      opacity: 1;
    }
  }
`;
