// CAProvinces.js

const CAProvinceHash = {
  "AB": "Alberta",
  "BC": "British Columbia",
  "MB": "Manitoba",
  "NB": "New Brunswick",
  "NL": "Newfoundland and Labrador",
  "NS": "Nova Scotia",
  "NT": "Northwest Territories",
  "NU": "Nunavut",
  "ON": "Ontario",
  "PE": "Prince Edward Island",
  "QC": "Québec",
  "SK": "Saskatchewan",
  "YT": "Yukon"
}

const getAllProvinceNames = () => Object.values(CAProvinceHash);
const getAllProvinceAbbrv = () => Object.keys(CAProvinceHash);
const getProvinceNameFromAbbrv = (abbrv) => CAProvinceHash[abbrv];

export {
	CAProvinceHash,
	getAllProvinceAbbrv,
	getAllProvinceNames,
	getProvinceNameFromAbbrv,
};
