import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import FormCard from '../components/FormCard.js';
import styled from 'styled-components';
import TableComponent from '../components/TableComponent';
import ErrorArea from '../components/ErrorArea';
import { Button } from '../components/CommonComponents';
import { parseSheep } from '../utils/CSVUtils';
import ImportForm from '../components/ImportForm.js';
import { sheepYellow } from '../components/Colors.js';
import { toast } from 'react-toastify';
import { getUsername } from '../utils/TokenUtils.js';

const axios = require('axios');

export default function ImportSheep({ file, headers }) {
	const initData = {
		exportingCountry: '',
		departureSite: '',
		firstDestinationPID: '',
		vehicleIdentifier: '',
		disabled: true,
	};

	const tableHeadings = [
		{ name: 'Date', id: 'date' },
		{ name: 'CSIP #', id: 'isonum' },
	];

	const [edit, setEdit] = useState(initData);
	const [importSheep, setImportSheep] = useState([]);
	const [selected, setSelected] = useState(0);
	const [errors, setErrors] = useState({});
	const [redirect, setRedirect] = useState('INITIAL');
	const [user, setUser] = useState([]);

	const provCan = ['NL', 'PE', 'NS', 'NB', 'QC', 'ON', 'MB', 'SK', 'AB', 'BC', 'YT', 'NT', 'NU'];

	useEffect(() => {
		setImportSheep([]);
		parseSheep(file, headers, ({ data }) => {
			setImportSheep(
				data.map((x, idx) => {
					return { id: idx, ...x };
				})
			);
			toast.success('Successfully loaded!');
		});
	}, [file]);

	//To get the _id of the current user logged in
	useEffect(() => {
		axios
			.get(`/api/users/${getUsername()}`)
			.then(function (response) {
				setUser(response.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	if (redirect === 'DONE') {
		return <Redirect to='/events/animalImport' push />;
	}

	const validateISO = () => {
		let isonum = +edit.isonum;
		if (Number.isNaN(isonum)) {
			return false;
		}
		isonum = edit.isonum.replace(/\s/g, '');
		// Canada ISO Validate
		isonum = parseInt(isonum, 10);
		if (
			(isonum >= 124000310000000 && isonum <= 124000319999999) ||
			(isonum >= 124000500000000 && isonum <= 124000549999999)
		) {
			return true;
		}
		// US ISO Validate
		return /^840\d{12}$/.test(isonum);
	};

	const validateFirstDestinationPID = () => {
		let firstDestinationPID = +edit.firstDestinationPID;

		if (firstDestinationPID.length != 9 || !provCan.includes(firstDestinationPID.substring(0, 2))) {
			return true;
		}
		return false;
	};

	//Do form error checking here
	const validateFields = () => {
		const err = {};
		if (edit.isonum && !validateISO()) {
			err.isonum = `${edit.isonum} is not a valid CSIP number!`;
		}
		if (edit.firstDestinationPID && !validateFirstDestinationPID()) {
			err.firstDestinationPID = `${edit.firstDestinationPID} is not a valid First Destination PID!`;
		}

		setErrors(err);
		return !Object.keys(err).length;
	};

	const viewSheep = (element) => {
		if (selected > 0.5) {
			setImportSheep(importSheep.map((sheep) => ({ ...sheep, checked: false })));
		}
		if (Object.keys(errors).length) setErrors({});
		setSelected(0.5);
		setEdit({ ...initData, ...element, disabled: false });
		console.log(edit);
	};

	const deleteSheep = (element) => {
		// TODO: Display 'Are you sure dialog?'
		setImportSheep(importSheep.filter((sheep) => sheep.isonum !== element.isonum));
	};

	const selectSheep = (element, checked) => {
		if (!edit.disabled) {
			setEdit(initData);
			setSelected(0);
		}
		if (element) {
			// Select one
			setSelected((selected) => (checked ? selected + 1 : selected - 1));
			setImportSheep(
				importSheep.map((sheep) => (sheep.id === element.id ? { ...sheep, checked } : sheep))
			);
		} else {
			// Select all
			setSelected(checked ? importSheep.length : 0);
			setImportSheep(importSheep.map((sheep) => ({ ...sheep, checked })));
		}
	};

	const save = () => {
		if (validateFields()) {
			setImportSheep(importSheep.map((element) => (element.id === edit.id ? edit : element)));
			setSelected(0);
			setEdit(initData);
		}
	};

	const saveMultiple = () => {
		if (edit.exportingCountry === '') {
			setErrors({ exportingCountry: 'Exporting Country is a required field' });
			return;
		}
		if (edit.firstDestinationPID === '') {
			setErrors({ firstDestinationPID: 'First Destination PID is a required field' });
			return;
		}
		if (!edit.dateOfArrival) {
			setErrors({ dateOfArrival: 'Date of Arrival is a required field' });
			return;
		}
		if (edit.vehicleIdentifier === '') {
			setErrors({ vehicleIdentifier: 'Vehicle Identifier is a required field' });
			return;
		}
		if (
			edit.firstDestinationPID.length != 9 ||
			!provCan.includes(edit.firstDestinationPID.substring(0, 2))
		) {
			setErrors({ firstDestinationPID: 'First Destination PID is invalid!' });
			return;
		}

		/*
		if (Object.keys(errors).length){
            return setErrors({});
        }
        */

		// Cannot apply unique identifiers to more than one sheep, so filter:
		const setObj = (({
			exportingCountry,
			departureSite,
			firstDestinationPID,
			dateOfArrival,
			vehicleIdentifier,
		}) => {
			const filtered = {};
			if (exportingCountry !== '') {
				filtered.exportingCountry = exportingCountry;
			}
			if (departureSite !== '') {
				filtered.departureSite = departureSite;
			}
			if (firstDestinationPID !== '') {
				filtered.firstDestinationPID = firstDestinationPID;
			}
			if (vehicleIdentifier !== '') {
				filtered.vehicleIdentifier = vehicleIdentifier;
			}
			if (dateOfArrival) {
				filtered.dateOfArrival = dateOfArrival;
			}

			return filtered;
		})(edit);

		setImportSheep(
			importSheep.map((element) =>
				element.checked ? { ...element, ...setObj, checked: false } : element
			)
		);
		setSelected(0);
		setEdit(initData);
	};

	const foo = (e) => {
		e.preventDefault();

		setRedirect('PROCESSING');
		// TODO: Make sure the sent object is as small as possible (ie. no extra fields in the object)
		// This is so that the request is sent faster, with less strain on networking resources
		axios.post(`/api/import/${user._id}/bulk`, importSheep).then(
			(res) => {
				toast.success('Sheep inserted!');
				setRedirect('DONE');
			},
			(error) => {
				if (error.response.data.error.errors) {
					let set_errors = {};
					const { errors } = error.response.data.error;
					for (const [field, val] of Object.entries(errors)) {
						set_errors[field] = val.properties.message;
						if (field === 'password')
							set_errors.cPassword = 'Password confirmation is a required field!';
					}
					setErrors(set_errors);
				}
				toast.error(`An error has occurred: ${error}`, { autoClose: false });
				setRedirect('INITIAL');
			}
		);
	};

	return (
		<FormCard title='Add Imported Sheep' back>
			<ImportContainer>
				<ImportSheep1>
					<ImportTable>
						<TableComponent
							headings={tableHeadings}
							data={importSheep}
							checkCB={selectSheep}
							view={{ cb: viewSheep, title: 'View' }}
							remove={{ cb: deleteSheep, title: 'Delete' }}
							actions
						/>
					</ImportTable>
					<ImportForm1>
						<ImportForm errors={errors} setData={setEdit} sentData={edit} />
						<ErrorContainer>
							<ErrorArea formErrors={errors} />
						</ErrorContainer>
						<Button onClick={() => (selected > 0.5 ? saveMultiple() : save())} disabled={!selected}>
							Apply {selected > 1 && <span>to {selected}</span>}
						</Button>
					</ImportForm1>
				</ImportSheep1>
				<ImportButton onClick={foo} disabled={redirect !== 'INITIAL'}>
					Import {importSheep.length} sheep!
				</ImportButton>
			</ImportContainer>
		</FormCard>
	);
}

const ErrorContainer = styled.div`
	margin-top: -20px;
	padding: 0 30px;
`;

const ImportContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 0 0;
`;

const ImportSheep1 = styled.div`
	width: fit-content;
	display: flex;
	padding: 0 20px 20px;
`;

const ImportForm1 = styled.div`
	display: flex;
	flex-direction: column;
`;

// TODO: Make the height dynamic
const ImportTable = styled.div`
	overflow-y: auto;
	max-height: 400px;
	width: fit-content;
	margin: auto;

	&::-webkit-scrollbar {
		width: 15px;
		&-track {
			box-shadow: inset 0 0 5px grey;
			border-radius: 10px;
		}
		&-thumb {
			background: ${sheepYellow};
			background: #e2e672;
			border-radius: 10px;
			&:hover {
				background: #e2e672;
				background: ${sheepYellow};
			}
		}
	}
`;

const ImportButton = styled(Button)`
	align-self: center;
`;
