import Papa from 'papaparse';
const moment = require('moment');

// TODO: Could probably use the same parseSheep function, just conditionally set the preview & transform flags

// These configurations are custom picked for parsing the sheep CSV's. An equiv one can be made for other files
export const parseHeaders = (path, completeCB) => {
	Papa.parse(path, {
		download: true,
		header: true, // To allow retrieval by field name
		skipEmptyLines: true,
		complete: completeCB,
		preview: 1,
		error: (error) => {
			// This is an error to do with FileReader and not papaparse
			console.log(`An error has occurred during parsing: ${error}`);
		},
	});
};

export const parseSheep = (path, headers, completeCB) => {
	// Set ewename and ramname
	let eweName = '';
	let ramName = '';
	if(headers.gender) {
		eweName = headers.eweName;	
		ramName = headers.ramName;
	}
	Papa.parse(path, {
		download: true,
		header: true, // To allow retrieval by field name
		// worker: true, // TODO: Fix... So that the page stays reactive and papaparser works on its own thread
		skipEmptyLines: true,
		transformHeader: (header) => {
			const val = Object.keys(headers).find((key) => headers[key] === header);
			return val ? val : header;
		},
		transform: (value, header) => {
			// Clean any fields that require it
			if (header === 'isonum') {
				// In order to clean: Remove anything except numbers. Then from the left, take 15 numbers
				return value.replace(/[^\d]/g, '').substring(0, 15);
			}
			if (header === 'gender') {
				if(value === ramName) return 'Male';
				if(value === eweName) return 'Female';
				return '';
			}
			if (header === 'date') {
				const parsed = moment(value, headers.dateFormat);
				if (!parsed.isValid()) {
					// TODO: Redirect user, since format is wrong...
				}
				return parsed.format('LL');
			}
			return value;
		},
		complete: completeCB,
		error: (error) => {
			// This is an error to do with FileReader and not papaparse
			console.log(`An error has occurred during parsing: ${error}`);
		},
	});
};
