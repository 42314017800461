// DetailTable.js
import React from 'react'
import { StyledTable } from './TableComponents.js';
import { EditButton } from './CommonComponents.js';
import styled from 'styled-components';
import propTypes from 'prop-types';

const ButtonsDiv = styled.div`
	display: flex;
	justify-content: space-between;
`;

const DetailTable = ({data, fields, buttons}) => {
	return <>
		<StyledTable style={{marginTop: '10px', tableLayout: 'fixed'}} >
			<thead>
				<tr>
					<th>Parameters</th>
					<th>Values</th>
				</tr>
			</thead>
			<tbody>
				{fields.map((field) => <>
					<tr>
						<td>{field.name}</td>
						<td>{(data) ? (data[field.id]) ? data[field.id].toString() : '' : ''}</td>
					</tr>
				</>)}
				<tr>
					<td colSpan='2'>
						<ButtonsDiv>
							{(buttons && buttons.length > 0) ? buttons.map((button) => <>
								<EditButton bColor={button.color} onClick={button.onClick}>{button.name}</EditButton>
							</>) : <></>}
						</ButtonsDiv>
					</td>
				</tr>
			</tbody>
		</StyledTable>
	</>
}

DetailTable.propTypes = {
  data: propTypes.node,
  fields: propTypes.node,
  buttons: propTypes.node
};


export default DetailTable;
