import React from 'react';
import { agGreen } from './Colors.js';
import { EditButton as Button } from '../components/CommonComponents';
import styled from 'styled-components';

const EventsDescriptionCard = () => (
	<DescriptionBox>
		<DescriptionTitle>Welcome to Events</DescriptionTitle>
		<DescriptionContent>
			You can create all of your events within this page!
			<br/>
			<br/>
			If you are unsure about which specific event to create, select your desire under the &apos;What you like to do&apos; table. You will
            be guided to your event by answering a series of questions.
			<br />
			<br />
            If you already know the specifc event you would like to create, select the <EditButton>Create</EditButton> button on that event.
		</DescriptionContent>
	</DescriptionBox>
);

export default EventsDescriptionCard;


const EditButton = styled(Button)`
	background-color: ${agGreen};
`;

const DescriptionBox = styled.div`
	background-color: grey;
	margin: 10px 0;
	border-radius: 10px;
	padding: 5px 10px 10px;
`;

const DescriptionTitle = styled.div`
	color: white;
	font-weight: bold;

	text-decoration: bold;
	font-size: 30px;
	margin-bottom: 10px;
`;

const DescriptionContent = styled.div`
	color: white;
`;

