import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PageCardContainer, PageHeader } from './PageComponents.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { agGreen } from './Colors.js';
import { BackLink } from './CommonComponents.js';
import ErrorArea from './ErrorArea';
import propTypes from 'prop-types';

const CreateButton = ({ to }) => (
	<CreateSpan>
		<Link to={to}>
			<FontAwesomeIcon icon={faPlusCircle} size='2x' color={agGreen} />
		</Link>
	</CreateSpan>
);

CreateButton.propTypes = {
  to: propTypes.any
}

/*

Note: Component title is the title of a page such as an individual operations/premises page.

*/

const PageCard = ({ title, loader, componentTitle, to, back, buttonContent, children, errors, line, form}) => {
	const history = useHistory();

	return (
		<PageCardContainer form={form}>
			{back && <BackLink onClick={history.goBack}>&larr; Back</BackLink>}
			{componentTitle && buttonContent && (
				<ContentContainer>
					<PageHeader style={{ flex: '1' }}>{componentTitle}</PageHeader>
					{buttonContent}
				</ContentContainer>
			)}
			{errors && <ErrorArea formErrors={errors} />}
			{loader ? (
				<PageHeader style={{ display: 'flex' }}>
					{title}
					{loader}
				</PageHeader>
			) : (
				<PageHeader>{title}</PageHeader>
			)}
			{to && <CreateButton to={to} />}
			{(line) ? <hr /> : <></>}
			<div>{children}</div>
		</PageCardContainer>
	);
};

PageCard.propTypes = {
  to: propTypes.any,
  title: propTypes.any,
  loader: propTypes.any,
  componentTitle: propTypes.any,
  back: propTypes.any,
  buttonContent: propTypes.any,
  children: propTypes.any,
  errors: propTypes.any,
  line: propTypes.any
}

export default PageCard;

const CreateSpan = styled.span`
	margin-left: 10px;
`;

const ContentContainer = styled.div`
	display: flex;
`;
