// VehicleCreate.js
import React, { useState, useEffect } from 'react';
import FormCard from '../components/FormCard.js';
import {
	FormContainer,
	Button 
} from '../components/CommonComponents.js';
import Input from '../components/Input.js';
import { getAllStateAbbrv, getStateNameFromAbbrv } from '../utils/USAStates.js';
import { getAllProvinceAbbrv, getProvinceNameFromAbbrv } from '../utils/CAProvinces.js';
import Multiselect from '../components/Multiselect.js';
import {
	getVehicleById,
	updateVehicleById,
} from '../utils/VehicleUtils.js';
import { getOperations } from '../utils/OperationUtils.js';
import { Redirect } from 'react-router-dom';
import DropDown from '../components/DropDown.js';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getUsername } from '../utils/TokenUtils.js';

const VehicleUpdateView = () => {
	const [operations, setOperations] = useState([]);
	const [form, setForm] = useState();

	const { vehicleId } = useParams();

	const fetchOperations = async () => {
		const result = await axios.get(`/api/operations/user/${getUsername()}`);
		console.log({result});
		const reducedOperations = result.data.map(o => ({
			id: o._id,
			name: o.businessName,
		}));
		
		setOperations(reducedOperations);
	}

	const fetchVehicle = async () => {
		const result = await getVehicleById(vehicleId);
		const reducedVehicle = {
			provinces: result.provinces,
			states: result.states,
			licensePlateNum: result.licensePlateNum,
			operation: result.operation._id,
			name: result.name,
		};
		setForm(reducedVehicle);
	}

	useEffect(() => {
		if(!form) fetchVehicle();
		if(operations.length === 0) fetchOperations();
	}, []);

	const [redirect, setRedirect] = useState('');

	const submitVehicle = async (e) => {
		e.preventDefault();
		const res = await axios.put(`/api/vehicles/${vehicleId}`, form);
		console.log(res);

		setRedirect(`/vehicles/${vehicleId}`);
	}

	const handleProvinceClick = (province) => {
		if(form.provinces.filter(p => p === province).length > 0){
			const filteredProvinces = form.provinces.filter(p => p !== province);
			setForm({
				...form,
				provinces: filteredProvinces,
			});
		} else {
			let provincesCopy = form.provinces;
			provincesCopy.push(province);
			setForm({
				...form,
				provinces: provincesCopy
			});
		}
	}

	const handleStateClick = (state) => {
		if(form.states.filter(s => s === state).length > 0){
			const filteredStates = form.states.filter(s => s !== state);
			setForm({
				...form,
				states: filteredStates
			});
		} else {
			let statesCopy = form.states;
			statesCopy.push(state);
			setForm({
				...form,
				states: statesCopy
			});
		};
	}

	const USStateDropDownData = () => {
		const states = getAllStateAbbrv();
		return states.map(s => ({
			id: s,
			label: getStateNameFromAbbrv(s),
			checked: (form.states.filter(state => state === s).length > 0),
			onClick: () => handleStateClick(s)
		}));
	}

	const CAProvinceDropDownData = () => {
		const provinces = getAllProvinceAbbrv();
		return provinces.map(p => ({
			id: p,
			label: getProvinceNameFromAbbrv(p),
			checked: (form.provinces.filter(province => province === p).length > 0),
			onClick: () => handleProvinceClick(p)
		}));
	}

	const formInputs = [
		{
			name: 'Vehicle Name',
			hint: "John's Pickup Truck",
			id: 'name',
		},
		{
			name: 'License Plate Number',
			hint: 'ABCD 123',
			id: 'licensePlateNum'
		}
	]

	const operationDropDown = {
		name: 'Operation Name',
		id: 'operation'
	};

	if(redirect !== ''){
		return <Redirect to={redirect} />
	}

	return <>
		{(form) ? <FormCard title='Update a Vehicle' back>
			<FormContainer onSubmit={submitVehicle}>
				{formInputs.map(i => <>
					<Input
						id={i.id}
						placeholder={i.hint}
						labelName={i.name}
						onChange={(e) => setForm({
							...form,
							[e.target.name]: e.target.value
						})}
						value={form[i.id]}
						required
					/>
				</>)}
				<Multiselect name='vehicles' title='States' options={USStateDropDownData()} />
				<Multiselect name='provinces' title='Provinces' options={CAProvinceDropDownData()} />
				<DropDown
					id={operationDropDown.id}
					name={operationDropDown.name}
					onChange={(e) => {
						setForm({
							...form,
							[e.target.id]: e.target.value
						});
					}}
					value={form[operationDropDown.id]}
					options={
						operations.map(o => ({
							name: o.name,
							value: o.id,
						}))
					}
					required
				/>
				<Button>Submit</Button>
			</FormContainer>
		</FormCard> : <>Loading</> }
	</>
}

export default VehicleUpdateView;
