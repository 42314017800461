// PremiseRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PremisesView from '../views/PremisesView';
import PremiseCreate from '../views/PremiseCreate';
import PremiseView from '../views/PremiseView';

const PremiseRouter = () => <Switch>
  <Route exact path='/premises' component={PremisesView} />
  <Route exact path='/premises/create' component={PremiseCreate} />
  <Route exact path='/premises/:id' component={PremiseView} />
</Switch>

export default PremiseRouter;
