import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import FormCard from '../components/FormCard.js';
import styled from 'styled-components';
import { parseHeaders } from '../utils/CSVUtils';
import TableComponent from '../components/TableComponent';
import {
	FormContainer,
	FormInputWrapper,
	FormInputLabel,
	FormSelectInput,
	Text,
	Button,
	FormTextInput,
} from '../components/CommonComponents';
import SheepImport from './SheepImport';
import AnimalMove from './AnimalMove';
import { FormInputSubLabel } from '../components/FormComponents.js';

export default function SheepCsvImportView() {
	const { route } = useParams();

	const [preview, setPreview] = useState({ headings: [], data: []});
	const [headings, setHeadings] = useState([]);
	const [sample, setSample] = useState({});
	// name should be more descriptive
	const fields = [
		{ name: 'CSIP Number:', id: 'isoNumber' },
		{ name: 'Local Managment Number:', id: 'localMgmtNumber'},
	];
	const [headers, setHeaders] = useState({});
	const [file, setFile] = useState();
	const [next, setNext] = useState(false);
	console.log({headers})

	const fileUpload = (e) => {
		setFile(e.target.files[0]);
		parseHeaders(e.target.files[0], (results) => {
			// The construction of this object is NOT correct! Why is it an array. Should be an object e.g.
			// var prev = { columns: ..., data: ... }
			let sample = results.data[0];
			let headings = Object.keys(sample);
			//let data = Object.values(sample);
			if(headings.length > 20) 
				headings = headings.slice(0,20);
			// Transform into expected form for headings
			headings = headings.map((h) => ({ id: h, name: h}));
			console.log({headings});
			console.log({sample});
			setHeadings(headings);
			setSample(sample);
		}
		);
	};

	const preValidate = () => {
		// Check that either localmgmt or csip are set
		const csipSet = (headers.isoNumber && headers.isoNumber != '' || headers.localMgmtNumber && headers.localMgmtNumber != '') ? true : false;
		if(!csipSet) return false;
		console.log("CSIP okay");

		// Check that if date selected, format is selected
		const dateSet = (headers['birthdate'] && headers['birthdate'] != '') ? true : false;
		const dateFormatSet = (headers.dateFormat && headers.dateFormat != '') ? true : false;
		if(dateSet && !dateFormatSet) return false;
		console.log("date okay");

		// Check that ram and ewename are set if gender is set
		const genderSet = (headers.gender && headers.gender != '') ? true : false;
		const ramNameSet = (headers.ramName && headers.ramName != '') ? true : false;
		const eweNameSet = (headers.eweName && headers.eweName != '') ? true : false;
		console.log("gender okay");

		if(genderSet && (!ramNameSet || !eweNameSet)) return false;
		return true;
	}

	const removeEmptyVals = (obj) => {
		let result = {}, key;

		for(key in obj){
			if(obj[key] && obj[key] != '') result[key] = obj[key];
		}

		return result;
	}

	return (
		<>
			{next ? 
					<SheepImport file={file} headers={removeEmptyVals(headers)} />
				
			 : (
				<FormCard title='Import sheep' back>
					<FileContainer>
						<input type='file' accept='.csv' onChange={fileUpload} />
					</FileContainer>
					{headings.length > 0 && (
						<PreviewContainer>
							<Title>Preview:</Title>
							<TableComponent headings={headings} data={[sample]} />

							<Title>Headers:</Title>
							<p style={{color: 'white'}}>CSIP column must be specified</p>
							<HeaderForm>
								<FormInputWrapper>
									<FormInputLabel>CSIP Prefix</FormInputLabel>
									<FormInputSubLabel>Use this if you need to add a prefix to the numbers in the CSIP column</FormInputSubLabel>
									<FormTextInput 
											value={headers["csipPrefix"]}
											onChange={(e)=> 
												setHeaders({
													...headers,
													["csipPrefix"]: e.target.value,
												})
											}		
										/>
								</FormInputWrapper>
								{fields.map((element) => (
									<FormInputWrapper>
										<FormInputLabel>{element.name}</FormInputLabel>
										<FormSelectInput
											value={headers[element.id]}
											onChange={(e) =>
												setHeaders({
													...headers,
													[element.id]: e.target.value,
												})
											}
										>
											<option key={'select'} hidden>Select</option>
											<option key={'na'} value={''}>N/A</option>
											{
												headings.map((heading) => (
													<option key={heading.name} value={heading.name}>
														{heading.name}
													</option>
												))}
										</FormSelectInput>
									</FormInputWrapper>
								))}
								{/* date */}
								<FormInputWrapper>
									<FormInputLabel>Date of Birth</FormInputLabel>
									<FormSelectInput
										value={headers["birthdate"]}
										onChange={(e) => 
											setHeaders({
												...headers,
												["birthdate"]: e.target.value,
											})
										}
									>
										<option hidden>Select</option>
										<option key={'na'} value={''}>N/A</option>
										{
											headings.map((heading) => (
												<option key={heading.name} value={heading.name}>
													{heading.name}
												</option>
											))
										}
									</FormSelectInput>
								</FormInputWrapper>
								{(headers.birthdate && headers.birthdate != '') ? <FormInputWrapper>
									<FormInputLabel>Date Format:</FormInputLabel>
									<FormSelectInput
										value={headers.dateFormat}
										onChange={(e) =>
											setHeaders({
												...headers,
												dateFormat: e.target.value,
											})
										}
									>
										<option hidden>Select</option>
										<option>DD/MM/YYYY</option>
										<option>MM/DD/YYYY</option>
										<option>YYYY-MM-DD</option>
										<option>MM-DD-YYYY</option>
									</FormSelectInput>
								</FormInputWrapper> : <></>}
								{/* Gender */}
								<FormInputWrapper>
									<FormInputLabel>Gender:</FormInputLabel>
									<FormSelectInput
										value={headers["gender"]}
										onChange={(e) => 
											setHeaders({
												...headers,
												["gender"]: e.target.value,
											})
										}
									>
										<option hidden>Select</option>
										<option key={'na'} value={''}>N/A</option>
										{
											headings.map((heading) => (
												<option key={heading.name} value={heading.name}>
													{heading.name}
												</option>
											))
										}
									</FormSelectInput>
								</FormInputWrapper>
								{(headers["gender"] && headers["gender"] != '') ? <>
								  <FormInputWrapper>
										<FormInputLabel>What does the sheet call a ram?</FormInputLabel>
										<FormTextInput 
											value={headers["ramName"]}
											onChange={(e)=> 
												setHeaders({
													...headers,
													["ramName"]: e.target.value,
												})
											}		
										></FormTextInput>
								  </FormInputWrapper>
									<FormInputWrapper>
										<FormInputLabel>What does the sheet call an ewe?</FormInputLabel>
										<FormTextInput 
											value={headers["eweName"]}
											onChange={(e)=> 
												setHeaders({
													...headers,
													["eweName"]: e.target.value,
												})
											}		
										></FormTextInput>
								  </FormInputWrapper>
								</> : <></>}
								<SubmitButton
									type='submit'
									onClick={(e) => {
										e.preventDefault();
										setNext(true);
									}}
									disabled={!preValidate()}
								>
									Select Headers
								</SubmitButton>
							</HeaderForm>
						</PreviewContainer>
					)}
				</FormCard>
			)}
		</>
	);
}

const FileContainer = styled.div`
	width: 500px;
	display: flex;
	padding: 20px 20px 10px;
	color: white;
`;

const PreviewContainer = styled.div`
	margin: 0 20px 10px;
`;

const HeaderForm = styled(FormContainer)`
	height: fit-content;
	max-width: 500px;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;

	> div {
		flex-basis: 40%;
	}
`;

const SubmitButton = styled(Button)`
	margin: auto;
`;

const Title = styled(Text)`
	color: white;
	font-size: 25px;
	text-align: left;
	margin: 10px 0;
`;
