// SheepImportNew.js
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PageCard from '../components/PageCard';
import SimpleCard from '../components/SimpleCard';
import Papa from 'papaparse';
import { getUsername } from '../utils/TokenUtils';
import axios from 'axios';
import { Button } from '../components/CommonComponents';
import styled from 'styled-components';
import StripedTable from '../components/StripedTable';
import { v4 as uuidv4} from 'uuid';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const parseAsync = async file => {
  const parseFile = file => {
    return new Promise(resolve => {
      Papa.parse(file, {
        download: true,
        header: true,
        complete: results => {
          resolve(results.data);
        }
      });
    });
  }

  return await parseFile(file);
}
const SubmittingDiv = styled.div`
  text-align: center
`;
const SubmittingSpinner = () => {
  return <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin>

    </FontAwesomeIcon>
    &nbsp;Submitting...
  </SubmittingDiv>
}

const CheckBox = ({onChange, checked}) => {
  return <input type='checkbox' checked={checked} onChange={onChange} />;
}

const getCSVValueFromHeader = (row, header) => {
  return row[`${header}`];
}

const buildSheepForSubmit = (sheeps) => {
  let sheepsForSubmit = [];
  sheeps.forEach(s => {
    const newSheep = {
      tag: {
        localMgmtNumber: s.localMgmtNumber,
        isoNumber: s.isoNumber
      },
      gender: s.gender,
      subgender: s.subgender,
      birthdate: s.birthdate,
    };
    Object.keys(newSheep.tag).forEach(key => newSheep.tag[key] === undefined && delete newSheep.tag[key]);
    Object.keys(newSheep).forEach(key => newSheep[key] === undefined && delete newSheep[key]);
    sheepsForSubmit.push(newSheep);
  });
  console.log({sheepsForSubmit});
  return sheepsForSubmit;
}

const generateSheepFromFileAndHeaders = async (file, headers) => {
  try {
    const parsedData = await parseAsync(file);
    let sheeps = [];

    // transform into sheep
    parsedData.forEach(d => {
      let sheep = {};
      Object.keys(headers).forEach(key => {
        const value = getCSVValueFromHeader(d, headers[`${key}`]);
        if(value && value != '') sheep[`${key}`] = value;
      });
      if(Object.keys(sheep).length > 0) {
        // Generate a dummy id
        const prefix = headers.csipPrefix;
        if(prefix && prefix != '' && sheep.isoNumber && sheep.isoNumber != '') sheep.isoNumber = `${prefix}${sheep.isoNumber}`;
        sheep.dummyID = uuidv4();
        sheeps.push(sheep);
      }
    });

    return sheeps;
  } catch (err) {
    toast.error(`An error occurred generating sheep from file: ${err.message}`);
  }
}

const bulkSheepSubmit = async (submissionObject) => {
  try {
    toast.info("Submitting sheep to the backend. It may take up to a minute. You will be redirected when the sheep have been created. Please stay on this page.");

    const request = await axios.post(`/api/sheep/bulk`, submissionObject);
    toast.success('Sheep created successfully');
    return;
  } catch (err) {
    throw new Error(`An error occurred submitting the sheep: ${err.message}`);
  }
}

const fetchPremises = async() => {
  try {
    const username = getUsername();
    const request = await axios.get(`/api/premises/user/${username}`);
    const fetchedPremises = request.data;
    return fetchedPremises;
  } catch(err) {
    throw new Error(`An error occurred fetching premises: ${err.message}`, err);
  }
}

const PremiseSelectDiv = styled.div`
  background-color: #707070;
  padding: .5rem;
  padding-top: 1rem;
  text-align: center;
  border-radius: 5px;
`
const DropdownDiv = styled.div`
  margin-bottom: 1rem;
  font-size: 2rem;
`

const PremiseLabel = styled.label`
  margin-right: 1rem;
`

const PremiseSelect = styled.select`
  height: 3rem;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
`

const CenteredButtonDiv = styled.div`
  text-align: center;
  margin-top: 2rem;
`

const SheepImport = ({file, headers}) => {
  const [sheeps, setSheeps] = useState([]);
  const [selectedSheeps, setSelectedSheeps] = useState([]);
  const [premises, setPremises] = useState([]);
  const [selectedPremise, setSelectedPremise] = useState('');
  const [filter, setFilter] = useState([]);
  const [tempFilter, setTempFilter] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');
  
  const genAndSetSheep = async () => {
    const generatedSheeps = await generateSheepFromFileAndHeaders(file, headers);
    console.log({generatedSheeps});
    setSheeps(generatedSheeps);
  }

  const fetchAndSetPremises = async () => {
    const fetchedPremises = await fetchPremises();
    console.log({fetchedPremises});
    setPremises(fetchedPremises);
  }

  useEffect(() => {
    genAndSetSheep();
    fetchAndSetPremises();
  }, []);

  const baseColumns = [
    {
      id: 'isoNumber',
      name: 'Isonumber'
    },
    {
      id: 'localMgmtNumber',
      name: 'Local Management Number'
    },
    {
      id: 'gender',
      name: 'Gender'
    },
    {
      id: 'birthdate',
      name: 'DOB'
    }
  ];

  const columns = [
    {
      id: 'check',
      name: '' 
    },
    ...baseColumns
  ];

  const handleSubmit = async(e) => {
    try {
      e.preventDefault();
      setSubmitting(true);
      const sheepsToSubmit = buildSheepForSubmit(selectedSheeps);
      const submissionObject = {
        sheep: sheepsToSubmit,
        premise: selectedPremise
      };
      console.log({submissionObject});
      await bulkSheepSubmit(submissionObject);
      setRedirect('/sheep');
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  }

  const SelectAllSection = () => <CenteredButtonDiv>
    <Button onClick={(e) => {
      e.preventDefault();
      setSelectedSheeps(sheeps);
    }}>Select All</Button>
    {(selectedSheeps.length > 0) ? 
      <Button onClick={(e) => {
        e.preventDefault();
        setSelectedSheeps([]);
      }}>Clear All</Button>
    : <></>}
  </CenteredButtonDiv>;
  const sheepRowsWithCheck = sheeps.map(s => ({
    ...s,
    check: <CheckBox 
      checked={selectedSheeps.filter(selectedSheep => selectedSheep.dummyID == s.dummyID).length > 0}
      onChange={() => {
        const alreadySelected = selectedSheeps.filter(selectedSheep => selectedSheep.dummyID == s.dummyID).length > 0;
        console.log({alreadySelected});
        if(alreadySelected){
          const newSelectedSheeps = selectedSheeps.filter(sheep => sheep.dummyID != s.dummyID);
          setSelectedSheeps(newSelectedSheeps)
        } else {
          const newSelectedSheeps = [
            sheeps.filter(sheep => sheep.dummyID == s.dummyID)[0],
            ...selectedSheeps
          ];
          setSelectedSheeps(newSelectedSheeps);
        }
      }}
    />
  }));

  const selectedSheepColumns = [
    ...baseColumns,
    {
      id: 'remove',
      name: ''
    }
  ];

  const selectedSheepRowsWithRemove = selectedSheeps.map(s => ({
    ...s,
    remove: <button
      onClick={(e) => {
        e.preventDefault();
        const newSelectedSheep = selectedSheeps.filter(sheep => sheep.dummyID != s.dummyID);
        setSelectedSheeps(newSelectedSheep);
      }}
    >Remove</button>
  }))

  if(redirect != ''){
    return <Redirect to={redirect} />;
  }

  return <>
    <PageCard
      line
      back
      title='Sheep Bulk Import'
    >
      <SimpleCard
        title='Premise Selection'
      >
        <PremiseSelectDiv>
          <DropdownDiv>
            <PremiseLabel>Premise for Sheep:</PremiseLabel>
            <PremiseSelect
              value={selectedPremise}
              onChange={(e) => {
                console.log(e.target.value);
                setSelectedPremise(e.target.value);
              }}
            >
              <option
                value=''
                hidden
              >Select an option</option>
              {premises.map(p => <>
                <option
                  value={p._id}
                  key={p._id}
                >{`${p.name} - ${p.pid}`}</option>
              </>)}
            </PremiseSelect>
          </DropdownDiv>
        </PremiseSelectDiv>
      </SimpleCard>
      <SimpleCard
        title='Select Sheep'
      >
        <StripedTable
          columns={columns}
          rows={sheepRowsWithCheck}
          ShowCount
          max={10}
          paginate
        />
        <SelectAllSection />
      </SimpleCard>
      {(selectedSheeps.length > 0) ? 
        <SimpleCard
          title='Sheep selected to create'
        >
          <StripedTable
            columns={selectedSheepColumns}
            rows={selectedSheepRowsWithRemove}
            max={10}
            paginate
            ShowCount
          />
          {(selectedPremise) ? <CenteredButtonDiv>
            <Button onClick={handleSubmit}>Submit {`${selectedSheeps.length}`} Sheep</Button>
            {(submitting) ? <SubmittingSpinner /> :<></>}
           </CenteredButtonDiv>
          :<></>}
        </SimpleCard> : <></> }
    </PageCard>
  </>
}

export default SheepImport;