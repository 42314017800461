import React, { useState } from 'react';

import { FormInputLabel, FormTextInput } from '../components/CommonComponents.js';

const axios = require('axios');

export default function TestNotifications({ client }) {
	const [recipient, setRecipient] = useState('defaultAdmin');
	const [message, setMessage] = useState('Hey');

	const sendNotification = () => {
		client.send(
			JSON.stringify({
				request: 'SEND',
				recipient: recipient,
				data: { type: 'MESSAGE', data: message },
			})
		);
	};

	return (
		<form>
			<FormInputLabel>To:</FormInputLabel>
			<input value={recipient} onChange={(e) => setRecipient(e.target.value)} />
			<FormInputLabel>Message</FormInputLabel>
			<input value={message} onChange={(e) => setMessage(e.target.value)} />
			<button onClick={sendNotification}>Send request</button>
		</form>
	);
}
