// SheepDashboard.js
import React, { useEffect, useState } from 'react';
import PageCard from '../components/PageCard';
import SearchTable from '../components/SearchTable';
import SimpleCard from '../components/SimpleCard';
import { Button } from '../components/CommonComponents';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { getOperations } from '../utils/OperationUtils';
import { getUsername } from '../utils/TokenUtils';
import axios from 'axios';
import ViewEditDispose from '../components/ViewEditDispose';

// Functional Components

/** OPERATIONS **/
const fetchSheep = async () => {
  try {
    const username = getUsername();
    const request = await axios.get(`/api/sheep?username=${username}&premise=true`);
    const fetchedSheep = request.data;
    console.log({ fetchedSheep });
    return fetchedSheep;
  } catch (err) {
    console.log({ err })
    throw new Error(`An error occurred fetching sheep: ${err.message}`, err);
  }
}


const OperationsComponent = () => {
  const [operations, setOperations] = useState([]);
  // Fetch operations and transform data as necessary

  const fetchAndTransformSheepData = async () => {
    try {
      const fetchedSheep = await fetchSheep();
      let operationNames = [];
      let tempOperations = [];

      // Simplify
      const simplifiedSheep = fetchedSheep.map((s) => {
        const simpleSheep = {
          id: s._id,
          operationName: s.premise.operation.businessName,
          operationId: s.premise.operation._id,
          actions: <ViewEditDispose id={s._id} endpoint={'/sheep/'} />
        };

        if(s.tag.tattooNumber) simpleSheep.identifier= s.tag.tattooNumber
        if(s.tag.localMgmtNumber) simpleSheep.identifier = s.tag.localMgmtNumber;
        if(s.tag.isoNumber) simpleSheep.identifier = s.tag.isoNumber


        operationNames.push(simpleSheep.operationName);

        return simpleSheep;
      });

      // Group into operations
      let uniqueOperationNames = [];
      operationNames.forEach(o => {
        if (!uniqueOperationNames.includes(o)) {
          uniqueOperationNames.push(o)
        }
      });

      tempOperations = uniqueOperationNames.map(n => {
        const operationSheep = simplifiedSheep.filter(s => s.operationName == n);
        const operation = {
          name: n,
          id: operationSheep[0].operationId,
          sheep: operationSheep
        };

        return operation;
      });

      console.log({ tempOperations })

      console.log({ simplifiedSheep })

      setOperations(tempOperations);
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  }

  useEffect(() => {
    fetchAndTransformSheepData();
  }, []);

  return <>
    <OperationsDiv>
      <OperationsInnerContainer>
        {operations.map((o) => {
          // Columns
          const columns = [
            {
              id: 'identifier',
              name: 'Identifier (iso #, local management # or tattoo #)'
            },
            {
              id: 'actions',
              name: 'Actions'
            }
          ];

          const rows = o.sheep;

          return <>
            <SimpleCard title={o.name}>
              <SearchTable
                to={`/sheep/operation/${o.id}`}
                placeholder="Enter the identifier you wish to search for"
                filterField="identifier"
                columns={columns}
                rows={rows}
                count={10}
              />
            </SimpleCard>
          </>
        })}
      </OperationsInnerContainer>
    </OperationsDiv>
  </>
}

/** REPORTS */

const ReportSection = ({ title, description, to }) => {
  return <ReportSectionDiv>
    <ReportSectionTitle>{title}</ReportSectionTitle>

    <ReportDescription>{description}</ReportDescription>

    <ReportButtonDiv>
      <Link to={to}>
        <ReportButton>View {title} Reports</ReportButton>
      </Link>
    </ReportButtonDiv>
  </ReportSectionDiv>
}

const ReportsComponent = () => {
  return <>
    <SimpleCard
      title="Reports"
      description="View and search reports created by agroledger relating to sheep"
    >
      <ReportSection
        title="Tag Activation"
        description="Click here to view tag activation reports"
        to="/reports/tagactivation"
      />
      <ReportSection
        title="Carcass Disposal"
        description="Click here to view carcass disposal reports"
        to="/reports/carcassdisposal"
      />
      <ReportSection
        title="Slaughter"
        description="Click here to view slaughter reports"
        to="/reports/slaughter"
      />
      <ReportSection
        title="Raw Sheep"
        description="Click here to view raw sheep reports"
        to="/reports/rawsheep"
      />
    </SimpleCard>
  </>
}

/** ADD SHEEP */

const AddSheepComponent = () => {
  return <>
    <SimpleCard
      title="Add sheep"
      description="Add sheep one by one or upload a csv created from a wand"
    >
      <AddSheepButtonContainer>
        <Link to="/sheep/create/manual">
          <AddSheepButton>Add sheep manually</AddSheepButton>
        </Link>
      </AddSheepButtonContainer>
      <AddSheepButtonContainer>
        <Link to="/sheep/create/csv">
          <AddSheepButton>Add sheep from a csv</AddSheepButton>
        </Link>
      </AddSheepButtonContainer>
    </SimpleCard>
  </>
}

/** EXPORTED COMPONENT */

const SheepDashboardView = () => {

  return <PageCard
    title="Sheep Dashboard"
    line
    back
  >

    <SheepDashboardDiv>
      <OperationsComponent />
      <ActionsDiv>
        <AddSheepComponent />
        <ReportsComponent />
      </ActionsDiv>
    </SheepDashboardDiv>
  </PageCard>;
};

// Styled components


const AddSheepButton = styled.button`
  background-color: #17a737;
  border: none;
  border-radius: 10px;
  padding: 1rem;
  font-size: 1rem;
  color: white;
`

const AddSheepButtonContainer = styled.div`
  width: 50%;
  display: inline-block;
  text-align: center;
`



const ReportButton = styled(AddSheepButton)`
  padding: .5rem;
  margin-left: 1rem;
  color: white;
  border-style: solid;
  width: 80%;
`
const SheepDashboardDiv = styled.div`
  width: 100%;
`
const OperationsDiv = styled.div`
  max-width: 60%;
  width: 80%;
  float: left;
  display: inline-block;
`
const OperationsInnerContainer = styled.div`
  padding: .25rem;
`;

const ActionsDiv = styled.div`
  width: 40%;
  display: inline-block;
`

const ReportSectionDiv = styled.div`
  margin-bottom: 2rem;
`;

const ReportSectionTitle = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
`;

const ReportButtonDiv = styled.div`
  text-align: center;
`

const ReportDescription = styled.div`
  margin: 0.5rem;
`

export default SheepDashboardView;