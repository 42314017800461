// ReportRouter.js
import React from 'react';
import { Route, Switch} from 'react-router-dom';
import CarcassDisposalListView from '../views/CarcassDisposalListView';
import RawDetailView from '../views/RawDetailView';
import RawSheepReportsListView from '../views/RawSheepReportsListView';
import SlaughterListView from '../views/SlaughterListView';
import TagActivationDetailView from '../views/TagActivationDetailView';
import TagActivationListView from '../views/TagActivationListView';


const ReportRouter = () => <>
  <Switch>
    <Route
      exact
      path="/reports/tagactivation"
      component={TagActivationListView}
    />
    <Route
      exact
      path="/reports/tagactivation/:id"
      component={TagActivationDetailView}
    />
    <Route
      exact
      path="/reports/carcassdisposal"
      component={CarcassDisposalListView}
    />
    <Route
      exact
      path="/reports/slaughter"
      component={SlaughterListView}
    />
    <Route
      exact
      path="/reports/rawsheep"
      component={RawSheepReportsListView}
    />
    <Route
      exact
      path='/reports/raw/:id'
      component={RawDetailView}
    />
  </Switch>
</>;

export default ReportRouter;