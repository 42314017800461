import React from 'react';
import { FetchCarcassDisposalReports } from '../components/SheepReportComponents';
import SheepReportListView from '../components/SheepReportListView';

// Since these are all the same, I should make them one component
const CarcassDisposalListView = () => {
  return <>
    <SheepReportListView
      title="Carcass Disposal Reports"
      description="Carcass Disposal Reports must be created when carcasses are disposed of"
      fetchReportsFunction={FetchCarcassDisposalReports}
      detailEndpoint={'/reports/raw/'}
    />
  </>
}

export default CarcassDisposalListView;