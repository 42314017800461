import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import FormCard from '../components/FormCard.js';
import styled from 'styled-components';
import TableComponent from '../components/TableComponent';
import {
  FormContainer,
  FormSectionHeader,
  FormInputWrapper,
  FormInputLabel,
  FormTextInput,
  Button,
  FormSelectInput,
} from '../components/CommonComponents';
import { sheepYellow } from '../components/Colors.js';
import { toast } from 'react-toastify';
import { getUsername } from '../utils/TokenUtils.js';
import { getVehiclesByOperation } from '../utils/VehicleUtils.js';

const moment = require('moment');
const axios = require('axios');

export default function AnimalSighting() {
  const tableHeadings = [{ name: 'CSIP #', id: 'isonum' }];

  const [sheep, setSheep] = useState([]);
  const [selected, setSelected] = useState(0);
  const [redirect, setRedirect] = useState('INITIAL');
  const [data, setData] = useState({});
  const fieldErrors = {};

  useEffect(() => {
    const promises = [axios.get(`/api/operations/${getUsername()}/all`)];
    Promise.all(promises)
      .then((res) => {
        console.log(res);
        setData({ ...data, operations: res[0].data });
      })
      .catch((err) => {
        console.log(`Error during data retrieval: ${JSON.stringify(err)}`);
      });
  }, []);

  if (redirect === 'DONE') {
    return <Redirect to='/' push />;
  }

  const operationChange = async (operation) => {
    setSheep([]);
    // Get premises
    axios
      .get(`/api/premises/operation/${operation}`)
      .then(function (response) {
        setData((data) => ({ ...data, premises: response.data }));
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    // Get vehicles
    const result = await getVehiclesByOperation(operation);
    setData((data) => ({ ...data, vehicles: result }));
  };

  const premiseChange = (target) => {
    setData({
      ...data,
      pid: target.value,
    });

    // Get sheep
    axios
      .get(`/api/sheep/premises/${target.dataset.id}`)
      .then((res) => {
        setSheep(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const selectSheep = (element, checked) => {
    if (element) {
      // Select one
      setSheep(sheep.map((sheep) => (sheep._id === element._id ? { ...sheep, checked } : sheep)));
      setSelected((selected) => (checked ? selected + 1 : selected - 1));
    } else {
      console.log('All', checked);
      // Select all
      setSelected(checked ? sheep.length : 0);
      setSheep(sheep.map((sheep) => ({ ...sheep, checked })));
    }
  };

  const sightSheep = (e) => {
    e.preventDefault();

    setRedirect('PROCESSING');
    const selectedSheep = [];
    sheep.forEach((elem) => {
      if (elem.checked) {
        selectedSheep.push(elem.isonum);
      }
    });

    const query = {
      pid: data.pid,
      animals: selectedSheep,
      timestamp: moment(data.date + ' ' + data.time).toDate(),
    };

    axios.post(`/api/animalsighting`, query).then(
      async () => {
        toast.success('Animal sighting complete!');
        setRedirect('DONE');
      },
      (error) => {
        toast.error(`An error has occurred: ${error}.`, {
          autoClose: false,
        });
        setRedirect('INITIAL');
      }
    );
  };

  return (
    <FormCard title='Animal Sighting' back>
      <ImportContainer>
        <ImportSheep>
          <ImportTable>
            <TableComponent
              headings={tableHeadings}
              data={sheep}
              check={{ type: 'multiple', cb: selectSheep }}
              actions
              filters
            />
          </ImportTable>
        </ImportSheep>
        <ImportFormContainer>
          <FormSectionHeader>Sighting Information</FormSectionHeader>
          <FormInputWrapper>
            <FormInputLabel>Operation</FormInputLabel>
            <FormSelectInput
              invalid={fieldErrors.operation}
              value={data.operation}
              onChange={(e) => operationChange(e.target.value)}
            >
              <option hidden>Select an operation</option>
              {data.operations &&
                data.operations.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.businessName}
                  </option>
                ))}
            </FormSelectInput>
          </FormInputWrapper>
          <FormInputWrapper>
            <FormInputLabel>Premise</FormInputLabel>
            <FormSelectInput
              invalid={fieldErrors.pid}
              value={data.pid}
              onChange={(e) => premiseChange(e.target[e.target.selectedIndex])}
            >
              <option hidden>Select a premise</option>
              {data.premises &&
                data.premises.map((option) => (
                  <option key={option._id} value={option.pid} data-id={option._id}>
                    {option.name}
                  </option>
                ))}
            </FormSelectInput>
          </FormInputWrapper>
          <FormInputWrapper>
            <FormInputLabel>Sighting Date</FormInputLabel>
            <FormTextInput
              type='date'
              invalid={fieldErrors.date}
              value={data.date || ''}
              onChange={(e) => {
                setData({
                  ...data,
                  date: e.target.value,
                });
              }}
            />
          </FormInputWrapper>
          <FormInputWrapper>
            <FormInputLabel>Sighting Time</FormInputLabel>
            <FormTextInput
              type='time'
              invalid={fieldErrors.time}
              value={data.time || ''}
              onChange={(e) => {
                setData({
                  ...data,
                  time: e.target.value,
                });
              }}
            />
          </FormInputWrapper>
          {/* TODO: Find a better button name. But my english sucks */}
          <ButtonContainer>
            <ImportButton
              onClick={sightSheep}
              disabled={!data.date || !data.time || !selected || redirect !== 'INITIAL'}
            >
              Sight {selected} sheep
            </ImportButton>
          </ButtonContainer>
        </ImportFormContainer>
      </ImportContainer>
    </FormCard>
  );
}

const ImportFormContainer = styled(FormContainer)`
  flex-direction: row;
  flex-wrap: wrap;
  > button {
    margin: 0 auto 10px;
  }
`;

const ImportContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0 0;
`;

const ImportSheep = styled.div`
  width: fit-content;
  display: flex;
  padding: 0 20px 20px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

// TODO: Make the height dynamic
const ImportTable = styled.div`
  overflow-y: auto;
  max-height: 400px;
  min-width: 350px;
  margin: auto;
  &::-webkit-scrollbar {
    width: 15px;
    &-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
    &-thumb {
      background: ${sheepYellow};
      background: #e2e672;
      border-radius: 10px;
      &:hover {
        background: #e2e672;
        background: ${sheepYellow};
      }
    }
  }
`;

const ImportButton = styled(Button)`
  align-self: center;
`;
