// SheepDetailView.js
import React, { useEffect, useState } from 'react';
import PageCard from '../components/PageCard';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import SimpleCard from '../components/SimpleCard';

const fetchSheepInfo = async (id) => {
  try {
    const request = await axios.get(`/api/sheep/${id}?premise=true`);
    const sheepInfo = request.data;
    console.log({sheepInfo});

    return sheepInfo;
  } catch (err){
    console.log(err);
    throw new Error(`An error occurred fetching sheep info: ${err.message}`);
  }
}

const Info = ({name, value}) => {
  return <div>
    <span>{name}:&nbsp;</span><span>{value}</span>
  </div>;
}

const SheepDetailView = () => {
  const [sheepInfo, setSheepInfo] = useState();
  const params = useParams();
  const id = params.id;

  const fetchAndSetSheepInfo = async () => {
    try {
      const fetchedSheepInfo = await fetchSheepInfo(id);
      setSheepInfo(fetchedSheepInfo);
    } catch (err) {
      toast.error(err.message);
    }
  }
  useEffect(() =>  {
    fetchAndSetSheepInfo();
  }, [])
  
  return <>
    <PageCard title="Sheep Details" back>
      {(sheepInfo) ? <>
        <SimpleCard title="Animal Infomation" size="md">
          <Info name="Created At" value={sheepInfo.createdAt} />
          {(sheepInfo.gender) ? <><Info name="Gender" value={sheepInfo.gender} /></> :<></>}
          {(sheepInfo.subgender) ?
            <>
              <Info name="Sub Gender" value={sheepInfo.subgender} />
            </>:<></>
          }

        </SimpleCard>
        <SimpleCard title="Tag Information" size="md">
          <Info name="Created At" value={sheepInfo.tag.createdAt} />
          {(sheepInfo.tag.isoNumber) ? <>
            <Info name="Iso Number" value={sheepInfo.tag.isoNumber}/>
          </>:<></>}
          {(sheepInfo.tag.localMgmtNumber) ? <>
            <Info name="Local Management Number" value={sheepInfo.tag.localMgmtNumber} />

          </>:<></>}
          {(sheepInfo.tag.tattooNumber) ? <>
            <Info name="Tattoo Number" value={sheepInfo.tag.tattooNumber} />
          </>:<></>}
        </SimpleCard>
        {/* Operation Info */}
        <SimpleCard title="Operation Information" size="sm">
          <Info name="Business Name" value={sheepInfo.premise.operation.businessName} />
        </SimpleCard>
        <SimpleCard title="Premise Information" size="sm">          
          <Info name="Name"  value={sheepInfo.premise.name} />
          <Info name="Address" value={sheepInfo.premise.address} />
          <Info name="Municipality" value={sheepInfo.premise.municipality}/>
          <Info name="Postal Code" value={sheepInfo.premise.postalCode}/>
          <Info name="Province" value={sheepInfo.premise.province}/>
        </SimpleCard>
      </>: <>Loading</>}
    </PageCard>
  </>
};

export default SheepDetailView;