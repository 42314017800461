import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import {
	FormContainer,
	FormInputWrapper,
	FormInputLabel,
	FormTextInput,
	Button,
	Text,
	TextContainer,
	FormPasswordContainer,
	FormPassPeek,
} from './CommonComponents';
import { decodeToken } from '../utils/TokenUtils';
import FormCard from './FormCard.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import propTypes from 'prop-types';

const Login = ({ handleLogin, token }) => {
	const [user, setUser] = useState({});
	const [fieldErrors, setErrors] = useState({});
	const [peek, setPeek] = useState(false);

	if (token) {
		const decodedToken = decodeToken();
		if (decodedToken.reset) {
			return <Redirect to='/reset' />;
		}
		return <Redirect to='/' />;
	}

	const loginRes = (res) => {
    if(!res) {
      setErrors({ 'message':'Could not connect to the server' });
    }
    else if (res.status === 400) {
			setErrors({ [user.username ? 'password' : 'username']: res.data.message });
		}
	};

	return (
		<LoginContainer>
			<TextContainer style={{ marginBottom: '20px' }}>
				<Text>Welcome Back!</Text>
			</TextContainer>
			<FormCard title='Login' errors={fieldErrors}>
				<FormContainer>
					<FormInputWrapper>
						<FormInputLabel htmlFor='input'>Username:</FormInputLabel>
						<FormTextInput
							placeholder='Username'
							value={user.username}
							onChange={(e) => setUser({ ...user, username: e.target.value })}
							invalid={fieldErrors.username}
						/>
					</FormInputWrapper>
					<FormInputWrapper>
						<FormInputLabel htmlFor='input'>Password:</FormInputLabel>
						<FormPasswordContainer>
							<FormTextInput
								placeholder='Password'
								type={peek ? 'text' : 'password'}
								value={user.password}
								onChange={(e) => setUser({ ...user, password: e.target.value })}
								invalid={fieldErrors.password}
							/>
							<FormPassPeek onClick={() => setPeek(!peek)}>
								<FontAwesomeIcon icon={peek ? faEyeSlash : faEye} color='gray' />
							</FormPassPeek>
						</FormPasswordContainer>
					</FormInputWrapper>
					<Button type='submit' onClick={(e) => handleLogin(e, user).then((res) => loginRes(res))}>
						Login
					</Button>
					<ForgetLink href='/forget'>Forgot Password?</ForgetLink>
				</FormContainer>
			</FormCard>
		</LoginContainer>
	);
};

Login.propTypes = {
  handleLogin: propTypes.any,
  token: propTypes.any
}

export default Login;

const LoginContainer = styled.div`
	margin: auto;
`;

const ForgetLink = styled.a`
	margin: 0 auto 20px;
	color: orange;
`;
