// VehicleUtils.js
import axios from 'axios';

const vehicleEndpoint = '/api/vehicles';

const getVehicles = async () => {
	const res = await axios.get(vehicleEndpoint);
	return res.data;
};

const getVehiclesByOperation = async (operationID) => {
	const res = await axios.get(`${vehicleEndpoint}?operationID=${operationID}`);
	return res.data;
};

const createVehicle = async (vehicleToCreate) => {
	const res = await axios.post(vehicleEndpoint, vehicleToCreate);
	return res.data;
};

const getVehicleById = async (vehicleId) => {
	const res = await axios.get(`${vehicleEndpoint}?id=${vehicleId}`);
	return res.data;
};

const deleteVehicleById = async (vehicleId) => {
	const res = await axios.delete(`${vehicleEndpoint}/${vehicleId}`);
	return res.data;
};

const updateVehicleById = async (vehicleId, updatedVehicle) => {
	const res = await axios.put(`${vehicleEndpoint}/${vehicleId}`, updatedVehicle);
	return res.data;
};

export {
	getVehicles,
	getVehiclesByOperation,
	createVehicle,
	getVehicleById,
	deleteVehicleById,
	updateVehicleById,
};
