// ReportComponents.js
import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getUsername } from '../utils/TokenUtils';
import propTypes from 'prop-types';

const DescriptionArea = styled.div`
  font-size: 1rem;
  margin-bottom: 2rem;
`;

const FilterSectionContainer = styled.div`
  padding: 0.5rem;
  display: inline-block;
`;

const FilterFieldDiv = styled.div`
  display: inline-block;
  padding: 0.25rem;
`;

const DropDown = ({options, name, id, onChange, value}) => {
  if(!options) options = [];
  return <>
    <select name={name} id={id} onChange={onChange} value={value}>
      <option value={''}>Any</option>
      {options.map((o) => <option value={o.value} key={o.name}>{o.name}</option>)}
    </select>
  </>
}

DropDown.propTypes = {
  options: propTypes.any,
  name: propTypes.any,
  id: propTypes.any,
  onChange: propTypes.any,
  value: propTypes.any
};

const ClearButtonComponent = ({onClick}) => <ClearButton onClick={onClick}>
  Clear
</ClearButton>

ClearButtonComponent.propTypes = {
  onClick: propTypes.any
};

const ClearButton = styled.button`

`
const FilterField = ({field, value}) => {
  return <FilterFieldDiv>
    <label>{field.name} {(field.type == 'date') ? 
      <><ClearButtonComponent onClick={field.onClick}/></> : 
      <></>}
    </label>
    <br />
    {(field.dropdown) ? <>
      <DropDown 
        options={field.options}
        onChange={field.onChange}
        value={value}
      /></> : 
      <input 
        type={(field.type) ? field.type : 'text'}
        onChange={field.onChange}
        value={value}
      ></input>}
  </FilterFieldDiv>;
}

FilterField.propTypes = {
  field: propTypes.any,
  value: propTypes.any
};

const ApplyButton = styled.button`
`;

const FilterSection = ({data, onApply, values, onClear}) => {
  return <>
    <FilterSectionContainer>
      {data.map((f) => 
        <FilterField 
          field={f} 
          value={values[f.id]} 
          key={f.id}
        />
      )}
      <FilterFieldDiv>
        <ApplyButton onClick={onApply}>Apply</ApplyButton>
        <ClearButtonComponent onClick={onClear} />
      </FilterFieldDiv>
    </FilterSectionContainer>
  </>
};

FilterSection.propTypes = {
  data: propTypes.any,
  onApply: propTypes.any,
  values: propTypes.any,
  onClear: propTypes.any
}

const FetchTagActivationReports = async () => {
  try {
    const reports = await FetchSheepReportsByAction('CREATE');
    return reports;
  } catch (err) {
    throw new Error(`An error occurred fetching tag activation reports: ${err.message}`);
  }
}

const FetchCarcassDisposalReports = async () => {
  try {
    const reports = await FetchSheepReportsByAction("CARCASSDISPOSAL");
    return reports;
  } catch (err) {
    throw new Error(`An error occurred fetching carcass disposal reports: ${err.message}`);
  }
}

const FetchSlaughterReports = async () => {
  try {
    const reports = await FetchSheepReportsByAction("SLAUGHTER");
    return reports;
  } catch (err) {
    throw new Error(`An error occurred fetching slaughter reports: ${err.message}`);
  }
}

const FetchSheepReportsByAction = async (action) => {
  const username = getUsername();
  const request = await axios.get(`/api/sheepreports?username=${username}&action=${action}`);
  const fetchedReports = request.data;
  return fetchedReports;
}

const FetchAllSheepReports = async () => {
  try {
    const username = getUsername();
    const request = await axios.get(`/api/sheepreports?username=${username}`);
    const fetchedReports = request.data;
    return fetchedReports;
  } catch (err) {
    throw new Error(`An error occurred fetching sheep reports: ${err.message}`);
  }
}

const NASpan = styled.span`
  color: #818181;
`
const NAField = () => <NASpan>N/A</NASpan>;

const FetchPremises = async () => {
  try {
    const username = getUsername();
    const request = await axios.get(`/api/premises/user/${username}`);
    const fetchedPremises = request.data;
    return fetchedPremises;
  } catch (err) {
    throw new Error(`An error occurred fetching premises: ${err.message}`)
  }
}

const FetchReport = async (id) => {
  try {
    const request = await axios.get(`/api/sheepreports/${id}`);
    const fetchedReport = request.data;
    return fetchedReport;
  } catch (err){
    throw new Error(`An error occurred fetching sheep report: ${err.message}`);
  }
}

const PrettyPrintJson = ({data}) => {
  return <>
    <div>
      <pre>
        { JSON.stringify(data, null, 2)}
      </pre>
    </div>
  </>
}

const PrintDate = ({date}) => {
  const formatted = new Date(date).toISOString();

  return <>{formatted}</>
}

export {
  PrintDate,
  DescriptionArea,
  FilterSection,
  FetchTagActivationReports,
  FetchReport,
  FetchCarcassDisposalReports,
  FetchSlaughterReports,
  FetchAllSheepReports,
  NAField,
  FetchPremises,
  PrettyPrintJson
}