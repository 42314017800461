import { useLocation } from "react-router";
import React, { useEffect, useState} from 'react';
import PageCard from "../components/PageCard";
import SimpleCard from "../components/SimpleCard";
import { FormInputLabel, FormInputWrapper, FormPasswordContainer, FormSelectInput } from "../components/CommonComponents";
import { fromByteArray } from "ipaddr.js";
import { FormDropDown, FormDate } from "../components/FormComponents";

// SheepEditView.js
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const SheepEditView = () => {
  const [sheep, setSheep] = useState();
  const [breeds, setBreeds] = useState([]);
  const [premises, setPremises] = useState([]);
  const [form, setForm] = useState({
    premise: '',
    gender: '',
    breed: '',
    dob: '',

  })
  const query = useQuery();

  useEffect(() => {
    // Fetch the sheep by id
    const sheepID = query.get('id');
    console.log({sheepID});
  }, []);

  // Mutators

  const setGender = (newGender) => {
    if(form.gender == newGender) return;
    setForm({
      ...form,
      gender: newGender,
      subgender: ''
    });
  }

  const genderOptions = [
    {
      name: 'Male',
      value: 'male'
    },
    {
      name: 'Female',
      value: 'female'
    }
  ]

  const subGenderOptions = () => {
    if(form.gender == 'male'){
      return [
        {
          name: 'Ram',
          value: 'ram'
        },
        {
          name: 'Wether',
          value: 'wether'
        }
      ]
    }
    if(form.gender == 'female'){
      return [
        {
          name: 'Ewe',
          value: 'ewe'
        },
        {
          name: 'Ewelamb',
          value: 'ewelamb'
        }
      ]
    }

    return [];
  }


  return <>
    <PageCard
      line
      back
      form='true'
      title='Edit Sheep'
    >
      <SimpleCard
        title="Sheep Information"
      />
      {/* DropDowns */}
      <FormDropDown
        options={genderOptions}
        name='Gender'
        id='gender'
        onChange={(e) => setGender(e.target.value)}
        value={form.gender}
      />
      {(form.gender != '') ? <>
        <FormDropDown
          options={subGenderOptions()}
          name='SubGender'
          id='subgender'
          onChange={(e) => setForm({...form, subgender: e.target.value})}
          value={form.subgender}
        />
      </>:<></>}
      <FormDropDown
        option={breeds}
        name="Breed"
        id='breed'
        onChange={(e) => setForm({...form, breed: e.target.value})}
        value={form.breed}
      />
      <FormDate
        name='Date of Birth'
        id='birthdate'
        onChange={(e) => setForm({...form, birthdate: e.target.value})}
        value={form.birthdate}
      />
    </PageCard>
  </>;
}

export default SheepEditView;