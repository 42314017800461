// EventRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AnimalMove from '../views/AnimalMove';
import ImportCreateDashboard from '../views/ImportCreateDashboard';
import ImportCreate from '../views/ImportCreate';
import ImportView from '../views/ImportView';
import ImportUpdate from '../views/ImportUpdate';
import ImportsView from '../views/ImportsView';
import EventsView from '../views/EventsView';
import AnimalImportView from '../views/AnimalImportView';
import TagReplacementCreate from '../views/TagReplacementCreate';
import AnimalTagView from '../views/AnimalTagView';
import AnimalExport from '../views/AnimalExport';
import AnimalSighting from '../views/AnimalSighting';
import AnimalMedical from '../views/AnimalMedical';
import LogsViewer from '../views/LogsViewer';
import LogViewer from '../views/LogViewer';
import ImportEventHeaders from '../views/ImportEventHeaders';
import AnimalAttachments from '../views/AnimalAttachments';

const EventRouter = () => (
  <Switch>
    <Route exact path='/events/animalmovement/' component={AnimalMove} />
    <Route exact path='/events/animalImport/createDashboard' component={ImportCreateDashboard} />
    <Route exact path='/events/animalImport/create' component={ImportCreate} />
    <Route exact path='/events/animalImport/id/:id' component={ImportView} />
    <Route exact path='/events/animalImport/id/:id/update' component={ImportUpdate} />
    <Route exact path='/events/animalImport/import' component={ImportEventHeaders} />
    <Route exact path='/events/animalImport' component={ImportsView} />
    <Route exact path='/events' component={EventsView} />
    <Route exact path='/events/decision/animalimport' component={AnimalImportView} />
    <Route exact path='/events/tagreplacement' component={TagReplacementCreate} />
    <Route exact path='/events/decision/animaltag' component={AnimalTagView} />
    <Route exact path='/events/decision/animalexport/' component={AnimalExport} />
    <Route exact path='/events/decision/animalsighting/' component={AnimalSighting} />
    <Route exact path='/events/decision/animalmedical/' component={AnimalMedical} />
    <Route exact path='/events/view/' component={LogsViewer} />
    <Route exact path='/events/view/:txn' component={LogViewer} />
    <Route exact path='/events/documentupload/' component={AnimalAttachments} />
  </Switch>
);

export default EventRouter;
