// VehicleView.js
import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import PageCard from '../components/PageCard.js';
import { agGreen, deleteColor } from '../components/Colors.js';
import { 
	getVehicleById,
	deleteVehicleById,
} from '../utils/VehicleUtils.js';
import DetailTable from '../components/DetailTable.js';

export default function VehicleView() {
	const params = useParams();
	const vehicleId = params.vehicleId;
	const [vehicle, setVehicle] = useState();
	const [redirect, setRedirect] = useState('');

	const fetchAndSetVehicle = async () => {
		const result = await getVehicleById(vehicleId);
		const reducedVehicle = {
			name: result.name,
			licensePlateNum: result.licensePlateNum,
			provinces: result.provinces.join(', '),
			states: result.states.join(', '),
			operationId: result.operation._id,
			operationName: result.operation.businessName,
		};
		console.log(reducedVehicle);
		setVehicle(reducedVehicle);
	}

	useEffect(() => {
		if(!vehicle) fetchAndSetVehicle();
	});

	const vehicleFields = [
		{ name: 'Vehicle Name', id: 'name' },
		{ name: 'License Plate Number', id: 'licensePlateNum' },
		{ name: 'Operation', id: 'operationName' },
		{ name: 'States', id: 'states' },
		{ name: 'Provinces', id: 'provinces' },
	];

	const buttons = [
		{
			name: 'Edit',
			onClick: (e) => setRedirect(`/vehicles/${vehicleId}/update`),
			color: agGreen,
		},
		{
			name: 'Delete',
			onClick: async (e) => {
				await deleteVehicleById(vehicleId);
				await setRedirect('/vehicles');
			},
			color: deleteColor,
		}
	];

	if(redirect !== '') return <Redirect push to={redirect} />;	

	return (
		<>
			<PageCard title='View Details' back>
				<DetailTable data={vehicle} fields={vehicleFields} buttons={buttons} />
			</PageCard>
		</>
	);
}
