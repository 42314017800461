// OperationUtils.js
import axios from 'axios'

const operationEndpoint = '/api/operations';
const getOperationTypes = async () => {
	const res = await axios.get('/api/operationType');
	return res.data;
}

const createOperation = async(operation) => {
	console.log(operation);
	const res = await axios
		.post(operationEndpoint, operation);
	console.log(res);
	return res.data;
}

const updateOperation = async (operation,id) => {
	const res = await axios
		.put(`${operationEndpoint}/${id}`, operation);
	console.log(res);
	return res.data;
}

const getOperationById = async (id) => {
	const res = await axios
		.get(`${operationEndpoint}/${id}`);
	console.log(res);
	return res.data;
};

const getOperations = async () => {
	const res = await axios.get(operationEndpoint);
	return res.data;
}

const deleteOperation = async (id) => {
	const res = await axios.delete(`${operationEndpoint}/${id}`);
	return res.data;
}

export { 
	getOperationTypes, 
	createOperation,
	updateOperation,
	getOperationById,
	getOperations,
	deleteOperation
};
