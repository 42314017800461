import React, { useState, useEffect } from 'react';
import PageCard from '../components/PageCard.js';
import { agGreen, viewColor, deleteColor } from '../components/Colors.js';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EditButton as Button } from '../components/CommonComponents';
import { StyledTable } from '../components/TableComponents.js';
import { getUsers, deleteUser } from '../utils/UserUtils.js';

export default function UsersView() {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getUsers().then((res) => {
			setLoading(false);
			setUsers(res);
		});
	}, []);

	const deleteUsername = (username) => {
		deleteUser(username);
		const deleted = users.filter((user) => user.username !== username);
		setUsers(deleted);
	};

	const DisplayUser = ({ user }) => (
		<tr>
			<td>{user.username}</td>
			<td>{user.firstName}</td>
			<td>{user.lastName}</td>
			<td>
				<ButtonContainer>
					<Link to={`/users/${user.username}`}>
						<ViewButton>View</ViewButton>
					</Link>
					<Link to={`/users/${user.username}`}>
						<EditButton>Edit</EditButton>
					</Link>
					<DeleteButton onClick={(e) => deleteUsername(user.username)}>Delete</DeleteButton>
				</ButtonContainer>
			</td>
		</tr>
	);

	return (
		<PageCard title='All Users' back>
			<StyledTable>
				<thead>
					<tr>
						<th>Username</th>
						<th>First Name</th>
						<th>Last Name</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{loading ? <>Loading...</> : users.map((user) => <DisplayUser user={user} />)}
				</tbody>
			</StyledTable>
		</PageCard>
	);
}

const ButtonContainer = styled.div`
	display: flex;
`;

const ViewButton = styled(Button)`
	background-color: ${viewColor};
`;

const DeleteButton = styled(Button)`
	background-color: ${deleteColor};
`;

const EditButton = styled(Button)`
	background-color: ${agGreen};
`;
