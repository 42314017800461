// TagActivationDetailView.js
import React from 'react';
import { FetchReport } from '../components/SheepReportComponents';
import SheepReportDetailView from '../components/SheepReportDetailView';

const RawDetailView = () => {

  return <>
    <SheepReportDetailView
      title={'Raw Report'}
      fetchReportDetailFunction={FetchReport}
    />
  </>
}

export default RawDetailView;