import React, { useState, useEffect } from 'react';
import PageCard from '../components/PageCard.js';
import TableComponent from '../components/TableComponent';
import { getUsername } from '../utils/TokenUtils';
import { toast } from 'react-toastify';

const axios = require('axios');
const moment = require('moment');

export default function Notifications() {
	const [messages, setMessages] = useState([]);

	useEffect(() => {
		// Get user notifications
		axios.get(`/api/notifications/${getUsername()}`).then(
			(res) => {
				console.log(res.data);
				setMessages(
					res.data.map((notification) => {
						notification.date = moment(notification.date).format('dddd, MMMM Do YYYY, h:mm:ss a');
						notification.message = notification.data.data;
						return notification;
					})
				);
			},
			(err) => {
				console.log('Error during ticket retrieval', err);
			}
		);
	}, []);

	const tableHeadings = [
		{ name: 'Date', id: 'date' },
		{ name: 'Message', id: 'message' },
		{ name: 'Sender', id: 'sender' },
	];

	const viewNotification = () => {};
	const dismissNotification = ({ _id }) => {
		axios.delete(`/api/notifications/${getUsername()}/${_id}`).then(
			() => {
				setMessages(messages.filter((element) => element._id !== _id));
			},
			(err) => {
				toast.error(`An error has occurred during deletion: ${err}`);
			}
		);
	};

	return (
		<PageCard title='Notifications'>
			<TableComponent
				headings={tableHeadings}
				data={messages}
				view={{ cb: viewNotification, title: 'View' }}
				remove={{ cb: dismissNotification, title: 'Delete' }}
				actions
			/>
		</PageCard>
	);
}
