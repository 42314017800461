// TagActivationDetailView.js
import React from 'react';
import { FetchReport } from '../components/SheepReportComponents';
import SheepReportDetailView from '../components/SheepReportDetailView';

const TagActivationDetailView = () => {

  return <>
    <SheepReportDetailView
      title={'Tag Activation'}
      fetchReportDetailFunction={FetchReport}
    />
  </>
}

export default TagActivationDetailView;