// RoleDetailView.js
import React, {useState, useEffect} from 'react';
import { useParams, Link } from 'react-router-dom';
import DataList from '../components/DataList';
import PageCard from '../components/PageCard';
import { getUser } from '../utils/UserUtils';
import { removeRole }  from '../utils/RoleUtils';
import { toast } from 'react-toastify';

const roleColor = '#828282';

const RoleDetailView = () => {
  const params = useParams();
  const username = params.username;
  const [user, setUser] = useState({});

  const removeRoleHandler = async (role) => {
    console.log('clicked!')
    const removeErr = await removeRole(username, role);
    setUser(await getUser(username));
    if(removeErr){
      toast.error(removeErr);
    } else {
      toast.success(`'${role}' removed successfully!`);
    }
  }

  useEffect(() => {
    (async () => {
      let u = await getUser(username);
      console.log(u);
      setUser(u);
    })();
  }, [])

  const infoFields = [
    { name: 'First Name', value: user.firstName },
    { name: 'Last Name', value: user.lastName },
    { name: 'Email', value: user.email }
  ];

  const roleFields = (user.roles) ? user.roles.map(r => {
    const opsString = (r.operations) ? r.operations.join(', ') : '';
    const premisesString = (r.premises) ? r.premises.join(', ') : '';
    return {
    name: r.role,
    values: [
      { name: 'Operations', value: opsString },
      { name: 'Premises', value: premisesString }
    ]};
  }) : [];

  return <>
    {(user) ? <PageCard title={`${username} details`} back>
      <DataList title='User Info' fields={infoFields} />
      <h3>Roles</h3><Link to={`/roles/${username}/add`}>Add Roles</Link>
      {roleFields.map(r => <DataList title={r.name} fields={r.values} color={roleColor} remove onClick={async (e) => await removeRoleHandler(r.name)}/>)}

    </PageCard> 
    : <p> loading</p>
    }
  </>
}

export default RoleDetailView;