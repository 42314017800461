import React from 'react';
import { agGreen } from './Colors.js';
import { EditButton as Button } from '../components/CommonComponents';
import styled from 'styled-components';
import propTypes from 'prop-types';

const DescriptionCard = ({ plural, page }) => (
	<DescriptionBox>
		<DescriptionTitle>Welcome to {plural || `${page}s`}</DescriptionTitle>
		<DescriptionContent>
			You can view, edit and delete all of your {plural || `${page}s`} within this page!
			<br />
			<br />
			Each row in the table is an individual {page} with information about various fields. The
			buttons underneath the Action column represent the 3 actions: View, Edit and Delete.
			<br />
			<br />
			<ViewButton>View</ViewButton> : Click this button to view the specific {page}
			<br />
			<br />
			<EditButton>Edit</EditButton> : Click this button to update information about that specific{' '}
			{page}
			<br />
			<br />
			<DeleteButton>Delete</DeleteButton> : Click this button to delete the specific {page}
		</DescriptionContent>
	</DescriptionBox>
);

DescriptionCard.propTypes = {
  plural: propTypes.node,
  page: propTypes.node
};

export default DescriptionCard;

const DeleteButton = styled(Button)`
	background-color: #c11400;
`;

const ViewButton = styled(Button)`
	background-color: #4682b4;
`;

const EditButton = styled(Button)`
	background-color: ${agGreen};
`;

const DescriptionBox = styled.div`
	background-color: grey;
	margin: 10px 0;
	border-radius: 10px;
	padding: 5px 10px 10px;
`;

const DescriptionTitle = styled.div`
	color: white;
	font-weight: bold;

	text-decoration: bold;
	font-size: 30px;
	margin-bottom: 10px;
`;

const DescriptionContent = styled.div`
	color: white;
`;