import React from 'react';
import {
	FormContainer,
	FormInputLabel,
	FormTextInput,
} from '../components/CommonComponents.js';
import styled from 'styled-components';
import propTypes from 'prop-types';

export default function ImportForm({ errors, setData, sentData }) {
	const account = [
		{ name: 'Exporting Country:', hint: 'United States of America', id: 'exportingCountry' },
		{ name: 'Departure Site:', hint: 'New York', id: 'departureSite'},
		{ name: 'First Destination PID:', hint: 'ON1234567', id: 'firstDestinationPID' },
        { name: 'Date of Arrival:', hint: '2020-08-10', id: 'dateOfArrival', type: 'date' },
        { name: 'Vehicle Identifier:', hint: 'ABC123', id: 'vehicleIdentifier' },
        { name: 'Animal Identifier:', hint: '124000501041129', id: 'isonum', unique: true },
	];

	return (
		<>
				<ImportContainer>
					{account.map((element) => {
						return (
							<FormInputWrapper key={element.id}>
								<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
								<FormTextInput
                                    disabled={element.unique && sentData.disabled}
									placeholder={element.hint}
									id='input'
									type={element.type ? element.type : 'text'}
									valid={true}
                                    name={element.id}
                                    invalid={errors[element.id]}
									value={sentData[element.id]}
									onChange={(e) => setData({ ...sentData, [e.target.name]: e.target.value })} // react replaces the whole object so go to add ...form,
								></FormTextInput>
							</FormInputWrapper>
						);
					})}
				</ImportContainer>
		</>
	);
}

ImportForm.propTypes = {
  errors: propTypes.node,
  setData: propTypes.node,
  sentData: propTypes.node
}

// This should be the new default
const ImportContainer = styled(FormContainer)`
	height: fit-content;
	flex-direction: row;
	flex-wrap: wrap;

	> div {
		flex-basis: 40%;
	}
`;

const FormInputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 25px;
	margin-top: 0;
	width: 450px;
`;