// AuthUtils.js
import axios from 'axios';
import jwt_decode from 'jwt-decode';

export const getToken = () => {
  const token = localStorage.getItem('agroledgerToken');
  if (token === undefined) return null;
  return token;
};
export const getRefreshToken = () => {
  const refToken = localStorage.getItem('agroledgerRefreshToken');
  if (refToken === undefined) return null;
  return refToken;
};

// Could just use an interceptor?
export const axiosWithAuth = (token) =>
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token || getToken()}`,
    },
  });

// Pulls the current users roles from jwt
export const getRoles = () => {
  const token = decodeToken(getToken());
  if (!token) return;

  return token.roles;
};

// Gets the username from the jwt
export const getUsername = () => {
  const token = decodeToken(getToken());
  if (!token) return;
  return token.username;
};

// Get the entire decoded payload
export const decodeToken = (token = getToken()) => {
  let decoded = null;
  try {
    decoded = jwt_decode(token);
  } catch (err) {
    console.log(err);
  }

  return decoded;
};

// Logs into the application and gets the tokens
export const loginAndSetToken = (user) => {
  return axios.post('/api/users/login', user).then((res) => {
    const { token, refreshToken } = res.data.tokens;
    // Set the auth token
    localStorage.setItem('agroledgerToken', token);
    // Set the refresh token
    localStorage.setItem('agroledgerRefreshToken', refreshToken);
    return { res, token };
  });
};

export const logoutAndClearTokens = () => {
  // Clear the auth token
  localStorage.removeItem('agroledgerToken');
  // Clear the refresh token
  localStorage.removeItem('agroledgerRefreshToken');
};

// Try & refresh the user token, will clear tokens if they're expired
export const refreshTokens = () => {
  return axios.post('/api/users/refresh', { refreshToken: getRefreshToken() }).then(
    (res) => {
      // Refresh token was valid. Replace the tokens
      const { token, refreshToken } = res.data;
      localStorage.setItem('agroledgerToken', token);
      localStorage.setItem('agroledgerRefreshToken', refreshToken);
      return token;
    },
    (err) => {
      // Remove the invalid tokens (since both have expired) from local storage
      logoutAndClearTokens();
      return Promise.reject(err);
    }
  );
};
