// FormComponents.js
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Create a small label below a label
const FormInputSubLabel = styled.label`
  font-family: Inter-Regular;
  font-size: 12px;
  margin -10px 0 10px 0;
`

const FormInputDiv = styled.div`
  margin: 25px;
  margin-top: 0;
  font-size: 1.5rem;
`

const FormLabel = styled.label`
  margin-right: 0.5rem;
`
const FormInput = styled.input`
`

const FormSelect = styled.select`
  font-size: 1rem;
`

const FormDropDown = ({options, name, id, onChange, value}) => {
  if(!options) options = [];

  return <FormInputDiv>
    <FormLabel>{name}</FormLabel>
    <FormSelect name={name} id={id} onChange={onChange} value={value}>
      <option hidden selected>Select an option</option>
      {options.map((o) => <>
        <option value={o.value} key={o.name}>{o.name}</option>
      </>)}
    </FormSelect>
  </FormInputDiv>
}

const FormDate = ({name, onChange, value}) => {

  return <>
    <label>
      {name}
    </label>
    <input
      type='date'
      onChange={onChange}
      value={value}
    />
  </>;
}

FormDropDown.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any
}

FormDate.propTypes ={
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any
}

export {
  FormInputSubLabel,
  FormDropDown,
  FormDate
}