// PremiseUtils.js
const axios = require('axios');

export const getPremises = () => {
  console.log('Fetching premises...');
  return axios.get('/api/premises').then((res) => {
    return res.data;
  });
}

export const getPremisesForUser = async(username) => {
  try {
    const request = await axios.get(`/api/premises/user/${username}`);
    const fetchedPremises = request.data;
    return fetchedPremises;
  } catch (err) {
    throw new Error(`An error occurred fetching premises: ${err.message}`);
  }
}

export async function deletePremises(name){
  await axios.delete(`/api/premises/${name}`)
  .then(function(response){
      console.log(response.data);
      console.log(response.status);
  }).catch(function(error){
      console.log(error);
  })
  window.location.reload(true);
}
