import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PageCard from '../components/PageCard.js';
import axios from 'axios';
import DescriptionCard from '../components/DescriptionCard.js';
import TableComponent from '../components/TableComponent';
import { getUsername } from '../utils/TokenUtils.js';
import TableComponentMini from '../components/TableComponentMini';
const moment = require('moment');

const ImportsView = () => {
	const [imports, setImports] = useState([]);
	const [load, setLoad] = useState(false);
	const [redirect, setRedirect] = useState('');
	const [windowSize, setWindowSize] = useState(0);

	useEffect(() => {
		axios
			.get(`/api/import/${getUsername()}/all`)
			.then(function (response) {
				setImports(response.data);
				console.log(response.data);
				setLoad(true);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	if (window.innerWidth !== windowSize) {
		setWindowSize(window.innerWidth);
		console.log(windowSize);
	}

	if (redirect !== '') {
		return <Redirect push to={redirect} />;
	}

	const deleteImport = ({ _id }) => {
		axios
			.delete(`/api/import/${_id}`)
			.then(function (response) {
				console.log(response.data);
				setImports(imports.filter((element) => element._id !== _id));
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const viewImport = ({ _id }) => {
		setRedirect(`/events/animalImport/${_id}`);
	};

	const editImport = ({ _id }) => {
		setRedirect(`/events/animalImport/${_id}/update`);
	};

	const tableHeadings = [
		{ name: 'Exporting Country', id: 'exportingCountry' },
		{ name: 'Departure Site', id: 'departureSite' },
		{ name: 'First Destination PID', id: 'firstDestinationPID' },
		{ name: 'Vehicle Identifier', id: 'vehicleIdentifier' },
	];

	return (
		<PageCard title='Imports' to='/events/animalImport/createDashboard' back>
			<DescriptionCard page='Import'></DescriptionCard>
			{!load ? (
				<>Loading...</>
			) : (
				<>
					{windowSize < 1300 ? (
						<TableComponentMini
							fields={tableHeadings}
							list={imports}
							deleteCB={deleteImport}
							viewCB={viewImport}
							editCB={editImport}
						/>
					) : (
						<TableComponent
							headings={tableHeadings}
							data={imports}
							view={{ cb: viewImport, title: 'View' }}
							edit={{ cb: editImport, title: 'Edit' }}
							remove={{ cb: deleteImport, title: 'Delete' }}
							actions
						/>
					)}
				</>
			)}
		</PageCard>
	);
};

export default ImportsView;
