import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PageCard from '../components/PageCard.js';
import axios from 'axios';
import DescriptionCard from '../components/DescriptionCard.js';
import TableComponent from '../components/TableComponent';
import { getUsername } from '../utils/TokenUtils.js';
import TableComponentMini from '../components/TableComponentMini';

const OperationsView = () => {
	const [operations, setOperations] = useState([]);
	const [load, setLoad] = useState(false);
	const [redirect, setRedirect] = useState('');
	const [windowSize, setWindowSize] = useState(0);

	useEffect(() => {
		axios
			.get(`/api/operations/user/${getUsername()}`)
			.then(function (response) {
				setOperations(response.data);
				console.log(response.data);
				setLoad(true);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	if (window.innerWidth !== windowSize) {
		setWindowSize(window.innerWidth);
		console.log(windowSize);
	}

	if (redirect !== '') {
		return <Redirect push to={redirect} />;
	}

	const deleteOperation = ({ _id }) => {
		axios
			.delete(`/api/operations/${_id}`)
			.then(function (response) {
				console.log(response.data);
				setOperations(operations.filter((element) => element._id !== _id));
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const viewOperation = ({ _id }) => {
		setRedirect(`operations/${_id}`);
	};

	const editOperation = ({ _id }) => {
		setRedirect(`operations/${_id}/update`);
	};

	const tableHeadings = [
		{ name: 'Business Name', id: 'businessName' },
		{ name: 'Phone Number', id: 'phoneNumber' },
		{ name: 'Type', id: 'operationType' },
		{ name: 'CVSP', id: 'cvsp' },
		{ name: 'SFCP', id: 'sfcp' },
	];

	const operationFields = [
		{ name: 'Business Name', id: 'businessName' },
		{ name: 'Phone Number', id: 'phoneNumber' },
		{ name: 'Type', id: 'operationType' },
		{ name: 'CVSP', id: 'cvsp' },
		{ name: 'SFCP', id: 'sfcp' },
	];

	//Mutating the values for now --> This will be changed shortly
	for (let i = 0; i < operations.length; i++) {
		operations[i].cvsp = String(operations[i].cvsp);
		operations[i].sfcp = String(operations[i].sfcp);
	}

	return (
		<PageCard title='Operations' to='/operations/create' back>
			<DescriptionCard page='Operation'></DescriptionCard>
			{!load ? (
				<>Loading...</>
			) : (
				<>
					{windowSize < 1300 ? (
						<TableComponentMini
							fields={operationFields}
							list={operations}
							deleteCB={deleteOperation}
							viewCB={viewOperation}
							editCB={editOperation}
						/>
					) : (
						<TableComponent
							headings={tableHeadings}
							data={operations}
							view={{ cb: viewOperation, title: 'View' }}
							edit={{ cb: editOperation, title: 'Edit' }}
							remove={{ cb: deleteOperation, title: 'Delete' }}
							actions
						/>
					)}
				</>
			)}
		</PageCard>
	);
};

export default OperationsView;
