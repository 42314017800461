import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import FormCard from './FormCard.js';
import {
	FormContainer,
	FormSectionHeader,
	FormInputWrapper,
	FormInputLabel,
	FormTextInput,
	Button,
	TextContainer,
	Text,
	FormPasswordContainer,
	FormPassPeek,
} from './CommonComponents';
import axios from 'axios';
import { getToken } from '../utils/TokenUtils.js';
import { toast } from 'react-toastify';
import complexity from 'complexity';
import emailValidator from 'email-validator';
import phone from 'phone';

const pwOptions = {
	uppercase: 1,
	digits: 1,
	min: 8
};

export default function Register() {
	const [user, setUser] = useState({});
	const [fieldErrors, setErrors] = useState({});
	const [registered, setRegistered] = useState(false);
	const [peek, setPeek] = useState(false);

	const personal = [
		{ name: 'First Name:', hint: 'First Name', id: 'firstName' },
		{ name: 'Last Name:', hint: 'Last Name', id: 'lastName' },
		{ name: 'Phone:', hint: 'Phone # (optional)', id: 'phone' },
	];
	const account = [
		{ name: 'Username:', hint: 'Username', id: 'username' },
		{ name: 'Email:', hint: 'Email (optional)', id: 'email' },
		{ name: 'Password:', hint: 'Password', id: 'password', type: 'password' },
		{
			name: 'Re-enter your password:',
			hint: 'Confirm Password',
			id: 'cPassword',
			type: 'password',
		},
	];

	// If user is already authenticated, redirect
	if (getToken()) {
		return <Redirect to='/' />;
	}

	if (registered) {
		return <Redirect to='/login' push />;
	}

	const register = (e) => {
		e.preventDefault();
		let errorsFound = 0;
		if (user.password !== user.cPassword) {
			// TODO: Use react alert instead:
			setErrors({ ...fieldErrors, cPassword: 'Passwords do not match!' });
			errorsFound += 1;
		}

		if(user.email && !emailValidator.validate(user.email)){
			setErrors({...fieldErrors, email: 'Invalid email'});
			errorsFound += 1;
		}

		if(user.phone && phone(user.phone).length == 0){
			setErrors({...fieldErrors, phone: 'Invalid phone'});
			errorsFound += 1;
		}

		if(user.password && !complexity.check(user.password, pwOptions)){
			setErrors({...fieldErrors, password: 'Password does not meet complexity requirements'});
			errorsFound += 1;
		}

		if(errorsFound > 0){
			return;
		}

		axios.post('/api/users/register', user).then(
			async (res) => {
				toast.success('Account created!');
				setRegistered(true);
				const roleType = await axios.get('/api/roleTypes/extuser');
				let roleObj = {roleType: roleType.data._id, user: res.data._id};
				
				await axios.post('/api/roles', roleObj)
					.then((roleRes) => {
						console.log('the roleRes is', roleRes);
				})
				
			},
			(error) => {
				let set_errors = {};
				const { errors } = error.response.data;
				for (const [field, val] of Object.entries(errors)) {
					set_errors[field] = val.properties.message;
					if (field === 'password')
						set_errors.cPassword = 'Password confirmation is a required field!';
				}
				setErrors(set_errors);
			}
		);
	};

	return (
		<RegisterContainer>
			<TextContainer>
				<Text>Your sheep are waiting!</Text>
			</TextContainer>
			<FormCard title='Registration' errors={fieldErrors}>
				<RegisterFormContainer>
					<FormSectionHeader>Personal Information</FormSectionHeader>
					{personal.map((element) => (
						<FormInputWrapper key={element.id}>
							<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
							<FormTextInput
								placeholder={element.hint}
								value={user[element.id]}
								onChange={(e) => {
									const { [element.id]: undefined, ...left } = fieldErrors;
									setErrors(left);
									setUser({ ...user, [element.id]: e.target.value });
								}}
								invalid={fieldErrors[element.id]}
							></FormTextInput>
						</FormInputWrapper>
					))}
					<FormSectionHeader>Account Information</FormSectionHeader>
					{account.map((element) => (
						<FormInputWrapper key={element.id}>
							<FormInputLabel htmlFor='input'>{element.name}</FormInputLabel>
							{element.type === 'password' ? (
								<FormPasswordContainer>
									<FormTextInput
										placeholder={element.hint}
										type={peek ? 'text' : 'password'}
										value={user[element.id]}
										onChange={(e) => {
											const { [element.id]: undefined, ...left } = fieldErrors;
											setErrors(left);
											setUser({ ...user, [element.id]: e.target.value });
										}}
										invalid={fieldErrors[element.id]}
									/>
									<FormPassPeek onClick={() => setPeek(!peek)}>
										<FontAwesomeIcon icon={peek ? faEyeSlash : faEye} color='gray' />
									</FormPassPeek>
								</FormPasswordContainer>
							) : (
								<FormTextInput
									placeholder={element.hint}
									value={user[element.id]}
									onChange={(e) => {
										const { [element.id]: undefined, ...left } = fieldErrors;
										setErrors(left);
										setUser({ ...user, [element.id]: e.target.value });
									}}
									invalid={fieldErrors[element.id]}
								></FormTextInput>
							)}
						</FormInputWrapper>
					))}
					<Button type='submit' onClick={register}>
						Register
					</Button>
				</RegisterFormContainer>
			</FormCard>
		</RegisterContainer>
	);
}

// TODO: should probably import this from a file since Login.js uses it too
const RegisterContainer = styled.div`
	margin: auto;
`;

const RegisterFormContainer = styled(FormContainer)`
	flex-direction: row;
	flex-wrap: wrap;

	> button {
		margin: 0 auto 10px;
	}
`;
