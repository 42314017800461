import axios from 'axios';

export const getUsers = () => {
	return axios.get('/api/users').then((res) => res.data);
};

export const getUser = (username) => {
	return axios.get(`/api/users/${username}`).then((res) => res.data);
}

export const deleteUser = (username) => {
	axios
		.delete(`/api/users/${username}`)
		.then((res) => res.data)
		.catch((err) => {
			console.log(`Error during user deletion: ${JSON.stringify(err)}`);
		});
};
