import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { StyledTable } from '../components/TableComponents.js';
import PageCard from '../components/PageCard.js';
import styled from 'styled-components';
import { 
	getOperationById,
	deleteOperation,
} from '../utils/OperationUtils.js';
import { getOperationTypeByName } from '../utils/OperationTypeUtils.js';
import DetailTable from '../components/DetailTable.js';
import { agGreen, deleteColor } from '../components/Colors.js';

const OperationView = () => {
	const [operation, setOperation] = useState();
	const [redirect, setRedirect] = useState('');
	
	const params = useParams();
	const operationId = params.id;

	const fetchAndSetOperationId = async () => {
		let result = await getOperationById(operationId);
		const operationType = await getOperationTypeByName(result.operationType);
		const transformedOperation = {
			name: result.businessName,
			type: result.operationType,
			phoneNumber: result.phoneNumber,
			sfcp: result.sfcp,
			cvsp: result.cvsp,
		}

		setOperation(transformedOperation);
	}

	const operationFields = [
		{ name: 'Business Name', id: 'name' },
		{ name: 'Phone Number', id: 'phoneNumber' },
		{ name: 'Type', id: 'type' },
		{ name: 'SFCP', id: 'sfcp' },
		{ name: 'CVSP', id: 'cvsp' },
	];

	const buttons = [
		{ 
			name: 'Edit',
			onClick: (e) => setRedirect(`/operations/${operationId}/update`),
			color: agGreen,
		},
		{
			name: 'Delete',
			onClick: (e) => {
				deleteOperation(operationId);
				setRedirect('/operations');
			},
			color: deleteColor,
		},
	];

	useEffect(() => {
		if(!operation) fetchAndSetOperationId();
	});

	if(redirect !== '') return <Redirect push to={redirect} />;
	
	return <>
		<PageCard  title='View Details' back>
			<DetailTable data={operation} fields={operationFields} buttons={buttons} />
		</PageCard>
	</>
}

export default OperationView;
