import React, { useState } from 'react';
import PageCard from './PageCard.js';
import { EditButton } from './CommonComponents';
import styled from 'styled-components';
import { 
  agGreen, 
  viewColor, 
} from './Colors.js';
import {Link} from 'react-router-dom';
import propTypes from 'prop-types';

export default function DecisionComponent({questions, title}){
    const [index, setIndex] = useState(0);
    // const questions = [
    //     {leaf: false, text: 'Did you tag the animal while it was still on its farm of origin?', yes: 1, no: 2},
    //     {leaf: false, text: 'Is this the first approved tag applied to this animal?', yes: 3, no: 4},
    //     {leaf: false, text: 'Was the animal received having lost a previously applied approved tag?', yes: 5, no: 6},
    //     {leaf: true, text: `You are not required to report that you have applied an approved tag to an animal (tag activation) for the first time while it is still on its farm of origin, 
    //     but doing so allows you to track animals in your own inventory and add attributes to the animal. You can voluntarily report a tag activation event here.`,
    //      event: 'Tag Activation', to: '/events/tagactivation', required: false},
    //     {leaf: true, text: `You are required to report the application of an approved tag to an animal that has lost an approved tag, whose previous tag has been revoked, 
    //     or whose approved tag has malfunctioned (only permitted by direction of the CFIA). You can report a tag replacement event here.`,
    //      event: 'Tag Replacement', to: '/events/tagreplacement', required: true},
    //     {leaf: true, text: `You are required to report the replacement of a lost approved tag of animals that you receive. You can report a tag replacement here.`,
    //      event: 'Tag Replacement', to: '/events/tagreplacement', required: true},
    //     {leaf: true, text: `You are required to report applying an approved tag to an animal that you received that has never had an approved tag. You can report a tag activation event here:`,
    //      event: 'Tag Activation', to: '/events/tagactivation', required: true},
    // ];
    function yes(){
        if(questions[index].yes){
            setIndex(questions[index].yes);
        }
    }
    function no(){
        if(questions[index].no){
            setIndex(questions[index].no);
        }
    }
    return(
        <div>
            <PageCard title={title} back>
                <StyledQuestion>{questions[index].text}</StyledQuestion>
                {!questions[index].leaf ? <ButtonContainer>
                    <NoButton onClick={no}>No</NoButton><YesButton onClick={yes}>Yes</YesButton>
                    </ButtonContainer> 
                :
                <EventContainer>
                    {questions[index].required ? <StyledEventText>Required Event:</StyledEventText>:<StyledEventText>Optional Event: </StyledEventText>}
                    <StyledLink to={questions[index].to}>{questions[index].event}</StyledLink>
                </EventContainer>}
            </PageCard>
        </div>
    );
}

DecisionComponent.propTypes = {
  questions: propTypes.node,
  title: propTypes.node,
}


const ButtonContainer = styled.div`
    display: flex;
    margin: auto;
    justify-content: center;
`;

const YesButton = styled(EditButton)`
    background-color: ${agGreen};
    width: 100px;
    height: 40px;
    font-size: 30px;
`;

const NoButton = styled(EditButton)`
    background-color: ${viewColor};
    width: 100px;
    height: 40px;
    font-size: 30px;
`;

const StyledQuestion = styled.div`
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 40px;
`;

const EventContainer = styled.div`
    display: flex;
    align-items: center;
`;
const StyledLink = styled(Link)`
    font-size: 32px;
    color: white;
    font-style: italic;

`;

const StyledEventText = styled.div`
    font-size: 32px;
    font-weight: bold;
    color: ${agGreen};
    margin-right: 10px;
`;