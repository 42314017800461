// SimpleCard.js
import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

const Title = styled.span`
  font-size: ${props => props.size};
  font-weight: bold;
  margin-bottom: 0px;
`;

const Line = styled.hr`
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
`

const Description = styled.div`
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
`

const SimpleCardDiv = styled.div`
  margin-bottom: 2rem;
`

const SimpleCard = ({ title, size, description, children }) => {
  let titleSize = '2em';
  if (size) {
    switch (size) {
      case 'xl':
        titleSize = '2.5rem';
        break;
      case 'lg':
        titleSize = '2rem';
        break;
      case 'md':
        titleSize = '1.5rem';
        break;
      case 'sm':
        titleSize = '1rem';
        break;
      default:
    }
  }
  return <SimpleCardDiv>
    {/* Title */}
    <Title size={titleSize}>{title}</Title>
    {/* Line */}
    <Line />
    {/* Description */}
    <Description>{description}</Description>
    {/* Children */}
    <>{children}</>
  </SimpleCardDiv>
};

SimpleCard.propTypes = {
  title: propTypes.any,
  size: propTypes.any,
  description: propTypes.any,
  children: propTypes.any
};

export default SimpleCard;