// AdminRouter.js
import React from 'react';

import { Route, Switch } from 'react-router-dom';

import AdminRoleView from '../views/AdminRoleView.js';

const AdminRouter = () => (
  <Switch>
    <Route
      path='/admin/dashboard'
      component={AdminRoleView}
    />
  </Switch>
);

export default AdminRouter;
