import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import FormCard from '../components/FormCard.js';
import styled from 'styled-components';
import TableComponent from '../components/TableComponent';
import {
	FormContainer,
	FormSectionHeader,
	FormInputWrapper,
	FormInputLabel,
	FormTextInput,
	Button,
	FormSelectInput,
} from '../components/CommonComponents';
import { parseSheep } from '../utils/CSVUtils';
import { sheepYellow } from '../components/Colors.js';
import { toast } from 'react-toastify';
import { getUsername } from '../utils/TokenUtils.js';
import { getVehicles } from '../utils/VehicleUtils.js';
import propTypes from 'prop-types';

const axios = require('axios');

async function createReport(data) {
	// TODO: Is the PDF e-fillable?
	const report_fields = {
		transporter: {
			license: data.vehicle,
		},
		shipper: {
			name: `${data.from.firstName} ${data.from.lastName}`,
			owner: true,
			pid: data.currentPID,
		},
		load: {
			quantity: data.sheep.length,
		},
		receiver: {
			pid: data.destinationPID,
		},
	};

	return axios.post('/api/createReport', report_fields, { responseType: 'blob' }).then(
		(res) => {
			const pdf = new Blob([res.data], { type: 'application/pdf' });
			window.open(URL.createObjectURL(pdf));
		},
		(error) => {
			console.log(error);
		}
	);
}

export default function AnimalMove({ file, headers }) {
	const tableHeadings = [
		{ name: 'Date', id: 'date' },
		{ name: 'CSIP #', id: 'isonum' },
	];

	const [sheep, setSheep] = useState([]);
	const [user, setUser] = useState({});
	const [redirect, setRedirect] = useState('INITIAL');
	const [data, setData] = useState({});
  const fieldErrors = {};
	const [moveType, setMove] = useState('out');

	useEffect(() => {
		setSheep([]);

		const promises = [
			axios.get(`/api/operations/${getUsername()}/all`),
			axios.get(`/api/users/${getUsername()}`),
		];
		Promise.all(promises)
			.then((res) => {
				setData({ ...data, operations: res[0].data });
				setUser(res[1].data);
			})
			.catch((err) => {
				console.log(`Error during data retrieval: ${JSON.stringify(err)}`);
			});

		parseSheep(file, headers, ({ data }) => {
			setSheep(
				data.map((x, idx) => {
					return { id: idx, ...x };
				})
			);
			toast.success('Successfully loaded!');
		});
	}, [file]);

	if (redirect === 'DONE') {
		return <Redirect to='/' push />;
	}

	const deleteSheep = (element) => {
		// TODO: Display 'Are you sure dialog?'
		setSheep(sheep.filter((sheep) => sheep.isonum !== element.isonum));
	};

	const operationChange = async (operation) => {
		// Get premises
		axios
			.get(`/api/premises/operation/${operation}`)
			.then(function (response) {
				setData((data) => ({ ...data, premises: response.data }));
			})
			.catch(function (error) {
				console.log(error);
			});
		// Get vehicles
		const vehicles = await getVehicles();
		setData((data) => ({ ...data, vehicles }));
	};

	const importSheep = (e) => {
		e.preventDefault();

		setRedirect('PROCESSING');
		const sheepIsos = sheep.map((a) => a.isonum);
		const query = {
			currentPID: moveType === 'out' ? data.pid_1 : data.pid_2,
			destinationPID: moveType === 'out' ? data.pid_2 : data.pid_1,
			vehicle: data.vehicle,
			sheep: sheepIsos,
			moveType,
		};

		axios.post('/api/move/', query).then(
			async () => {
				if (moveType === 'out') {
					// Create move out report
					await createReport({ ...query, user });
				}
				toast.success('Move out complete!');
				setRedirect('DONE');
			},
			(error) => {
				toast.error(`An error has occurred: ${error}.`, {
					autoClose: false,
				});
				setRedirect('INITIAL');
			}
		);
	};

	return (
		<FormCard title='Animal Movement' back>
			<ImportContainer>
				<ImportSheep>
					<ImportTable>
						<TableComponent headings={tableHeadings} data={sheep} deleteCB={deleteSheep} actions />
					</ImportTable>
				</ImportSheep>
				<ImportFormContainer>
					<div>
						<input type='radio' checked={moveType === 'in'} onChange={() => setMove('in')} />
						Move in
						<input type='radio' checked={moveType === 'out'} onChange={() => setMove('out')} />
						Move out
					</div>
					<FormSectionHeader>
						{moveType === 'out' ? 'Origin' : 'Destination'} Information
					</FormSectionHeader>
					<FormInputWrapper>
						<FormInputLabel>Operation</FormInputLabel>
						<FormSelectInput
							invalid={fieldErrors.operation}
							value={data.operation}
							onChange={(e) => operationChange(e.target.value)}
						>
							<option hidden>Select an operation</option>
							{data.operations &&
								data.operations.map((option) => (
									<option key={option._id} value={option._id}>
										{option.businessName}
									</option>
								))}
						</FormSelectInput>
					</FormInputWrapper>
					<FormInputWrapper>
						<FormInputLabel>Premise</FormInputLabel>
						<FormSelectInput
							invalid={fieldErrors.pid_1}
							value={data.pid_1}
							onChange={(e) =>
								setData({
									...data,
									pid_1: e.target.value,
								})
							}
						>
							<option hidden>Select a premise</option>
							{data.premises &&
								data.premises.map((option) => (
									<option key={option._id} value={option.pid}>
										{option.name}
									</option>
								))}
						</FormSelectInput>
					</FormInputWrapper>
					<FormInputWrapper>
						<FormInputLabel>Vehicle</FormInputLabel>
						<FormSelectInput
							invalid={fieldErrors.vehicle}
							value={data.vehicle}
							onChange={(e) =>
								setData({
									...data,
									vehicle: e.target.value,
								})
							}
						>
							<option hidden>Select a vehicle</option>
							{data.vehicles &&
								data.vehicles.map((option) => (
									<option key={option.name} value={option.licensePlateNum}>
										{option.name}
									</option>
								))}
						</FormSelectInput>
					</FormInputWrapper>
					<FormSectionHeader>
						{moveType === 'out' ? 'Destination' : 'Origin'} Information
					</FormSectionHeader>
					<FormInputWrapper>
						<FormInputLabel htmlFor='input'>Premise ID</FormInputLabel>
						<FormTextInput
							invalid={fieldErrors.pid_2}
							placeholder='PID #'
							value={data.pid_2}
							onChange={(e) => {
								setData({ ...data, pid_2: e.target.value });
							}}
						></FormTextInput>
					</FormInputWrapper>
					<ButtonContainer>
						<ImportButton
							onClick={importSheep}
							disabled={!data.pid_1 || !data.pid_2 || !sheep || redirect !== 'INITIAL'}
						>
							Move {sheep.length} sheep
						</ImportButton>
					</ButtonContainer>
				</ImportFormContainer>
			</ImportContainer>
		</FormCard>
	);
}

AnimalMove.propTypes = {
  file: propTypes.any,
  headers: propTypes.any
}


const ImportFormContainer = styled(FormContainer)`
	flex-direction: row;
	flex-wrap: wrap;

	> button {
		margin: 0 auto 10px;
	}
`;

const ImportContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding: 20px 0 0;
`;

const ImportSheep = styled.div`
	width: fit-content;
	display: flex;
	padding: 0 20px 20px;
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

// TODO: Make the height dynamic
const ImportTable = styled.div`
	overflow-y: auto;
	max-height: 400px;
	width: fit-content;
	margin: auto;

	&::-webkit-scrollbar {
		width: 15px;
		&-track {
			box-shadow: inset 0 0 5px grey;
			border-radius: 10px;
		}
		&-thumb {
			background: ${sheepYellow};
			background: #e2e672;
			border-radius: 10px;
			&:hover {
				background: #e2e672;
				background: ${sheepYellow};
			}
		}
	}
`;

const ImportButton = styled(Button)`
	align-self: center;
`;
